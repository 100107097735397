import account from './account';
import mall from './mall';
import common from './common';
import message from './message';
import home from './home';
import error from './error';
import site from './site';
import team from './team';
import profile from './profile';
import service from './service';
import donation from './donation';
import extra from './extra'
//土耳其语言
export default {
    common,
    account,
    mall,
    message,
    home,
    error,
    site,
    team,
    profile,
    service,
    donation,
    extra
};