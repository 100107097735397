export default {
    title: ({
        named
    }) => {
        const typeName = named('messageType');
        switch (typeName) {
            case 'RECHARGE_AUDIT_REJECT':
                return 'Şarj başarısız';
            case 'RECHARGE_SUCCESS':
                return 'Şarj başarısı';
            case 'VIP_UPGRADE_SUCCESS':
                return "Yükseltme başarısı";
            case 'WITHDRAW_AUDIT_PASS':
                return `Çekilme denetimi sonucu`;
            case 'WITHDRAW_AUDIT_REJECT':
                return `Çekilme denetimi sonucu`;
            case 'WITHDRAW_SUCCESS':
                return "Çekilme tamamlandı";
            case 'WITHDRAW_FAILED':
                return "Çekilme başarısız";
            case 'SYSTEM_BONUS':
                return "Bonus alındı";
            case 'SYSTEM_PENALTY':
                return "Hesap değişikliği";
            case 'TASK_ACCOMPLISHED':
                return "Görev tamamlandı";
            case 'DEPOSITORY_PROFITS':
                return 'AI güven karı';
            case 'DEPOSITORY_TRANSOUT':
                return 'AI güveni tamamlandı';
            case 'DONATION_AUDIT':
                return 'Bağış başvurusu';
            case 'DONATION_RECEIVED':
                return `Bağış parası alındı`;
            case 'DONATION_COMPLETE':
                return "Bağış tamamlandı";
            case 'OTHER':
                return `${named('title')}`;
            default:
                return '';
        }
    },

    content: ({
        named,
        linked
    }) => {
        return linked(`message.content_${named('messageType')}`);
    },
    content_OTHER: '{içerik}',
    content_RECHARGE_AUDIT_REJECT: 'Sevgili VIP{vip_level} üyesi, sizin tarafınızdan {inserted_at} tarihinde başlatılan yeniden yükleme incelemesi reddedildi. Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.',
    content_RECHARGE_SUCCESS:`Sevgili VIP{vip_level} üyesi, {inserted_at} tarihinde başlattığınız sipariş numarası: {order_no}'nun yüklemesi geldi!;`,
    content_VIP_UPGRADE_SUCCESS: `Sevgili üye, {inserted_at} tarihinde VIP{upgrade_level}'e yükselttiniz!`,
    content_WITHDRAW_AUDIT_PASS: 'Sevgili VIP{vip_level} üyesi, {inserted_at} tarihinde başlatılan para çekme başvurunuz onaylandı ve alınan miktar {amount}. Lütfen banka hesabınıza dikkat edin',
    content_WITHDRAW_SUCCESS: 'Sevgili VIP{vip_level} üyesi, {inserted_at} tarihinde başlatılan para çekme başvurunuz başarıyla ulaştı ve alınan miktar {amount}. Lütfen banka hesabınıza dikkat edin',
    content_WITHDRAW_FAILED: 'Sevgili VIP{vip_level} üyesi, sizin tarafınızdan {inserted_at} tarihinde başlatılan para çekme incelemesi başarısız oldu',
    content_WITHDRAW_AUDIT_REJECT: 'Sevgili VIP{vip_level} üyesi, sizin tarafınızdan {inserted_at} tarihinde başlatılan para çekme incelemesi reddedildi. Reddetme nedeni: Yanlış banka kartı bilgileri. Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.',
    content_SYSTEM_BONUS: 'Sevgili VIP{vip_level} üye, platform yöneticisi tarafından verilen {amount} aldınız, lütfen hesabınızı kontrol edin.',
    content_SYSTEM_PENALTY: 'Sevgili VIP{vip_level} üyeleri, hesabınız değişti. Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.',
    content_TASK_ACCOMPLISHED: 'Sevgili VIP{vip_level} üyesi, davet görevini tamamladınız ve ödül {bonus} hesabınıza aktarıldı!',
    content_DEPOSITORY_PROFITS: 'Sevgili VIP{vip_level} üyesi, {inserted_at} tarihinde AI güven yönetimine geçtiğinizde zaten {profits} geliri elde ettiniz',
    content_DEPOSITORY_TRANSOUT: 'Sevgili VIP{vip_level} üyesi, {inserted_at} tarihinde AI güven yönetimi servetine aktardığınız {amount} miktarı sona erdi, lütfen hesabınızdaki değişikliklere dikkat edin',
    content_DONATION_AUDIT: 'Sevgili VIP{vip_level} üye, bağış başvurunuz denetlendi: {audit_status}',
    content_DONATION_RECEIVED: 'Sevgili VIP{vip_level} üyesi, toplam {amountReceived} bağış aldınız, lütfen hesabınızı kontrol edin.',
};