export default {
    somethingWentWrong: 'Ada yang salah, coba lagi nanti',

    signup: {
        success: 'Registrasi berhasil',
        termOfService: 'Anda harus menyetujui persyaratan layanan kami untuk mendaftar',
        invalidInviteCode: "Maaf, kode undangan tidak valid",
    },
    resetPassword: {
        success: 'Kata sandi telah diubah',
    },
    validator: {
        mobile: {
            required: 'Silakan masukkan nomor ponsel',
            invalid: 'Silakan masukkan nomor ponsel yang benar',
            exists: 'Nomor telepon telah terdaftar',
            notExists: 'Akun tidak ada',
            startWith0:'Tidak dapat dimulai dengan 0',
            errorlength: 'Panjang nomor ponsel adalah 8 - 11'//atau 11
        },
        nickname: {
            required: 'Silakan masukkan nama panggilan Anda',
            length: 'Nama panggilan terlalu pendek',
        },
        inviteCode: {
            required: 'Silakan masukkan kode undangan',
            invalid: 'Kode undangan tidak valid',
        },
        password: {
            required: 'Silakan masukkan kata sandi',
            length: 'Panjang minimal 6 karakter',
        },
        checkPass: {
            required: 'Silakan masukkan kata sandi lagi',
            notMatch: 'Ulangi kata sandi tidak cocok',
        },
        identifyCode: {
            required: 'Silahkan masukan kode identitas dari sms',
            invalid: 'Kode identitas tidak valid',
        },
    },
    grab:{
         noAvailableToday:'tidak tersedia hari ini',
         finishTasks:'Anda telah menyelesaikan semua tugas hari ini',
         insufficient:'saldo akun tidak mencukupi'
    },
    withDraw:{
        amountErr:'Halo, jumlah penarikan minimum adalah {min}, jumlah penarikan maksimum adalah {max}'
    },
    recharge:{
        noAmount:'Silakan pilih jumlah',
        noChannel:'Silakan pilih saluran',
        noMatchLimit:'Melebihi batas saluran',
        screenshotsRequired:"Anda harus mengunggah voucher transfer",
        decimalErr:'Harap masukkan hingga dua tempat desimal',
        decimalErr4:'Tidak boleh melebihi 4 tempat desimal',
        amountErr:'Halo, jumlah isi ulang minimum adalah {min}, jumlah isi ulang maksimum adalah {max}',
        selectBank: 'Silakan pilih Bank Pemberi'
    },
    server: {
        tokenError:'informasi login telah kedaluwarsa',
        startupQuery: 'Muat konfigurasi gagal karena kesalahan jaringan, silakan coba nanti',
        abnormal:'Jaringan tidak normal. Periksa koneksi jaringan',
        error400: 'Permintaan yang buruk',
        error401: 'tidak sah',
        error403: 'Terlarang',
        error404: 'Sumber daya tidak ada',
        error405: 'Kesalahan jaringan',
        error408: 'Minta batas waktu',
        error500: 'Kesalahan internal server',
        error501: 'Kesalahan internal server',
        error502: 'Kesalahan jaringan server',
        error503: 'Server sementara tidak tersedia',
        error504: 'Waktu habis jaringan',
        error505: 'Kesalahan peramban',
        InvalidPhoneNumber: 'Nomor telepon tidak valid',
        InvalidFeeRate: 'Kesalahan konfigurasi server',
        NoEnoughBalance: 'Saldo tidak mencukupi',
        WithdrawFrequency: 'Tarik batas frekuensi',
        WithdrawAmountExceedVipLimitation: 'Jumlah penarikan melebihi batas level VIP',
        TooManyWithdraws: 'Terlalu banyak penarikan hari ini, silakan coba besok',
        MaximumVipLevel: 'Level VIP maksimum tercapai',
        NoPaymentChannelAvailable: 'Saluran pembayaran tidak tersedia, silakan coba nanti',
        LastVipUpgradeProcessing: 'Peningkatan level VIP sedang berlangsung, harap tunggu sebentar',
        WithdrawNotOpenForNow: 'Di luar rentang waktu layanan penarikan',
        WithdrawNotAllowed: 'Kondisi penarikan tidak terpenuhi, silakan hubungi layanan pelanggan',
        RechargeNotOpenForNow: 'Di luar rentang waktu layanan isi ulang',
        InvalidRechargeAmount: 'Jumlah isi ulang tidak valid',
        FundPasswordNotSet: 'Silakan setel kata sandi penarikan terlebih dahulu',
        FundPasswordNotMatch: 'Silakan masukkan kata sandi penarikan yang benar',
        TooManyProcessingTasks: 'Terlalu banyak tugas pemrosesan',
        InvalidTask: 'Tugas tidak valid',
        InvalidOldPassword: 'Silakan masukkan kata sandi lama yang benar',
        VipLevelLimit: 'Batas level VIP',
        AccountNotExists: 'Akun tidak ada',
        InvalidIdentifyCode: 'Silakan masukkan kode identitas yang benar',
        InvalidInviteCode: 'Harap masukkan kode undangan yang benar',
        MobileHasBeenRegistered: 'Nomor telepon telah terdaftar',
        AmountExceedsDepositoryThreshold: 'Jumlah manajemen kepercayaan AI melebihi ambang batas',
        TooManyGrabsToday: 'Anda telah menyelesaikan semua tugas hari ini',
        ValidationError: 'Nilai formulir berisi nilai yang tidak valid',
        BankcardExists: 'Informasi kartu bank sudah ada',
        DonationComplete: 'Penerima telah menerima donasi yang cukup, terima kasih atas bantuan Anda yang ramah',
        GrabNotComplete: 'Harap selesaikan semua tugas pesanan hari ini sebelum memulai penarikan',
        AlreadySubmitDonationApplication: "Maaf, tetapi Anda tidak dapat mengajukan donasi berkali-kali",
        DonationLimited: 'Terima kasih atas kemurahan hati Anda, tetapi Anda harus mengisi ulang sejumlah uang sebelum menyumbang',
        AgentNotExists: 'Agen tidak ditemukan',
        AccountBanned: 'Batas aktivitas akun',
        SomethingWentWrong: 'Ada yang salah',
        SystemMaintenance: 'Sistem dalam pemeliharaan, silakan coba nanti',
        ServerError: 'Server down sementara, silakan coba nanti',
        GrabFrequency: 'Ambil batas frekuensi',

    },
};