export default {
    somethingWentWrong: 'Algo ha ido mal, por favor, inténtelo más tarde',

    signup: {
        success: 'Éxito de la inscripción',
        termOfService: 'Debes aceptar nuestras condiciones de servicio para registrarte',
        invalidInviteCode: "Lo sentimos, el código de invitación no es válido",
    },
    resetPassword: {
        success: 'Se ha cambiado la contraseña',
    },
    validator: {
        mobile: {
            required: 'Por favor, introduzca el número de teléfono móvil',
            invalid: 'Por favor, introduzca el número de teléfono móvil correcto',
            exists: 'Se ha registrado el número de teléfono',
            notExists: 'La cuenta no existe',
            startWith0:'No puede comenzar con 0',
            errorlength: 'La longitud del número de móvil es de 8 - 11'//u 11
        },
        nickname: {
            required: 'La longitud del número de móvil es de 10 u 11',
            length: 'El apodo es demasiado corto',
        },
        inviteCode: {
            required: 'Por favor, introduzca el código de invitación',
            invalid: 'Código de invitación inválido',
        },
        password: {
            required: 'Por favor, introduzca la contraseña',
            length: 'Longitud mínima de 6 caracteres',
        },
        checkPass: {
            required: 'Por favor, introduzca la contraseña de nuevo',
            notMatch: 'Repetir contraseña no coincide',
        },
        identifyCode: {
            required: 'Por favor, introduzca el código de identificación del sms',
            invalid: 'Código de identificación inválido',
        },
    },
    grab:{
         noAvailableToday:'no disponible hoy',
         finishTasks:'Has terminado todas las tareas de hoy',
         insufficient:'saldo insuficiente de la cuenta'
    },
    withDraw:{
        amountErr:'Hola, el monto mínimo de retiro es {min}, el monto máximo de retiro es {max}'
    },
    recharge:{
        noAmount:'Por favor, seleccione la cantidad',
        noChannel:'Por favor, seleccione el canal',
        noMatchLimit:'Superación del límite de canales',
        screenshotsRequired:"Debe cargar el comprobante de transferencia",
        decimalErr:'Por favor, introduzca hasta dos decimales',
        decimalErr4:'No puede superar los 4 decimales',
        amountErr:'Hola, el monto mínimo de recarga es {min}, el monto máximo de recarga es {max}',
        selectBank: 'Por favor, seleccione el Banco de cobro'
    },
    server: {
        tokenError:'los datos de acceso han caducado',
        startupQuery: 'La carga de la configuración ha fallado debido a un error de red, por favor inténtelo más tarde',
        abnormal:'La red es anormal. Compruebe la conexión de red',
        error400: 'Mala petición',
        error401: 'No autorizado',
        error403: 'Prohibido',
        error404: 'El recurso no existe',
        error405: 'Error de red',
        error408: 'Tiempo de espera de la solicitud',
        error500: 'Error interno del servidor',
        error501: 'Error interno del servidor',
        error502: 'Error de red del servidor',
        error503: 'Servidor temporalmente no disponible',
        error504: 'Tiempo de espera de la red',
        error505: 'Error del navegador',
        InvalidPhoneNumber: 'Número de teléfono inválido',
        InvalidFeeRate: 'Error de configuración del servidor',
        NoEnoughBalance: 'Equilibrio insuficiente',
        WithdrawFrequency: 'Límite de frecuencia de retirada',
        WithdrawAmountExceedVipLimitation: 'El importe de la retirada supera el límite del nivel VIP',
        TooManyWithdraws: 'Demasiados retiros hoy, por favor inténtelo mañana',
        MaximumVipLevel: 'Nivel VIP máximo alcanzado',
        NoPaymentChannelAvailable: 'El canal de pago no está disponible, por favor inténtelo más tarde',
        LastVipUpgradeProcessing: 'Actualización del nivel VIP en curso, por favor espere un poco',
        WithdrawNotOpenForNow: 'Fuera del rango de tiempo de servicio de retirada',
        WithdrawNotAllowed: 'Si no se cumple la condición de retirada, póngase en contacto con el servicio de atención al cliente',
        RechargeNotOpenForNow: 'Fuera del rango de tiempo de servicio de recarga',
        InvalidRechargeAmount: 'Cantidad de recarga no válida',
        FundPasswordNotSet: 'Por favor, establezca primero la contraseña de retirada',
        FundPasswordNotMatch: 'Por favor, introduzca la contraseña de retirada correcta',
        TooManyProcessingTasks: 'Demasiadas tareas de procesamiento',
        InvalidTask: 'La tarea no es válida',
        InvalidOldPassword: 'Por favor, introduzca la contraseña antigua correcta',
        VipLevelLimit: 'Límite de nivel VIP',
        AccountNotExists: 'La cuenta no existe',
        InvalidIdentifyCode: 'Por favor, introduzca el código de identificación correcto',
        InvalidInviteCode: 'Por favor, introduzca el código de invitación correcto',
        MobileHasBeenRegistered: 'Se ha registrado el número de teléfono',
        AmountExceedsDepositoryThreshold: 'El importe de la gestión fiduciaria de la IA supera el umbral',
        TooManyGrabsToday: 'Has terminado todas las tareas de hoy',
        ValidationError: 'El valor del formulario contiene un valor no válido',
        BankcardExists: 'La información de la tarjeta bancaria ya existe',
        DonationComplete: 'El beneficiario ha recibido suficiente donación, gracias por su amable ayuda',
        GrabNotComplete: 'Por favor, complete todas las tareas del pedido hoy antes de iniciar la retirada',
        AlreadySubmitDonationApplication: "Lo sentimos, pero no se puede solicitar la donación varias veces",
        DonationLimited: 'Gracias por su generosidad, pero debería recargar algo de dinero antes de donar',
        AgentNotExists: 'Agente no encontrado',
        AccountBanned: 'Límite de actividad de la cuenta',
        SomethingWentWrong: 'Algo salió mal',
        SystemMaintenance: 'Sistema en mantenimiento, por favor inténtelo más tarde',
        ServerError: 'El servidor está temporalmente caído, por favor inténtelo más tarde',
        GrabFrequency: 'Límite de frecuencia de agarre',

    },
};