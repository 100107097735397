export default {
    label: {
        advisory: 'Danışma',
        complaint: 'Şikayet',
        faq: 'Sıkça Sorulan Sorular',
    },
    message: {
        // system_success: '成功',
        // system_fail: '失败',
        // system_add_suc: '新增成功',
        // system_add_fail: '新增失败',
        // system_del_suc: '删除成功',
        // system_del_fail: '删除失败',
        // system_update_suc: '更新成功',
        // system_update_fail: '更新失败',
        // system_api_error: '接口异常',
        // system_opt_suc: '操作成功',
        // system_opt_fail: '操作失败',
        // system_opt_edit: '编辑',
        // system_opt_del: '删除',
        // system_data_not_empty: '数据不能为空',

        // login_username_not_empty: '登录账号不能为空',
        // login_password_not_empty: '登录密码不能为空',
        // login_username_not_exist: '登录账号不存在',
        // login_pass_err: '登录密码错误',
        // login_username_freeze: '用户已冻结,不能登录',
        // login_username_lt_4: '登录账号不应低于4位',
        // login_password_empty: '请输入登录密码',
        // login_password_lt_4: '登录密码不应低于4位',
        // login_success: '登录成功',
        // login_fail: '登录失败',
        // login_param_empty: '账号或密码为空',
        // login_param_unvalid: '账号或密码错误',

        // logout_btn: '注销',
        // logout_confirm: '确认注销登录?',
        // logout_success: '注销成功',
        // logout_fail: '注销失败',


        // user_add: '新增用户',
        // user_update: '更新用户',
        // user_username_repeat: '账号重复',
        // user_username_valid: '限制以小写字母开头，由小写字母、数字组成',
        // user_password_update_placeholder: '请输入新密码，为空则不更新密码',
        // user_update_loginuser_limit: '禁止操作当前登录账号',
        // user_not_exist_update_fail: '修改失败,账号不存在',
        // user_old_password_err: '旧密码错误请重新输入',
        // user_nick_not_empty: '昵称不能为空',
        // user_code_not_empty: '验证码不能为空',
        // user_sms_code_expired: '验证码已过期',
        // user_sms_code_err: '验证码错误',
        // user_invitation_code_not_exist: '邀请码不存在',
        // user_invitation_code_err: '邀请码错误',
        // user_level_not_configured: '无等级信息,请配置等级',
        // user_reg_fail: '注册失败',
        // user_send_sms_fail: '发送失败,请配置短信内容',
        // user_account_not_exist: '账户不存在',
        // user_avatar_not_empty: '头像地址不能为空',
        // user_transaction_password_not_empty: '交易密码不能为空',
        // user_task_log_create_fail: '生成任务记录失败',
        // user_task_not_exist: '领取失败,任务不存在',
        // user_task_not_completed: '领取失败,任务未完成',
        // user_task_received: '领取失败,奖励已领取',
        // user_task_expired: '领取失败,任务已过期',
        // user_task_state_error: '领取失败,任务状态错误',
        // user_failed_to_modify_balance: '领取失败,修改余额失败',
        // user_task_receive_fail: '领取失败',
        // user_insufficient_balance: '余额不足',

        // level_config_1: 'VIP数据错误',
        // level_config_3: '您已经是此等级,无需升级',
        // level_config_4: '升级失败,扣除余额失败',
        // level_config_5: '升级失败,修改用户信息失败',

        // member_bank_1: '绑定银行卡失败',

        // order_save_1: '请联系管理配置产品小类!',
        // order_save_2: '数据有误，请联系管理员!',
        // order_save_3: '更新佣金失败!',
        // order_save_5: '扣款失败!',
        // order_save_6: '下单失败!!!',
        // order_save_7: '订单数量已达到当前最大订单等级，请提高等级。!!!',
        // order_detail_1: '数据有误，请联系管理员!',
        // order_detail_2: '用户VIP等级不够!',
        // order_detail_3: '每天只能抢购20单',
        // order_detail_4: '该专区需要最大金额',
        // order_detail_5: '请联系管理配置产品小类!',
        // order_detail_6: '订单状态有误!',

        // user_buy_record_save_1: '请勿重复购买!',
        // user_buy_record_save_2: '数据有误,请联系客服!',
        // user_buy_record_save_3: '参数有误,请联系管理员!',
        // user_buy_record_save_4: '小于最少限制,请重新购买!',
        // user_buy_record_save_5: '超出最大限制,请重新购买!',
        // user_buy_record_save_6: '用户VIP等级不够!',

        // web_pay_add_1: '该通道未配置',
        // web_pay_add_2: '参数有误',
        // web_pay_add_3: '支付订单创建成功',
        // web_pay_add_4: '支付订单创建失败',

        // web_pay_create_pay_1: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_pay_2: '平台卡有误',
        // web_pay_create_pay_3: '通道已关闭，请选择其他通道',
        // web_pay_create_pay_4: '提交金额不在允许范围内',

        // web_pay_create_proxy_1: '请求数据有误，提现卡号不是本人或不存在!',
        // web_pay_create_proxy_2: '您好，帐号银行卡被锁定，请联系客服!',
        // web_pay_create_proxy_3: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_proxy_4: '您好，每日最多提款次数为:3次',
        // web_pay_create_proxy_5: '您好，最小提款金额为',
        // web_pay_create_proxy_6: '您好，最大提款金额为',
        // web_pay_create_proxy_9: '提现成功!',
        // web_pay_create_proxy_10: '提现失败',

        // login_expired:'登录已过期，请重新登录',
        // login_network_error:'网络异常，请检查网络链接',

        system_success: 'Başarı',
        system_fail: 'Arıza',
        system_add_suc: 'Başarı Ekle',
        system_add_fail: 'Hata Ekle',
        system_del_suc: 'Başarıyla silindi',
        system_del_fail: 'Silinemedi',
        system_update_suc: 'Güncelleme başarılı',
        system_update_fail: 'Güncelleştirme başarısız',
        system_api_error: 'Arayüz istisnaları',
        system_opt_suc: 'Başarılı operasyon',
        system_opt_fail: 'Operasyon başarısız',
        system_opt_edit: 'Editor',
        system_opt_del: 'Silmek',
        system_data_not_empty: 'Veriler boş olamaz',

        login_username_not_empty: `Giriş hesabı boş bırakılamaz`,
        login_password_not_empty: 'Giriş şifresi boş bırakılamaz',
        login_username_not_exist: 'Giriş hesabı kullanılamıyor',
        login_pass_err: 'Hatalı giriş / şifre',
        login_username_freeze: 'Kullanıcı donduruldu ve giriş yapamıyor',
        login_username_lt_4: 'Giriş hesap numarası 4 haneden az olmamalıdır',
        login_password_empty: 'Lütfen giriş şifresini girin',
        login_password_lt_4: 'Giriş şifresi 4 haneden az olmamalıdır',
        login_success: 'Giriş başarılı',
        login_fail: 'Giriş başarısız',
        login_param_empty: 'Hesap veya şifre boş',
        login_param_unvalid: 'Yanlış hesap veya şifre',

        logout_btn: 'İptal',
        logout_confirm: 'Çıkış onaylandı mı?',
        logout_success: 'Başarılı iptal',
        logout_fail: 'İptal başarısız',

        user_add: 'Yeni kullanıcılar',
        user_update: 'Kullanıcıları güncelle',
        user_username_repeat: 'Yinelenen hesaplar',
        user_username_valid: 'KısıtlamalarKüçük harf, sayılardan oluşan küçük harfle başlayan',

        user_password_update_placeholder: 'Lütfen yeni bir şifre girin, eğer boşsa şifre güncellenmeyecektir',
        user_update_loginuser_limit: 'Oturum açmış olan hesabın çalışmasını yasakla',
        user_not_exist_update_fail: 'Değişiklik başarısız oldu, hesap mevcut değil',
        user_old_password_err: 'Eski şifre yanlış, lütfen tekrar girin',
        user_nick_not_empty: 'Takma ad boş olamaz',
        user_code_not_empty: 'Captcha boş olamaz',
        user_sms_code_expired: `Captcha'nın süresi doldu`,
        user_sms_code_err: 'CAPTCHA hatası',
        user_invitation_code_not_exist: 'Davetiye kodu mevcut değil',
        user_invitation_code_err: 'Davetiye kodu hatası',
        user_level_not_configured: 'Seviye bilgisi yok, lütfen seviyeyi yapılandırın',
        user_reg_fail: 'Kayıt başarısız',
        user_send_sms_fail: 'Gönderme başarısız, lütfen mesajın içeriğini yapılandırın',
        user_account_not_exist: 'Hesap yok',
        user_avatar_not_empty: 'Avatar adresi boş bırakılamaz',
        user_transaction_password_not_empty: 'İşlem şifresi boş bırakılamaz',
        user_task_log_create_fail: 'Bir görev kaydı oluşturulamadı',
        user_task_not_exist: 'Toplanamadı, görev mevcut değil',
        user_task_not_completed: 'Toplanamadı, görev tamamlanmadı',
        user_task_received: 'Talep başarısız oldu, ödül talep edildi',
        user_task_expired: 'Toplanamadı, görev süresi doldu',
        user_task_state_error: 'Toplanamadı, görev durumu hatası',
        user_failed_to_modify_balance: 'Tahsilat yapılamadı, bakiye değiştirilemedi',
        user_task_receive_fail: 'Toplanamadı',
        user_insufficient_balance: 'Yetersiz bakiye',

        level_config_1: 'VIP veri hatası',
        level_config_3: 'Zaten bu seviyedesin, yükseltmene gerek yok',
        level_config_4: 'Yükseltme başarısız oldu, bakiye düşülemedi',
        level_config_5: 'Yükseltme başarısız, kullanıcı bilgilerini değiştirme başarısız',

        member_bank_1: 'Banka kartı bağlanamadı',
        member_bank_2: 'Çekilme yöntemi zaten var',

        order_save_1: 'Lütfen Yönetim Konfigürasyonu ürün alt kategorisine başvurun',
        order_save_2: 'Veriler yanlışsa lütfen yönetici ile iletişime geçin',
        order_save_3: 'Komisyon güncellemesi başarısız',
        order_save_5: 'Ödeme başarısız',
        order_save_6: 'Verilen sipariş başarısız',
        order_save_7: 'Sipariş sayısı mevcut maksimum sipariş seviyesine ulaştı, lütfen seviyeyi yükseltin.',
        order_save_8: 'Bekleyen emirler var.',
        order_save_9: 'Siparişin süresi doldu, lütfen yenilemek için müşteri hizmetleri ile iletişime geçin.',
        order_save_10: 'Sipariş donduruldu, çözmek için lütfen müşteri hizmetleriyle iletişime geçin.',
        order_save_11: 'Bakiye yetersizse aradaki farkın kapatılması gerekecek.',

        order_detail_1: 'Veriler yanlışsa lütfen yönetici ile iletişime geçin',
        order_detail_2: 'Yeterli kullanıcı VIP seviyesi yok',
        order_detail_3: 'Müşteri Hizmetleri Uzmanınızla görüşün',
        order_detail_4: 'Bu alan için gereken maksimum miktar',
        order_detail_5: 'Lütfen Yönetim Konfigürasyonu ürün alt kategorisine başvurun',
        order_detail_6: 'Sipariş durumu yanlış!',

        user_buy_record_save_1: 'Lütfen çift satın almayın',
        user_buy_record_save_2: 'Veriler yanlışsa lütfen müşteri hizmetleri ile iletişime geçin.',
        user_buy_record_save_3: 'Parametrelerde hata varsa lütfen yönetici ile iletişime geçin',
        user_buy_record_save_4: 'Asgari limitin altında, lütfen tekrar satın alın',
        user_buy_record_save_5: 'Maksimum limit aşıldı, lütfen tekrar satın alın',
        user_buy_record_save_6: 'Kullanıcılar için yeterli VIP seviyesi yok',
        user_buy_record_save_7: 'Açık bir siparişiniz var, lütfen önce doldurun',
        user_buy_record_save_8: 'Sipariş tamamlanmadı ve geri alınamaz',
        

        web_pay_add_1: 'Bu kanal yapılandırılmamış',
        web_pay_add_2: 'Yanlış parametreler',
        web_pay_add_3: 'Ödeme talimatı başarıyla oluşturuldu',
        web_pay_add_4: 'Ödeme emri oluşturma hatası',

        web_pay_create_pay_1: 'Merhaba, son siparişiniz henüz işleme koyulmadığı için lütfen bir dakika bekleyin ve tekrar gönderin',
        web_pay_create_pay_2: 'Yanlış platform kartı',
        web_pay_create_pay_3: 'Erişim kapalı, lütfen başka bir erişim seçin',
        web_pay_create_pay_4: 'Gönderilen miktar izin verilen aralıkta değil',

        web_pay_create_proxy_1: 'Talep verileri yanlış, para çekme kartı numarası kişi değil veya mevcut değil',
        web_pay_create_proxy_2: 'Merhaba, hesap kartı kilitli, lütfen müşteri hizmetleri ile iletişime geçin',
        web_pay_create_proxy_3: 'Merhaba, son siparişiniz henüz işleme koyulmadığı için lütfen bir dakika bekleyin ve tekrar gönderin',
        web_pay_create_proxy_4: 'Merhaba, günlük maksimum çekim sayısı: {days}',
        web_pay_create_proxy_5: 'Merhaba, minimum para çekme miktarı {min}',
        web_pay_create_proxy_6: 'Merhaba, maksimum para çekme miktarı {max}',
        web_pay_create_proxy_9: 'Başarılı para çekme işlemleri',
        web_pay_create_proxy_10: 'çekilme başarısız',
        web_pay_create_proxy_11:'Yanlış güvenlik şifresi; lütfen güvenlik parolanızı iki kez kontrol edin veya değiştirmek için çevrimiçi müşteri hizmetleriyle iletişime geçin',
        web_pay_create_proxy_12:'Güvenlik şifresi defalarca yanlış girilirse sistem hesabı dondurur. Hesabı dondurmak için lütfen müşteri hizmetleriyle iletişime geçin',
        web_pay_create_proxy_13:"Döviz kuru güncellendi, lütfen yenileyin",
        
        login_expired:'Giriş süresi doldu. Lütfen tekrar giriş yapın.',
        login_network_error:'Ağ anormal. Ağ bağlantısını kontrol edin.',
        user_sms_fail:'SMS doğrulama kodu 2 dakika içinde geçerlidir',

        user_experience_cannot: 'Bakiye {experienceGold} deneme parası içeriyor ve para çekmek için kullanılamaz',
        user_experience_cannot_up_vip: 'Bakiye {experienceGold} deneme parası içeriyor ve VIP yükseltme için kullanılamaz',
        user_experience_cannot_ai: 'Bakiye {experienceGold} deneme fonu içeriyor ve güven satın almak için kullanılamaz'
    }
};