export default {
    placeholder: {
        mobile: 'Cep telefonu numarası',
        verificationCode: `Sms'ten doğrulama kodu`,
        password:'Parola',
        confirmPassword: 'Şifre Tekrar',
        inviteCode: 'Davet kodu',
        nickname: 'Takma ad',
        lineId: 'Hat Hesabı',
    },
    signup: {
        smsSent:'Telefonunuza tanımlama kodu gönderildi',
        smsFailed: 'SMS gönderirken bir sorun oluştu, lütfen daha sonra deneyin',
        terms: {
            pre: `Kaydol'a tıklayarak, aşağıdakileri kabul etmiş olursunuz`,
            link: 'Şartlar, Veri Politikası ve Çerez Politikası.',
            post: 'Bizden SMS bildirimleri alabilir ve istediğiniz zaman vazgeçebilirsiniz',
        },
    },
};