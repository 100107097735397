export default {
    label: {
        applyForHelp: 'Terapkan untuk Bantuan',
        iWantHelp: 'Aku ingin membantu',
        whatIsThis: "Apa ini",
        latestNews: 'Berita Terbaru',
        more: 'Lagi',
        donated: 'disumbangkan',
        to: 'Ke',
        time: 'Waktu',
        process: 'Proses',
        process1: 'mengajukan permohonan',
        process2: 'Audit platform',
        process3: 'Mulai menyumbang',
        nickname: 'Nama panggilan',
        mobile: 'Nomor telepon',
        patientName: 'Nama pasien',
        description: 'Keterangan',
        donationAmount: 'Jumlah donasi',
        says: 'Mengatakan',
        thankYou: 'Terima kasih telah menunjukkan cinta padaku',
        amount: 'Jumlah',
        comment: 'Komentar',
        target: 'Target',
        raised: 'Dibesarkan',
        aims: 'Tujuan',
        helpTimes: 'Waktu bantuan',
        recipients: 'Penerima',
        nameCertified: 'Bersertifikat nama asli',
        accountsReceivable: 'Piutang usaha:',
        idCardapproved: 'Kartu identitas disetujui',
        proofApproved: 'Bukti hubungan disetujui',
        verificationPassed: 'Menerima verifikasi kartu bank lulus',
        promise: 'Janji promotor',
        platformStatement: 'Pernyataan platform',
        expandAll: 'Melebarkan semua',
        closeAll: 'Ciutkan semua',
        askFriend: 'Minta Teman Anda Untuk Membantu',
        applyForDonation: 'Ajukan donasi',
        backgroundStory: 'Latar Belakang Cerita',
        evidence: 'Bukti',
        witnessList: 'daftar saksi',
        progress: 'Kemajuan',
        postedGrogress: 'kemajuan yang diposting',
        Malltext: 'Jika Anda memerlukan bantuan, silakan masuk ke platform kami',
        iWantHelptext: 'Berikan cintamu dan buat dunia lebih baik!',
    },
    hits: {
        application: `Setelah aplikasi donasi diajukan, staf platform akan menghubungi Anda melalui telepon.`,
        requireAmount: 'Silakan isi jumlah target donasi',
        comments1: `Tindakan kecil kebaikan bergabung menjadi lautan cinta, dan saya harap Anda sembuh.`,
        comments2: `Semoga Anda pulih.`,
        recipientDetail1: 'Perlindungan modal tingkat atas, platform yang dipercaya oleh pengguna global',
        joinPlatform: 'Jika Anda membutuhkan bantuan, silakan bergabung dengan platform kami',
        dedication: 'Dedikasi cinta',
        referral: 'Mengajukan rujukan dapat mempercepat donasi',
        requestHelp: 'Mulai permintaan bantuan',
    },
    placeholder: {
        requireWhatAppID: 'Silakan masukkan id baris',
        whatAppIDLength: 'panjang id aplikasi whats harus 1 hingga 30',
        requirePatientName: 'Silakan masukkan nama pasien',
        patientNameLength: 'Panjang nama pasien harus 1 hingga 50',
        requireDesc: 'Mohon masukan deskripsi',
        descLength: 'Description length should be 10 to 1000',
        requireAmount: 'Panjang deskripsi harus 10 hingga 1000',
    },
    message: {
        notAllowed: 'Donasi untuk diri sendiri tidak diperbolehkan',
    },
};