export default {
    inviteFriends: 'Arkadaşları davet etmek',
    message: 'İleti',
    availableAssets: 'Mevcut varlıklar',
    yesterdayEarnings: "dün",
    cumulativeIncome: 'Kümülatif',
    todayEarnings: 'bugün',
    recharge: 'Şarj',
    withdraw: 'Çekil',
    aboutUS: 'Hakkımızda',
    whyUS: 'Neden biz',
    howToEarn: 'Nasıl kazanılır',
    howToShare: 'Nasıl Paylaşılır',
    missionList: 'Görev Listesi',
    commission: 'Komisyon',
    BeginnersGuide: 'Yeni başlayanların kılavuzu',
    experienceGold: 'Altın Deneyimi',
    ChooseYourAvatar:'Avatarını seç',
    integral: 'integral'
};