export default {
    label: {
        advisory: 'penasehat',
        complaint: 'Keluhan',
        faq: 'Pertanyaan yang Sering Diajukan',
    },
    message: {
        // system_success: '成功',
        // system_fail: '失败',
        // system_add_suc: '新增成功',
        // system_add_fail: '新增失败',
        // system_del_suc: '删除成功',
        // system_del_fail: '删除失败',
        // system_update_suc: '更新成功',
        // system_update_fail: '更新失败',
        // system_api_error: '接口异常',
        // system_opt_suc: '操作成功',
        // system_opt_fail: '操作失败',
        // system_opt_edit: '编辑',
        // system_opt_del: '删除',
        // system_data_not_empty: '数据不能为空',

        // login_username_not_empty: '登录账号不能为空',
        // login_password_not_empty: '登录密码不能为空',
        // login_username_not_exist: '登录账号不存在',
        // login_pass_err: '登录密码错误',
        // login_username_freeze: '用户已冻结,不能登录',
        // login_username_lt_4: '登录账号不应低于4位',
        // login_password_empty: '请输入登录密码',
        // login_password_lt_4: '登录密码不应低于4位',
        // login_success: '登录成功',
        // login_fail: '登录失败',
        // login_param_empty: '账号或密码为空',
        // login_param_unvalid: '账号或密码错误',

        // logout_btn: '注销',
        // logout_confirm: '确认注销登录?',
        // logout_success: '注销成功',
        // logout_fail: '注销失败',


        // user_add: '新增用户',
        // user_update: '更新用户',
        // user_username_repeat: '账号重复',
        // user_username_valid: '限制以小写字母开头，由小写字母、数字组成',
        // user_password_update_placeholder: '请输入新密码，为空则不更新密码',
        // user_update_loginuser_limit: '禁止操作当前登录账号',
        // user_not_exist_update_fail: '修改失败,账号不存在',
        // user_old_password_err: '旧密码错误请重新输入',
        // user_nick_not_empty: '昵称不能为空',
        // user_code_not_empty: '验证码不能为空',
        // user_sms_code_expired: '验证码已过期',
        // user_sms_code_err: '验证码错误',
        // user_invitation_code_not_exist: '邀请码不存在',
        // user_invitation_code_err: '邀请码错误',
        // user_level_not_configured: '无等级信息,请配置等级',
        // user_reg_fail: '注册失败',
        // user_send_sms_fail: '发送失败,请配置短信内容',
        // user_account_not_exist: '账户不存在',
        // user_avatar_not_empty: '头像地址不能为空',
        // user_transaction_password_not_empty: '交易密码不能为空',
        // user_task_log_create_fail: '生成任务记录失败',
        // user_task_not_exist: '领取失败,任务不存在',
        // user_task_not_completed: '领取失败,任务未完成',
        // user_task_received: '领取失败,奖励已领取',
        // user_task_expired: '领取失败,任务已过期',
        // user_task_state_error: '领取失败,任务状态错误',
        // user_failed_to_modify_balance: '领取失败,修改余额失败',
        // user_task_receive_fail: '领取失败',
        // user_insufficient_balance: '余额不足',

        // level_config_1: 'VIP数据错误',
        // level_config_3: '您已经是此等级,无需升级',
        // level_config_4: '升级失败,扣除余额失败',
        // level_config_5: '升级失败,修改用户信息失败',

        // member_bank_1: '绑定银行卡失败',

        // order_save_1: '请联系管理配置产品小类!',
        // order_save_2: '数据有误，请联系管理员!',
        // order_save_3: '更新佣金失败!',
        // order_save_5: '扣款失败!',
        // order_save_6: '下单失败!!!',
        // order_save_7: '订单数量已达到当前最大订单等级，请提高等级。!!!',
        // order_detail_1: '数据有误，请联系管理员!',
        // order_detail_2: '用户VIP等级不够!',
        // order_detail_3: '每天只能抢购20单',
        // order_detail_4: '该专区需要最大金额',
        // order_detail_5: '请联系管理配置产品小类!',
        // order_detail_6: '订单状态有误!',

        // user_buy_record_save_1: '请勿重复购买!',
        // user_buy_record_save_2: '数据有误,请联系客服!',
        // user_buy_record_save_3: '参数有误,请联系管理员!',
        // user_buy_record_save_4: '小于最少限制,请重新购买!',
        // user_buy_record_save_5: '超出最大限制,请重新购买!',
        // user_buy_record_save_6: '用户VIP等级不够!',

        // web_pay_add_1: '该通道未配置',
        // web_pay_add_2: '参数有误',
        // web_pay_add_3: '支付订单创建成功',
        // web_pay_add_4: '支付订单创建失败',

        // web_pay_create_pay_1: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_pay_2: '平台卡有误',
        // web_pay_create_pay_3: '通道已关闭，请选择其他通道',
        // web_pay_create_pay_4: '提交金额不在允许范围内',

        // web_pay_create_proxy_1: '请求数据有误，提现卡号不是本人或不存在!',
        // web_pay_create_proxy_2: '您好，帐号银行卡被锁定，请联系客服!',
        // web_pay_create_proxy_3: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_proxy_4: '您好，每日最多提款次数为:3次',
        // web_pay_create_proxy_5: '您好，最小提款金额为',
        // web_pay_create_proxy_6: '您好，最大提款金额为',
        // web_pay_create_proxy_9: '提现成功!',
        // web_pay_create_proxy_10: '提现失败',

        // login_expired:'登录已过期，请重新登录',
        // login_network_error:'网络异常，请检查网络链接',

        system_success: 'Kesuksesan',
        system_fail: 'Kegagalan',
        system_add_suc: 'Tambah Sukses',
        system_add_fail: 'Tambahkan Kegagalan',
        system_del_suc: 'Berhasil dihapus',
        system_del_fail: 'Gagal menghapus',
        system_update_suc: 'Pembaruan berhasil',
        system_update_fail: 'Pembaharuan gagal',
        system_api_error: 'Pengecualian antarmuka',
        system_opt_suc: 'Operasi yang berhasil',
        system_opt_fail: 'Operasi gagal',
        system_opt_edit: 'Editor',
        system_opt_del: 'Menghapus',
        system_data_not_empty: 'Data tidak boleh kosong',

        login_username_not_empty: `Akun masuk tidak boleh kosong`,
        login_password_not_empty: 'Kata sandi masuk tidak boleh kosong',
        login_username_not_exist: 'Akun masuk tidak tersedia',
        login_pass_err: 'Kata sandi masuk salah',
        login_username_freeze: 'Pengguna telah dibekukan dan tidak dapat masuk',
        login_username_lt_4: 'Nomor akun login tidak boleh kurang dari 4 digit',
        login_password_empty: 'Silakan masukkan kata sandi masuk',
        login_password_lt_4: 'Kata sandi masuk tidak boleh kurang dari 4 digit',
        login_success: 'Login berhasil',
        login_fail: 'Gagal masuk',
        login_param_empty: 'Akun atau kata sandi kosong',
        login_param_unvalid: 'Akun atau kata sandi salah',

        logout_btn: 'Pembatalan',
        logout_confirm: 'Konfirmasi keluar?',
        logout_success: 'Penghapusan berhasil',
        logout_fail: 'Kegagalan pembatalan',

        user_add: 'Pengguna baru',
        user_update: 'Perbarui pengguna',
        user_username_repeat: 'Akun duplikat',
        user_username_valid: 'BatasanDimulai dengan huruf kecil, terdiri dari huruf kecil, angka',
        user_password_update_placeholder: 'Silakan masukkan kata sandi baru, jika kosong, kata sandi tidak akan diperbarui',
        user_update_loginuser_limit: 'Melarang pengoperasian akun yang saat ini masuk',
        user_not_exist_update_fail: 'Modifikasi gagal, akun tidak ada',
        user_old_password_err: 'Kata sandi lama salah, silakan masukkan kembali',
        user_nick_not_empty: 'Nama panggilan tidak boleh kosong',
        user_code_not_empty: 'Captcha tidak boleh kosong',
        user_sms_code_expired: 'Captcha telah kedaluwarsa',
        user_sms_code_err: 'kesalahan captcha',
        user_invitation_code_not_exist: 'Kode undangan tidak ada',
        user_invitation_code_err: 'Kesalahan kode undangan',
        user_level_not_configured: 'Tidak ada informasi level, harap konfigurasikan level',
        user_reg_fail: 'Registrasi gagal',
        user_send_sms_fail: 'Pengiriman gagal, harap konfigurasikan konten pesan',
        user_account_not_exist: 'akun tidak ada',
        user_avatar_not_empty: 'Alamat avatar tidak boleh kosong',
        user_transaction_password_not_empty: 'Password transaksi tidak boleh kosong',
        user_task_log_create_fail: 'Gagal membuat catatan tugas',
        user_task_not_exist: 'Gagal mengumpulkan, misi tidak ada',
        user_task_not_completed: 'Gagal mengumpulkan, misi tidak selesai',
        user_task_received: 'Klaim gagal, hadiah diklaim',
        user_task_expired: 'Gagal mengumpulkan, misi telah kedaluwarsa',
        user_task_state_error: 'Gagal mengumpulkan, kesalahan status misi',
        user_failed_to_modify_balance: 'Gagal mengumpulkan, gagal mengubah saldo',
        user_task_receive_fail: 'Gagal mengumpulkan',
        user_insufficient_balance: 'Saldo tidak mencukupi',

        level_config_1: 'Kesalahan data VIP',
        level_config_3: 'Anda sudah berada di level ini, tidak perlu memutakhirkan',
        level_config_4: 'Gagal meningkatkan, gagal mengurangi saldo',
        level_config_5: 'Upgrade gagal, ubah informasi pengguna gagal',

        member_bank_1: 'Gagal mengikat kartu bank',
        member_bank_2: 'Metode penarikan sudah ada',

        order_save_1: 'Silakan hubungi sub-kategori produk Konfigurasi Manajemen',
        order_save_2: 'Silahkan hubungi admin jika datanya salah',
        order_save_3: 'Pembaruan komisi gagal',
        order_save_5: 'Debit gagal',
        order_save_6: 'Pesanan yang ditempatkan gagal',
        order_save_7: 'Jumlah pesanan telah mencapai level pesanan maksimum saat ini, Harap naikkan levelnya.',
        order_save_8: 'Ada pesanan yang tertunda.',
        order_save_9: 'Pesanan telah kedaluwarsa, harap hubungi layanan pelanggan untuk memperbarui.',
        order_save_10: 'Pesanan telah dibekukan, harap hubungi layanan pelanggan untuk mencairkan.',
        order_save_11: 'Jika saldo tidak mencukupi, selisihnya harus diisi.',

        order_detail_1: 'Silahkan hubungi admin jika datanya salah',
        order_detail_2: 'Level VIP pengguna tidak cukup',
        order_detail_3: 'Hubungi Spesialis Layanan Pelanggan Anda',
        order_detail_4: 'Jumlah maksimum yang diperlukan untuk area ini',
        order_detail_5: 'Silakan hubungi sub-kategori produk Konfigurasi Manajemen',
        order_detail_6: 'Status pesanan salah!',

        user_buy_record_save_1: 'Tolong jangan beli dua kali',
        user_buy_record_save_2: 'Silahkan hubungi customer service jika datanya salah.',
        user_buy_record_save_3: 'Silakan hubungi administrator jika ada kesalahan dalam parameter',
        user_buy_record_save_4: 'Kurang dari batas minimum, silakan beli kembali',
        user_buy_record_save_5: 'Batas maksimum terlampaui, silakan beli kembali',
        user_buy_record_save_6: 'Level VIP tidak cukup untuk pengguna',
        user_buy_record_save_7: 'Anda memiliki pesanan terbuka, silakan isi terlebih dahulu',
        user_buy_record_save_8: 'Pesanan belum selesai dan tidak dapat ditarik',
        

        web_pay_add_1: 'Saluran ini tidak dikonfigurasi',
        web_pay_add_2: 'Parameter yang salah',
        web_pay_add_3: 'Pesanan pembayaran berhasil dibuat',
        web_pay_add_4: 'Kegagalan pembuatan pesanan pembayaran',

        web_pay_create_pay_1: 'Halo, karena pesanan terakhir Anda belum diproses, harap tunggu sebentar dan kirimkan lagi',
        web_pay_create_pay_2: 'Kartu platform yang salah',
        web_pay_create_pay_3: 'Akses ditutup, silakan pilih akses lain',
        web_pay_create_pay_4: 'Jumlah yang dikirimkan tidak dalam kisaran yang diizinkan',

        web_pay_create_proxy_1: 'Data permintaan salah, nomor kartu penarikan bukan orangnya atau tidak ada',
        web_pay_create_proxy_2: 'Halo, kartu akun terkunci, silakan hubungi layanan pelanggan',
        web_pay_create_proxy_3: 'Halo, karena pesanan terakhir Anda belum diproses, harap tunggu sebentar dan kirimkan lagi',
        web_pay_create_proxy_4: 'Halo, jumlah penarikan maksimum per hari adalah: {days}',
        web_pay_create_proxy_5: 'Halo, jumlah penarikan minimum adalah {min}',
        web_pay_create_proxy_6: 'Halo, jumlah penarikan maksimum adalah {max}',
        web_pay_create_proxy_9: 'Penarikan yang berhasil',
        web_pay_create_proxy_10: 'penarikan gagal',
        web_pay_create_proxy_11:'Kata sandi keamanan salah; silakan periksa kembali kata sandi keamanan Anda atau hubungi layanan pelanggan online untuk mengubahnya',
        web_pay_create_proxy_12:'Jika kata sandi keamanan dimasukkan terlalu sering, sistem akan membekukan akun. Silakan hubungi layanan pelanggan untuk mencairkan akun',
        web_pay_create_proxy_13:"Nilai tukar telah diperbarui, harap segarkan",
        
        login_expired:'Login telah kedaluwarsa. Silakan masuk lagi.',
        login_network_error:'Jaringan tidak normal. Periksa koneksi jaringan.',
        user_sms_fail:'Kode verifikasi SMS berlaku dalam 2 menit',

        user_experience_cannot: 'Saldo berisi uang percobaan {experienceGold} dan tidak dapat digunakan untuk penarikan',
        user_experience_cannot_up_vip: 'Saldo berisi uang percobaan {experienceGold} dan tidak dapat digunakan untuk peningkatan VIP',
        user_experience_cannot_ai: 'Saldo berisi dana percobaan {experienceGold} dan tidak dapat digunakan untuk membeli perwalian'
    }
};