export default {
    somethingWentWrong: 'Bir şeyler ters gitti, lütfen daha sonra deneyin',

    signup: {
        success: 'Başarılı Kayıt',
        termOfService: 'Kaydolmak için hizmet şartlarımızı kabul etmelisiniz',
        invalidInviteCode: "Üzgünüz, davet kodu geçersiz",
    },
    resetPassword: {
        success: 'Şifre değiştirildi',
    },
    validator: {
        mobile: {
            required: 'Lütfen cep telefonu numarasını girin',
            invalid: 'Lütfen doğru cep telefonu numarasını girin',
            exists: 'Telefon numarası kaydedildi',
            notExists: 'Hesap mevcut değil',
            startWith0:'0 ile başlayamaz',
            errorlength: 'Cep telefonu numarası uzunluğu 8 - 11'// veya 11     
        },
        nickname: {
            required: 'Lütfen takma adınızı giriniz',
            length: 'Takma ad çok kısa',
        },
        inviteCode: {
            required: 'Lütfen davet kodunu girin',
            invalid: 'Geçersiz davet kodu',
        },
        password: {
            required: 'Lütfen şifrenizi girin',
            length: 'Minimum uzunluk 6 karakter',
        },
        checkPass: {
            required: 'Lütfen şifreyi tekrar girin',
            notMatch: 'Şifreyi tekrarla eşleşmiyor',
        },
        identifyCode: {
            required: `Lütfen sms'den tanımlama kodunu girin`,
            invalid: 'Geçersiz tanımlama kodu',
        },
    },
    grab:{
         noAvailableToday:'bugün müsait değil',
         finishTasks:'Bugün tüm görevleri bitirdin',
         insufficient:'yetersiz hesap bakiyesi'
    },
    withDraw:{
        amountErr:'Merhaba, minimum para çekme miktarı {min}, maksimum para çekme miktarı {max}'
    },
    recharge:{
        noAmount:'Lütfen miktarı seçin',
        noChannel:'Lütfen kanal seçin',
        noMatchLimit:'Kanal sınırı aşıldı',
        screenshotsRequired:"Transfer fişini yüklemelisiniz",
        decimalErr:'Lütfen en fazla iki ondalık basamak girin',
        decimalErr4:'4 ondalık basamağı geçemez',
        amountErr:'Merhaba, minimum şarj miktarı {min}, maksimum şarj miktarı {max}',
        selectBank: 'Lütfen Bağır Bankasını seçin'
    },
    server: {
        tokenError:'giriş bilgilerinin süresi doldu',
        startupQuery: 'Ağ hatası nedeniyle yükleme yapılandırması başarısız oldu, lütfen daha sonra deneyin',
        abnormal:'Ağ anormal. Ağ bağlantısını kontrol edin',
        error400: 'Geçersiz istek',
        error401: 'Yetkisiz',
        error403: 'Yasaklı',
        error404: 'Kaynak mevcut değil',
        error405: 'Ağ hatası',
        error408: 'İstek zaman aşımına uğradı',
        error500: 'Sunucu dahili hatası',
        error501: 'Sunucu dahili hatası',
        error502: 'Sunucu ağ hatası',
        error503: 'Sunucu geçici olarak kullanılamıyor',
        error504: 'Ağ zaman aşımı',
        error505: 'Tarayıcı hatası',
        InvalidPhoneNumber: 'Geçersiz telefon numarası',
        InvalidFeeRate: 'Sunucu yapılandırma hatası',
        NoEnoughBalance: 'Yetersiz bakiye',
        WithdrawFrequency: 'Sıklık limitini geri çekme',
        WithdrawAmountExceedVipLimitation: 'Çekim miktarı VIP seviye sınırını aşıyor',
        TooManyWithdraws: 'Bugün çok fazla para çekme, lütfen yarın deneyin',
        MaximumVipLevel: 'Maksimum VIP seviyesine ulaşıldı',
        NoPaymentChannelAvailable: 'Ödeme kanalı kullanılamıyor, lütfen daha sonra deneyin',
        LastVipUpgradeProcessing: 'VIP seviye yükseltmesi devam ediyor, lütfen bir süre bekleyin',
        WithdrawNotOpenForNow: 'Çekilme hizmet zaman aralığı dışında',
        WithdrawNotAllowed: 'Para çekme koşulu yerine getirilmedi, lütfen müşteri hizmetleri ile iletişime geçin',
        RechargeNotOpenForNow: 'Şarj dışı servis zaman aralığı',
        InvalidRechargeAmount: 'Geçersiz şarj miktarı',
        FundPasswordNotSet: 'Lütfen önce para çekme şifresini belirleyin',
        FundPasswordNotMatch: 'Lütfen doğru para çekme şifresini girin',
        TooManyProcessingTasks: 'Çok fazla işlem görevi',
        InvalidTask: 'Görev geçersiz',
        InvalidOldPassword: 'Lütfen doğru eski şifreyi girin',
        VipLevelLimit: 'VIP seviye sınırı',
        AccountNotExists: 'Hesap mevcut değil',
        InvalidIdentifyCode: 'Lütfen doğru tanımlama kodunu girin',
        InvalidInviteCode: 'Lütfen doğru davet kodunu girin',
        MobileHasBeenRegistered: 'Telefon numarası kaydedildi',
        AmountExceedsDepositoryThreshold: 'AI güven yönetimi miktarı eşiği aşıyor',
        TooManyGrabsToday: 'Bugün tüm görevleri bitirdin',
        ValidationError: 'Form değeri geçersiz değer içeriyor',
        BankcardExists: 'Banka kartı bilgileri zaten mevcut',
        DonationComplete: 'Alıcı yeterince bağış aldı, nazik yardımlarınız için teşekkür ederiz',
        GrabNotComplete: 'Lütfen para çekme işlemini başlatmadan önce tüm sipariş görevlerini bugün tamamlayın',
        AlreadySubmitDonationApplication: "Üzgünüz ama birden çok kez bağışta bulunamazsınız",
        DonationLimited: 'Cömertliğiniz için teşekkür ederim, ancak bağış yapmadan önce biraz para yüklemelisiniz',
        AgentNotExists: 'Ajan bulunamadı',
        AccountBanned: 'Hesap hareket limiti',
        SomethingWentWrong: 'Bir şeyler yanlış gitti',
        SystemMaintenance: 'Sistem bakımda, lütfen daha sonra deneyin',
        ServerError: 'Sunucu geçici olarak kapalı, lütfen daha sonra deneyin',
        GrabFrequency: 'Sınır sınırını yakala',

    },
};