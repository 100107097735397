export default {
    placeholder: {
        mobile: 'Nomor ponsel',
        verificationCode: 'Kode verifikasi dari sms',
        password: 'Kata Sandi',
        confirmPassword: 'Sandi lagi',
        inviteCode: 'Kode undangan',
        nickname: 'Nama Panggilan',
        lineId: 'Akun LINE',
    },
    signup: {
        smsSent: 'Identifikasi kode telah dikirim ke ponsel Anda',
        smsFailed: 'Ada yang salah saat mengirim SMS, silakan coba nanti',
        terms: {
            pre: 'Dengan mengklik Daftar, Anda setuju dengan kami',
            link: 'Ketentuan, Kebijakan Data, dan Kebijakan Cookie.',
            post: 'Anda mungkin menerima notifikasi SMS dari kami dan dapat memilih keluar kapan saja',
        },
    },
};