<template>
  <div class="g-doc">
    <router-view class="g-con"></router-view>

    <div class="g-footer">
      <template v-for="tab in tabs" :key="tab.title">
        <router-link
          v-bind="$props"
          custom
          v-slot="{ isActive }"
          :to="tab.path"
        >
          <div
            v-bind="$attrs"
            class="g-footer-item"
            :class="{ current: isActive }"
            @click.prevent="router.push(tab.path)"
          >
            <span class="g-footer-item-img" :class="tab.clazz" />
            <span class="g-footer-item-title">
              {{ t('common.menu.' + tab.key) }}
            </span>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from '@/lang';
const tabs = [
  {
    clazz: 'homeImg',
    key: 'home',
    path: '/home',
  },
  // {
  //   clazz: 'categoryImg',
  //   key: 'team',
  //   path: '/team',
  // },
  {
    clazz: 'orderImg',
    key: 'grabOrder',
    path: '/grabOrder',
  },
  // {
  //   clazz: 'rankImg',
  //   title: 'Lazada Cares',
  //   key: 'index',
  //   path: '/Mall',
  // },
  {
    clazz: 'taskImg',
    key: 'grabOrderList2',
    path: '/grabOrderList2',
  },
  {
    clazz: 'mineImg',
    key: 'my',
    path: '/profile',
  },
];

export default defineComponent({
  title: 'Layout',
  setup() {
    const { t } = useI18n();
    const currentTabIndex = ref(0);
    const title = ref('标题');
    const router = useRouter();

    return {
      t,
      title,
      currentTabIndex,
      tabs,
      router,
    };
  },
});
</script>

<style lang="scss">
@import '../scss/_utils.scss';

.g-doc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  background-color: #f5f4f7;

  .g-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: px2rem($header-height);
    padding-right: px2rem(15);
    padding-left: px2rem(15);
    line-height: px2rem($header-height);
    color: white;
    text-align: center;
    background-color: $primary;

    &-back {
      position: absolute;
      top: px2rem(30);
      left: px2rem(30);
      width: px2rem(36);
      height: px2rem(34);
      background-image: url('../assets/images/ic_back.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-title {
      @include font-dpr(18);
    }

    &-search {
      position: relative;
      width: 100%;
      height: px2rem(60);

      .searchBox {
        width: 100%;
        height: 100%;
        padding-left: px2rem(60);
        margin: px2rem(25) auto;
        color: $dark;
        text-align: center;
        background-color: #fff;
        background-image: url('../assets/images/search.png');
        background-position: 45% center;
        background-repeat: no-repeat;
        background-size: px2rem(40) px2rem(40);
        border-width: 0;
        border-radius: px2rem(10);
        filter: grayscale(90%);
        @include font-dpr(16);
      }

      .searchClear {
        position: absolute;
        top: px2rem(40);
        right: px2rem(10);
        width: px2rem(30);
        height: px2rem(30);
        background-image: url('../assets/images/clear_input1.png');
        background-size: contain;
      }
    }

    &-search.has-focus > .searchBox {
      text-align: left;
      background-position-x: px2rem(10);
    }

    &-apps {
      position: absolute;
      top: px2rem(30);
      right: px2rem(30);
      width: px2rem(36);
      height: px2rem(34);
      background-image: url('../assets/images/ic_app_manage.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-layer {
      position: fixed;
      top: px2rem(100);
      bottom: px2rem(85);
      left: 0;
      width: 100vw;
      background-color: $dark;
      opacity: 0.5;
    }

    &-keywords {
      position: fixed;
      top: px2rem(100);
      bottom: px2rem(85);
      left: 0;
      z-index: 1;
      width: 100%;
      background-color: white;
      opacity: 0.9;

      &-item {
        margin: 0 px2rem(10);
        color: $dark;
        text-align: left;
        border-bottom: px2rem(1) solid $light;
        @include font-dpr(16);
      }
    }
  }

  .g-con {
    position: absolute;
    top: 0;
    bottom: px2rem($footer-height);
    left: 0;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    @include translate3d(0, 0, 0);
  }

  .g-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    width: 100vw;
    height: px2rem($footer-height);
    padding: 0 px2rem(20);
    justify-content: center;
    flex-direction: row;

    /* border-top: 1px solid $dark; */
    &::before {
      @include border-horizontal($background: $dark);

      top: 0;
    }

    &-item {
      position: relative;
      display: flex;
      width: 90%;
      height: 100%;
      padding-bottom: px2rem(10);
      margin: 0;
      text-align: center;
      border: 0;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      @include font-dpr(12);

      &-mask {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: white;
        border: 0;
        opacity: 0.98;
      }

      &-img {
        width: px2rem(40);
        height: px2rem(38);
        margin: auto;
        background-repeat: no-repeat;
        background-size: contain;
        filter: grayscale(90%);

        &.homeImg {
          background-image: url('../Content/images/nav_01_on.png');
        }

        &.categoryImg {
          background-image: url('../Content/images/nav_02_on.png');
        }
        
        &.taskImg {
          background-image: url('../Content/images/task.jpg');
        }

        &.orderImg {
          background-image: url('../Content/images/money.png');
        }

        &.rankImg {
          background-image: url('../Content/images/nav_07_on.png');
        }

        &.searchImg {
          background-image: url('../Content/images/nav_05_on.png');
        }

        &.mineImg {
          background-image: url('../Content/images/nav_06_on.png');
        }
      }

      &.current {
        color: $primary;

        .g-footer-item-img {
          filter: grayscale(0%) !important;
        }
      }
    }
  }
}
</style>

<style scoped>
/* @font-face {
    font-family: 'Niagara Solid';
    src:url('../Content/font/NIAGSOL.TTF') format('truetype');
}
@import '../Content/iconfont/iconfont.css';
@import '../Content/System_Ui.css'; */
</style>
