export default {
    label: {
        language:'Idioma',
        total: 'Total',
        count:'Contar',
        orderProfits: 'Lucros do pedido',
        totalCommission: 'Comissão total',
        walletBalance: 'Saldo da minha carteira',
        Funds_accepted_in_trust:'Fundos aceitos em confiança',
        rechargeInstructions: 'Instruções de recarga',
        setTransPassword: 'Definir senha da transação',
        password: 'Senha',
        confirmPassword: 'Confirme a Senha',
        changePassword: 'Por favor, digite sua nova senha',
        oldPassword: 'Senha Antiga',
        newPassword: 'Nova Senha',
        confirmPasswordLower: 'Confirme a Senha',
        //use_info
        avatar: 'Avatar',
        nickname: 'Apelido',
        myCellNo: 'Meu número de celular',
        myBankId: 'Meu cartão bancário',
        myWithdrawal: 'Meu método de saque',
        changeUserPassword: 'Gerenciamento de senha',
        withdrawPassword: 'Retirar senha',
        shippingAddress: 'Endereço para envio',
        //address
        realInfo: 'Recebendo informações de identidade',
        actualName: 'Nome real',
        actualCellNo: 'Número de telefone',
        addressInfo: 'Informações do endereço de entrega',
        addressArea: 'Área',
        address: 'Endereço',
        //bankcard
        yourId: 'Sua identidade',
        accountName: 'Nome da conta',
        contactMobile: 'Telefone de contato',
        yourAccountInfo: 'Informações da sua conta',
        bank: 'Banco',
        accountNo: 'Número de conta',
        //depository
        availableAssets: 'Recursos disponíveis',
        yesterdayEarn: "Ganhos de ontem",
        cumulativeIncome: 'Renda acumulada',
        days: 'Dias',
        expectedProfits: 'Lucros Esperados',
        vipLevelRequired: 'Nível VIP necessário',
        fee: 'Taxa',
        feeAmount:'Valor da taxa',
        actualToAccount:'O real a ser contabilizado',
        min: 'Mín',
        max: 'Máx',
        time: 'Tempo',
        serialNumber: 'Número de série',
        orderNumber: 'número do pedido',
        amount: 'Montante',
        profits: 'Lucros',
        expireDate: 'Data de validade',
        expectedReturn: 'Retorno esperado',
        //grab
        commission: 'Comissão',
        vip: 'VIP',
        grabbedToday: 'Capturado hoje',
        availableToday: 'Disponível hoje',
        commissionYesterday: 'Comissões ontem',
        commissionsTotal: 'Total de comissões',
        commissionsToday: 'Comissões hoje',
        vipLevelUpgrade: 'Atualização de nível VIP',
        automaticOrder: 'Pedido automático',
        explanation: 'Explicação',
        orderRecord: ' Registro do pedido',
        return: 'Retornar',
        orderAmount:'Valor do pedido',
        //recharge
        rechargeSuccess: 'Sucesso de recarga',
        //withdraw
        withdrawAmount: 'Quantidade retirada',
        balance: 'Equilíbrio',
        tax: 'Imposto',
        afterTax: 'Após impostos',
        contactEmail: 'email de contato',
        extraBonus: 'bônus extra',
        selectPaymentChannel: 'Selecionar canal',
        teamReports:'relatórios',

        methodType_1:'pix',
        methodType_2:'USDT-TRC20',
        methodType_3:'USDT-ERC20',
        methodType_4:'cartão do banco',
        pixType: 'pix tipo',

        teamReportsTitle:'Visão geral dos relatórios da equipe',
        teamReportsDetail:'Detalhe dos relatórios da equipe',
        //team records
        chargeTotalAmount:'Recarga total',
        firstChargeNumber:'O total de primeiros usuários de recarga',
        orderAmountTotal:'O dinheiro total dos pedidos',
        payTotalAmount:'Total de saques',
        profitTotal:'Lucro total',
        registerNumber:'Total de registros',
        subordinateNumber:'Total de subordinados',
        subordinateNumber1:'Total de membros',
        teamBalance:'Dinheiro total das contas da equipe',
        totalPeople: 'O total de usuários que pagaram pedidos',

        teamOrderRecords:'ordem',

        teamAccountRecords:'contas',

        rechargeDetail:'Recarrega',
        withdrawDetail:'cancelamento',
        orderAndRebateDetail:'Pedido',

        agencyType:'Hierarquia',
        LastLogin:'Último Login',
        myself:'Eu mesmo'

    },
    menu: {
        trustManagement: 'Gerenciamento de confiança de IA',
        information: 'Em formação',
        orderRecord: 'Registro do pedido',
        accountDetails: 'Detalhes da conta',
        rechargeRecord: 'Registro de recarga',
        withdrawRecord: 'Registro de saque',
        inviteFriends: 'Convide amigos',
        announcement: 'Anúncio',
        about: 'Sobre nós',
        download: 'Download do aplicativo',
        recharge: 'Recarrega',
        withdraw: 'Retirar',
        service: 'Serviço',
        agencyCenter:'Centro de agência',
        agencyRecords:'Registros da agência',
    },
    hits: {
        transPassword: `Lembre-se da senha, se você esquecer a senha, entre em contato com o serviço de atendimento ao cliente.`,
        bindAccount: `Dica de segurança: verifique antes de enviar as informações da conta bancária. Cada
            o usuário tem apenas uma chance gratuita de enviar conta bancária. Nós garantimos
            para manter todas as informações confidenciais. * * (Quando você deseja modificar
            as informações novamente, você precisa pagar {fee})
`,
        bindAccountConfirm: `Por favor, verifique novamente e certifique-se de que as informações estão corretas. Tem certeza de enviar?`,
        minimumAmount: 'Valor mínimo {amount}',
        maximumAmount: 'Valor máximo {amount}',
        //grab
        grabTip1: 'Na pressa de encomendas',
        grabTip2: 'Conectando-se ao centro de pedidos',
        grabTip3: 'Aguardando resposta do banco de dados',
        grabTip4: 'Aguardando o servidor alocar o pedido',
        minimumRecharge: 'Recarga mínima de {amount}',
        maximumRecharge: 'Recarga máxima de {amount}',
        recharge: `dica: Você pode encontrar todos os registros de recarga em "Registro de recarga"`,
        rechargeOrderSubmitted: 'Aguarde o administrador do sistema auditar o pedido.',
        rechargeBank: 'Transfira a quantia especificada de dinheiro para a seguinte conta e faça upload de capturas de tela',
    },
    placeholder: {
        password: 'Por favor, digite a senha',
        oldPassword: 'Por favor, digite sua senha antiga',
        oldPasswordLength: 'A senha deve ter entre 4 e 30 caracteres',
        newPassword: 'Por favor, digite uma nova senha',
        newPasswordLength: 'A senha deve ter entre 6 e 30 caracteres',
        confirmPassword: 'Por favor, digite a senha novamente',
        passwordMath: `Duas entradas não coincidem!`,
        realName: 'Por favor, digite seu nome verdadeiro',
        area: 'Por favor, insira a área',
        contactPhone: 'Por favor, digite o telefone de contato',
        validPhoneNumber: 'Insira um número de celular válido',
        address: 'Por favor, digite o endereço',
        accountName: 'Por favor, digite o nome da sua conta',
        accountNo: 'Insira o número da conta',
        selectIFSC: 'Por favor selecione o banco',
        transAmount: 'Insira o valor da transferência',
        rechargeAmount: 'Por favor, insira seu valor',
        selectPayChannel: 'Selecione o canal de pagamento',
        requireIFSC: 'Por favor, insira o IFSC',
        fundPassword: 'por favor digite sua senha de retirada',
        fundCI: 'Insira seu CI',
        fundCI2: 'por favor insira seu número de identificação',
        withdrawAmount: 'Por favor, insira o valor do saque',
        excessBalance: 'Excesso de saldo',
        bank: 'Insira o nome do seu banco',
        withdrawalType:'Por favor, selecione um método de retirada',
        contactEmail: 'Por favor, insira seu e-mail de contato',
    },
    agencyCenter:{
        subordinatesManagement:'gestão de subordinados',
        rechargeROT:'recarregar registros da equipe',
        withdrawROT:'retirar registros da equipe',
        orderROT:'registros de pedidos de equipe',
        accountROT:'registros de contas da equipe',
        TeamRO:'Visão geral dos relatórios da equipe'
    }
};