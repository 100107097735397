export default{
    MySubordinates :'bawahan saya',
    listTheSubordinates:'daftar bawahan',
    goReports:'pergi laporan',
    virtualCurrency :'mata uang virtual',
    rechageText1:'Transfer sejumlah uang tertentu ke akun berikut dan unggah tangkapan layar.',
    rechageText2:'(*Silakan hubungi layanan pelanggan sebelum mengisi ulang dan Anda harus mengambil risiko saluran ini.)',
    coinType:'jenis koin',
    coinAmount:'jumlah koin',
    channel:'saluran',
    rate:'kecepatan',
    remark:'komentar',
    maxAmount:'jumlah maksimal',
    minAmount:'jumlah minimal',
    bankPay:'pembayaran kartu bank',
    bankName:'nama Bank',
    BeneficiaryBank:'Bank Penerima',
    holderName:`Nama asli pemegang kartu`,
    rememberPwdText:`Harap ingat kata sandi, jika Anda lupa kata sandi, silakan hubungi layanan pelanggan.`,
    versionInfo:'Informasi Versi',
    instructions:'instruksi',
    minLength:'Panjang minimum adalah {min}.',
    maxLength:'Panjang maksimum adalah {max}.',
    allSub:'Semua bawahan',
    directSub:'Bawahan langsung',
    PIX:'Silakan masukkan akun PIX',
    realName:'Nama asli'
}