export default {
    somethingWentWrong: 'Algo deu errado, tente mais tarde',

    signup: {
        success: 'Registro bem sucedido',
        termOfService: 'Você deve concordar com nossos termos de serviço para se inscrever',
        invalidInviteCode: "Desculpe, o código de convite é inválido",
    },
    resetPassword: {
        success: 'a senha foi alterada',
    },
    validator: {
        mobile: {
            required: 'Por favor, insira o número do celular',
            invalid: 'Por favor, insira o número de telefone celular correto',
            exists: 'O número de telefone foi registrado',
            notExists: 'A conta não existe',
            startWith0:'Não pode começar com 0',
            errorlength: 'The length of the mobile number is 8 - 11'// or 11
        },
        nickname: {
            required: 'Por favor, digite seu apelido',
            length: 'O apelido é muito curto',
        },
        inviteCode: {
            required: 'Insira o código de convite',
            invalid: 'Código de convite inválido',
        },
        password: {
            required: 'Por favor, insira a senha',
            length: 'Comprimento mínimo 6 caracteres',
        },
        checkPass: {
            required: 'Por favor, digite a senha novamente',
            notMatch: 'Repetir senha não corresponde',
        },
        identifyCode: {
            required: 'Por favor, insira o código de identificação do sms',
            invalid: 'Código de identificação inválido',
        },
    },
    grab:{
         noAvailableToday:'não disponível hoje',
         finishTasks:'Você terminou todas as tarefas hoje',
         insufficient:'saldo insuficiente da conta'
    },
    withDraw:{
        amountErr:'Olá, o valor mínimo de retirada é {min}, o valor máximo de retirada é {max}'
    },
    recharge:{
        noAmount:'Por favor, selecione o valor',
        noChannel:'Por favor selecione o canal',
        noMatchLimit:'Excedendo o limite do canal',
        screenshotsRequired:"Você deve fazer o upload do voucher de transferência",
        decimalErr:'Insira até duas casas decimais',
        decimalErr4:'Não pode exceder 4 casas decimais',
        amountErr:'Olá, o valor mínimo de recarga é {min}, o valor máximo de recarga é {max}',
        selectBank: 'Seleccione o Banco Beneficiário'
    },
    server: {
        tokenError:'as informações de login expirou',
        startupQuery: 'A configuração de carregamento falhou devido a um erro de rede, tente mais tarde',
        abnormal:'A rede está anormal. Verifique a conexão de rede',
        error400: 'Pedido ruim',
        error401: 'Não autorizado',
        error403: 'Proibido',
        error404: 'O recurso não existe',
        error405: 'Erro de rede',
        error408: 'Solicitar tempo limite',
        error500: 'Erro interno do servidor',
        error501: 'Erro interno do servidor',
        error502: 'Erro de rede do servidor',
        error503: 'Servidor temporariamente indisponível',
        error504: 'Tempo limite da rede',
        error505: 'Erro do navegador',
        InvalidPhoneNumber: 'Número de telefone inválido',
        InvalidFeeRate: 'Erro de configuração do servidor',
        NoEnoughBalance: 'Saldo insuficiente',
        WithdrawFrequency: 'Limite de frequência de saque',
        WithdrawAmountExceedVipLimitation: 'O valor do saque excede o limite do nível VIP',
        TooManyWithdraws: 'Muitos saques hoje, tente amanhã',
        MaximumVipLevel: 'Nível VIP máximo atingido',
        NoPaymentChannelAvailable: 'O canal de pagamento não está disponível. Tente mais tarde',
        LastVipUpgradeProcessing: 'Atualização de nível VIP em andamento, aguarde um pouco',
        WithdrawNotOpenForNow: 'Fora do intervalo de tempo de serviço de retirada',
        WithdrawNotAllowed: 'Condição de retirada não cumprida, entre em contato com o atendimento ao cliente',
        RechargeNotOpenForNow: 'Fora do intervalo de tempo do serviço de recarga',
        InvalidRechargeAmount: 'Valor de recarga inválido',
        FundPasswordNotSet: 'Por favor, defina retirar a senha primeiro',
        FundPasswordNotMatch: 'Por favor, insira a senha de retirada correta',
        TooManyProcessingTasks: 'Muitas tarefas de processamento',
        InvalidTask: 'A tarefa é inválida',
        InvalidOldPassword: 'Insira a senha antiga correta',
        VipLevelLimit: 'Limite de nível VIP',
        AccountNotExists: 'A conta não existe',
        InvalidIdentifyCode: 'Insira o código de identificação correto',
        InvalidInviteCode: 'Insira o código de convite correto',
        MobileHasBeenRegistered: 'O número de telefone foi registrado',
        AmountExceedsDepositoryThreshold: 'A quantidade de gerenciamento de confiança da IA excede o limite',
        TooManyGrabsToday: 'Você terminou todas as tarefas hoje',
        ValidationError: 'O valor do formulário contém um valor inválido',
        BankcardExists: 'As informações do cartão bancário já existem',
        DonationComplete: 'O destinatário recebeu doação suficiente, obrigado por sua gentil ajuda',
        GrabNotComplete: 'Por favor, complete todas as tarefas do pedido hoje antes de iniciar a retirada',
        AlreadySubmitDonationApplication: "Desculpe, mas você não pode aplicar a doação várias vezes",
        DonationLimited: 'Obrigado por sua generosidade, mas você deve recarregar algum dinheiro antes de doar',
        AgentNotExists: 'Agente não encontrado',
        AccountBanned: 'Limite de atividade da conta',
        SomethingWentWrong: 'Algo deu errado',
        SystemMaintenance: 'Sistema em manutenção, tente mais tarde',
        ServerError: 'Servidor temporariamente fora do ar, por favor tente mais tarde',
        GrabFrequency: 'Limite de frequência de captura',

    },
};