import {
    createRouter,
    createWebHashHistory
} from 'vue-router';
import Layout from '@/layout';
import BaseQuery from '@/BaseQuery';
import {
    Modal
} from 'ant-design-vue';
import {
    user
} from '@/hooks/user';
import {
    userDetail
} from '@/hooks/userDetail';
import {
    inviteCode
} from '@/hooks/inviteCode';
import {
    lunContent,
    tanContent
} from '@/hooks/announcement'
import mybus from '@/utils/mitt'
// import {
//     user
// } from '@/hooks/user';

import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //样式必须引入
import api from '@/api'

const routes = [{
    path: '/',
    component: BaseQuery,
    children: [{
            path: '/',
            component: Layout,
            redirect: () => {
                return user.value ? '/home' : '/login'
            },
            children: [{
                    path: '/home',
                    name: 'Home',
                    component: () =>
                        import( /* webpackChunkName: "home" */ '../views/Home.vue'),
                },
                {
                    path: '/team',
                    name: 'Team',
                    component: () =>
                        import( /* webpackChunkName: "team" */ '../views/Team.vue'),
                },
                {
                    path: '/grabOrder',
                    name: 'GrabOrder',
                    component: () =>
                        import( /* webpackChunkName: "team" */ '../views/grabOrder.vue'),
                },
                {
                    path: '/mall',
                    name: 'Mall',
                    component: () =>
                        import( /* webpackChunkName: "mall" */ '../views/Mall.vue'),
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    component: () =>
                        import( /* webpackChunkName: "tab5" */ '../views/Profile.vue'),
                },
                {
                    path: '/grabOrderList2',
                    name: 'GrabOrderList2',
                    component: () =>
                        import('../views/GrabOrderList2.vue'),
                },
            ],
        },
        {
            path: '/service',
            name: 'Service',
            component: () =>
                import(
                    /* webpackChunkName: "service" */
                    '../views/CustomerService.vue'
                ),
        },
        {
            path: '/login',
            name: 'Login',
            component: () =>
                import( /* webpackChunkName: "tab5" */ '../views/Login.vue'),
        },
        {
            path: '/register',
            name: 'Register',
            component: () =>
                import( /* webpackChunkName: "tab5" */ '../views/Register.vue'),
        },
        {
            path: '/ResetPassword',
            name: 'ResetPassword',
            component: () =>
                import( /* webpackChunkName: "tab5" */ '../views/ResetPassword.vue'),
        },
        {
            path: '/recharge',
            name: 'recharge',
            component: () =>
                import('../views/Recharge.vue'),
        },
        {
            path: '/profile/user_info',
            name: 'UserInfo',
            component: () =>
                import('../views/profile/UserInfo.vue'),
        },
        {
            path: '/profile/Withdrawal',
            name: 'Withdrawal',
            component: () =>
                import('../views/profile/Withdrawal.vue'),
        },
        {
            path: '/profile/WithdrawalCommission',
            name: 'WithdrawalCommission',
            component: () =>
                import('../views/profile/WithdrawalCommission.vue'),
        },
        {
            path: '/profile/bankcard',
            name: 'bankcard',
            component: () =>
                import('../views/profile/Bankcard.vue'),
        },
        {
            path: '/recharge_via_bank/:amount',
            name: 'RechargeBank',
            component: () =>
                import('../views/RechargeBank.vue'),
        },
        {
            path: '/recharge_online',
            name: 'Recharge_online',
            component: () =>
                import('../views/Recharge_online.vue')
        },
        {
            path: '/coinPay',
            name: 'coinPay',
            component: () =>
                import('../views/coinPay.vue')
        },
        {
            path: '/coinPayDetail',
            name: 'coinPayDetail',
            component: () =>
                import('../views/coinPayDetail.vue')
        },
        {
            path: '/profile/Withdraw_password',
            name: 'Withdraw_password',
            component: () =>
                import('../views/profile/WithdrawPassword.vue'),
        },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: () =>
                import('../views/Withdrawal.vue'),
        },
        {
            path: '/depository',
            name: 'Depository',
            component: () =>
                import('../views/Depository.vue'),
        },
        {
            path: '/order_ai',
            name: 'order_ai',
            component: () =>
                import('../views/DepositoryLIst.vue'),
        },
        {
            path: '/grab_order',
            name: 'GrabOrderList',
            component: () =>
                import('../views/GrabOrderList.vue'),
        },
        {
            path: '/grabOrderDetail',
            name: 'GrabOrderDetail',
            component: () =>
                import('../views/GrabOrderDetail.vue'),
        },
        {
            path: '/Account',
            name: 'Account',
            component: () =>
                import('../views/Account.vue'),
        },
        {
            path: '/recharge_list',
            name: 'RechargeList',
            component: () =>
                import('../views/RechargeList.vue'),
        },
        {
            path: '/withdrawal_list',
            name: 'withdrawal_list',
            component: () =>
                import('../views/WithdrawalList.vue'),
        },
        {
            path: '/profile/Password',
            name: 'Password',
            component: () =>
                import('../views/profile/Password.vue'),
        },
        {
            path: '/site_message',
            name: 'SiteMessage',
            component: () =>
                import('../views/SiteMessage.vue'),
        },
        {
            path: '/team_detail/:level',
            name: 'TeamDetail',
            component: () =>
                import('../views/TeamDetail.vue'),
        },
        {
            path: '/team_activity',
            name: 'team_activity',
            component: () =>
                import('../views/TeamActivity.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () =>
                import('../views/aboutUs/index.vue'),
        },
        {
            path: '/about_one',
            name: 'about_one',
            component: () =>
                import('../views/aboutUs/Company.vue'),
        },
        {
            path: '/about_two',
            name: 'about_two',
            component: () =>
                import('../views/aboutUs/Team.vue'),
        },
        {
            path: '/downlaod',
            name: 'downlaod',
            component: () =>
                import('../views/downlaod.vue'),
        },
        {
            path: '/about_three',
            name: 'about_three',
            component: () =>
                import('../views/aboutUs/BusinessModel.vue'),
        },
        {
            path: '/inviteFriends',
            name: 'inviteFriends',
            component: () =>
                import('../views/InviteFriend.vue'),
        },
        {
            path: '/grab/:level',
            name: 'Grab',
            component: () =>
                import('../views/Grab.vue'),
        },
        {
            path: '/profile/address',
            name: 'address',
            component: () =>
                import('../views/profile/Address.vue'),
        },
        {
            path: '/onlineRechargeHtml',
            name: 'onlineRechargeHtml',
            component: () =>
                import('../views/onlineRechargeHtml.vue')

        },
        {
            path: '/WhyUs',
            name: 'WhyUs',
            component: () =>
                import('../views/WhyUs.vue')

        },
        {
            path: '/HowToEarn',
            name: 'HowToEarn',
            component: () =>
                import('../views/HowToEarn.vue')

        },
        {
            path: '/HowToShare',
            name: 'HowToShare',
            component: () =>
                import('../views/HowToShare.vue')

        },
        {
            path:'/agencyRecords',
            name:'agencyRecords',
            component: ()=>
                import('../views/agencyRecords.vue')
        },
        {
            path:'/agencyCenter',
            name:'agencyCenter',
            component: ()=>
                import('../views/agencyCenter.vue')
        },
        {
            path:'/subordinatesManagement',
            name:'subordinatesManagement',
            component: ()=>
                import('../views/subordinatesManagement.vue')
        },
        {
            path:'/subordinatesProfit',
            name:'subordinatesProfit',
            component: ()=>
                import('../views/subordinatesProfit.vue')
        },
    ]
}]
// const routes = [{
//     path: '/',
//     component: BaseQuery,
//     children: [{
//             path: '/',
//             component: Layout,
//             redirect: () => (user.value ? .id ? '/home' : '/mall'),
//             children: [{
//                     path: '/home',
//                     name: 'Home',
//                     component: () =>
//                         import ( /* webpackChunkName: "home" */ '../views/Home.vue'),
//                 },
//                 {
//                     path: '/team',
//                     name: 'Team',
//                     component: () =>
//                         import ( /* webpackChunkName: "team" */ '../views/Team.vue'),
//                 },
//                 {
//                     path: '/mall',
//                     name: 'Mall',
//                     component: () =>
//                         import ( /* webpackChunkName: "mall" */ '../views/Mall.vue'),
//                 },
//                 {
//                     path: '/profile',
//                     name: 'Profile',
//                     component: () =>
//                         import ( /* webpackChunkName: "tab5" */ '../views/Profile.vue'),
//                 },
//             ],
//         },
//         // 不走 layout 框架
//         {
//             path: '/service',
//             name: 'Service',
//             component: () =>
//                 import (
//                     /* webpackChunkName: "service" */
//                     '../views/CustomerService.vue'
//                 ),
//         },
//         {
//             path: '/login',
//             name: 'Login',
//             component: () =>
//                 import ( /* webpackChunkName: "tab5" */ '../views/Login.vue'),
//         },
//         {
//             path: '/register',
//             name: 'Register',
//             component: () =>
//                 import ( /* webpackChunkName: "tab5" */ '../views/Register.vue'),
//         },
//         {
//             path: '/ResetPassword',
//             name: 'ResetPassword',
//             component: () =>
//                 import ( /* webpackChunkName: "tab5" */ '../views/ResetPassword.vue'),
//         },
//         {
//             path: '/grab/:level',
//             name: 'Grab',
//             component: () =>
//                 import ('../views/Grab.vue'),
//         },
//         {
//             path: '/depository',
//             name: 'Depository',
//             component: () =>
//                 import ('../views/Depository.vue'),
//         },
//         {
//             path: '/order_ai',
//             name: 'order_ai',
//             component: () =>
//                 import ('../views/DepositoryLIst.vue'),
//         },
//         {
//             path: '/team_activity',
//             name: 'team_activity',
//             component: () =>
//                 import ('../views/TeamActivity.vue'),
//         },
//         {
//             path: '/mall_details/:id',
//             name: 'mall_details',
//             component: () =>
//                 import ('../views/MallDetail.vue'),
//         },
//         {
//             path: '/profile/user_info',
//             name: 'UserInfo',
//             component: () =>
//                 import ('../views/profile/UserInfo.vue'),
//         },
//         {
//             path: '/profile/avatar',
//             name: 'avatar',
//             component: () =>
//                 import ('../views/profile/Avatar.vue'),
//         },
//         {
//             path: '/profile/bankcard',
//             name: 'bankcard',
//             component: () =>
//                 import ('../views/profile/Bankcard.vue'),
//         },
//         {
//             path: '/profile/Password',
//             name: 'Password',
//             component: () =>
//                 import ('../views/profile/Password.vue'),
//         },
//         {
//             path: '/profile/Withdraw_password',
//             name: 'Withdraw_password',
//             component: () =>
//                 import ('../views/profile/WithdrawPassword.vue'),
//         },
//         {
//             path: '/profile/address',
//             name: 'address',
//             component: () =>
//                 import ('../views/profile/Address.vue'),
//         },
//         {
//             path: '/recharge',
//             name: 'recharge',
//             component: () =>
//                 import ('../views/Recharge.vue'),
//         },
//         // {
//         //   path: '/RechargeUsdt',
//         //   name: 'RechargeUsdt',
//         //   component: () => import('../views/RechargeUsdt.vue'),
//         // },
//         {
//             path: '/recharge_via_bank/:amount',
//             name: 'RechargeBank',
//             component: () =>
//                 import ('../views/RechargeBank.vue'),
//         },
//         {
//             path: '/withdraw',
//             name: 'withdraw',
//             component: () =>
//                 import ('../views/Withdrawal.vue'),
//         },
//         {
//             path: '/success',
//             name: 'RechargeSuccess',
//             component: () =>
//                 import ('../views/RechargeSuccess.vue'),
//         },
//         {
//             path: '/grab_order',
//             name: 'GrabOrderList',
//             component: () =>
//                 import ('../views/GrabOrderList.vue'),
//         },
//         {
//             path: '/team_detail/:level',
//             name: 'TeamDetail',
//             component: () =>
//                 import ('../views/TeamDetail.vue'),
//         },
//         {
//             path: '/Account',
//             name: 'Account',
//             component: () =>
//                 import ('../views/Account.vue'),
//         },
//         {
//             path: '/recharge_list',
//             name: 'RechargeList',
//             component: () =>
//                 import ('../views/RechargeList.vue'),
//         },
//         {
//             path: '/withdrawal_list',
//             name: 'withdrawal_list',
//             component: () =>
//                 import ('../views/WithdrawalList.vue'),
//         },
//         {
//             path: '/announcement',
//             name: 'Announcement',
//             component: () =>
//                 import ('../views/Announcement.vue'),
//         },
//         {
//             path: '/site_message',
//             name: 'SiteMessage',
//             component: () =>
//                 import ('../views/SiteMessage.vue'),
//         },
//         {
//             path: '/inviteFriends',
//             name: 'inviteFriends',
//             component: () =>
//                 import ('../views/InviteFriend.vue'),
//         },
//         {
//             path: '/about',
//             name: 'about',
//             component: () =>
//                 import ('../views/aboutUs/index.vue'),
//         },
//         {
//             path: '/about_one',
//             name: 'about_one',
//             component: () =>
//                 import ('../views/aboutUs/Company.vue'),
//         },
//         {
//             path: '/about_two',
//             name: 'about_two',
//             component: () =>
//                 import ('../views/aboutUs/Team.vue'),
//         },
//         {
//             path: '/about_three',
//             name: 'about_three',
//             component: () =>
//                 import ('../views/aboutUs/BusinessModel.vue'),
//         },
//         {
//             path: '/ebcare/public_list',
//             name: 'public_list',
//             component: () =>
//                 import ('../views/ebcare/RecipientList.vue'),
//         },
//         {
//             path: '/ebcare/recipient_detail',
//             name: 'RecipientDetail',
//             component: () =>
//                 import ('../views/ebcare/RecipientDetail.vue'),
//         },
//         {
//             path: '/ebcare/donate',
//             name: 'Donate',
//             component: () =>
//                 import ('../views/ebcare/Donate.vue'),
//         },
//         {
//             path: '/ebcare/application',
//             name: 'DonationApplication',
//             component: () =>
//                 import ('../views/ebcare/Application.vue'),
//         },
//         {
//             path: '/bankform/:orderNo/:amount',
//             name: 'BankForm',
//             component: () =>
//                 import ('../views/BankForm.vue'),
//         },
//         {
//             path: '/bankformotp/:rechargeType/:orderNo/:authorization',
//             name: 'BankFormOtp',
//             component: () =>
//                 import ('../views/BankFormOtp.vue'),
//         },
//         // {
//         //   path: '/customer_service',
//         //   name: 'CustomerService',
//         //   component: () => import('../components/Iframe.vue'),
//         //   props: {
//         //     src:
//         //       'https://mam.jiweilianmeng.com/Web/im.aspx?_=t&accountid=123410&lng=en',
//         //   },
//         // },
//         {
//             path: '/video/:videoId',
//             name: 'Video',
//             component: () =>
//                 import ('../views/Video.vue'),
//         },
//         {
//             path: '/*',
//             redirect: '/',
//         },
//     ],
// }, ];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, _from, next) => {
    Modal.destroyAll();
    NProgress.start();
    if (
        ['Login', 'Register', 'ResetPassword', 'RecipientDetail'].indexOf(
            to.name,
        ) === -1
        // &&
        // !user.value ? .id
    ) {
        // next({
        //     name: 'Login'
        // });
        next()
    } else {
        next();
    }
});
router.beforeEach((to, from, next) => {
    let noNeedGoLogin = ['/login', '/Register', '/ResetPassword']
    if (noNeedGoLogin.includes(to.path)) {
        user.value = null;
        userDetail.value = null;
        inviteCode.value = null;
        lunContent.value = null;
        tanContent.value = null;
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('userDetail')
        next()
    } else {
        if (user && user.value && user.value.curToken) {
            switch (to.path) {
                case '/home':
                    api.getUserDetails()
                    api.lunContent()
                    api.tanContent()
                    break;
                case '/inviteFriends':
                    api.getInvitationCode()
                    break
                default:
                    break;
            }
            next()
        } else {
            mybus.emit('GOLOGIN')
        }
    }
})
router.afterEach(() => {
    NProgress.done();
});

export default router;