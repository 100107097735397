export default {
    label: {
        totalMembers: 'Tamanho total da equipe',
        totalCommission: 'Comissão Total da Equipe',
        totalRecharge: 'Recarga total',
        totalWithdraw: 'Retirada total',
        commission: 'Comissão',
        members: 'Membros',
        bonus: 'Bônus',
        expireIn: 'Expirar em',
        days: 'dias',
        cellNo: 'Número do celular',
        registrationDate: 'Data de registro',
        balance:'Saldo',
        samedaySubcCmmission:'Subcomissão no mesmo dia'
    },
};