import en from './en';
import pb from './pb'
import es from './es'
import ind from './ind'
import tur from './tur'
import {
    createI18n
} from 'vue-i18n';

export const i18nConfig = {
    legacy: false,
    locale: localStorage.getItem('locale')||'en',
    messages: {
        en,
        pb,
        es,
        ind,
        tur
    },
};

export const i18n = createI18n(i18nConfig);

export function addServerMessages(messages) {
    i18nConfig.messages.en.server = messages;
    i18n.global.setLocaleMessage('en', i18nConfig.messages.en);
}

export function useI18n(namespace) {
    function getKey(key) {
        if (!namespace) {
            return key;
        }

        if (key.startsWith(namespace)) {
            return key;
        }

        return `${namespace}.${key}`;
    }

    const {
        t,
        ...methods
    } = i18n.global;

    return {
        ...methods,

        t: (key, ...args) => {
            if (!key) return '';
            return t(getKey(key), ...args);
        },
    };
}