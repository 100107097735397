export default {
    label: {
        advisory: 'Advisory',
        complaint: 'Complaint',
        faq: 'Frequently Asked Questions',
    },
    message: {
        // system_success: '成功',
        // system_fail: '失败',
        // system_add_suc: '新增成功',
        // system_add_fail: '新增失败',
        // system_del_suc: '删除成功',
        // system_del_fail: '删除失败',
        // system_update_suc: '更新成功',
        // system_update_fail: '更新失败',
        // system_api_error: '接口异常',
        // system_opt_suc: '操作成功',
        // system_opt_fail: '操作失败',
        // system_opt_edit: '编辑',
        // system_opt_del: '删除',
        // system_data_not_empty: '数据不能为空',

        // login_username_not_empty: '登录账号不能为空',
        // login_password_not_empty: '登录密码不能为空',
        // login_username_not_exist: '登录账号不存在',
        // login_pass_err: '登录密码错误',
        // login_username_freeze: '用户已冻结,不能登录',
        // login_username_lt_4: '登录账号不应低于4位',
        // login_password_empty: '请输入登录密码',
        // login_password_lt_4: '登录密码不应低于4位',
        // login_success: '登录成功',
        // login_fail: '登录失败',
        // login_param_empty: '账号或密码为空',
        // login_param_unvalid: '账号或密码错误',

        // logout_btn: '注销',
        // logout_confirm: '确认注销登录?',
        // logout_success: '注销成功',
        // logout_fail: '注销失败',


        // user_add: '新增用户',
        // user_update: '更新用户',
        // user_username_repeat: '账号重复',
        // user_username_valid: '限制以小写字母开头，由小写字母、数字组成',
        // user_password_update_placeholder: '请输入新密码，为空则不更新密码',
        // user_update_loginuser_limit: '禁止操作当前登录账号',
        // user_not_exist_update_fail: '修改失败,账号不存在',
        // user_old_password_err: '旧密码错误请重新输入',
        // user_nick_not_empty: '昵称不能为空',
        // user_code_not_empty: '验证码不能为空',
        // user_sms_code_expired: '验证码已过期',
        // user_sms_code_err: '验证码错误',
        // user_invitation_code_not_exist: '邀请码不存在',
        // user_invitation_code_err: '邀请码错误',
        // user_level_not_configured: '无等级信息,请配置等级',
        // user_reg_fail: '注册失败',
        // user_send_sms_fail: '发送失败,请配置短信内容',
        // user_account_not_exist: '账户不存在',
        // user_avatar_not_empty: '头像地址不能为空',
        // user_transaction_password_not_empty: '交易密码不能为空',
        // user_task_log_create_fail: '生成任务记录失败',
        // user_task_not_exist: '领取失败,任务不存在',
        // user_task_not_completed: '领取失败,任务未完成',
        // user_task_received: '领取失败,奖励已领取',
        // user_task_expired: '领取失败,任务已过期',
        // user_task_state_error: '领取失败,任务状态错误',
        // user_failed_to_modify_balance: '领取失败,修改余额失败',
        // user_task_receive_fail: '领取失败',
        // user_insufficient_balance: '余额不足',

        // level_config_1: 'VIP数据错误',
        // level_config_3: '您已经是此等级,无需升级',
        // level_config_4: '升级失败,扣除余额失败',
        // level_config_5: '升级失败,修改用户信息失败',

        // member_bank_1: '绑定银行卡失败',

        // order_save_1: '请联系管理配置产品小类!',
        // order_save_2: '数据有误，请联系管理员!',
        // order_save_3: '更新佣金失败!',
        // order_save_5: '扣款失败!',
        // order_save_6: '下单失败!!!',
        // order_save_7: '订单数量已达到当前最大订单等级，请提高等级。!!!',
        // order_detail_1: '数据有误，请联系管理员!',
        // order_detail_2: '用户VIP等级不够!',
        // order_detail_3: '每天只能抢购20单',
        // order_detail_4: '该专区需要最大金额',
        // order_detail_5: '请联系管理配置产品小类!',
        // order_detail_6: '订单状态有误!',

        // user_buy_record_save_1: '请勿重复购买!',
        // user_buy_record_save_2: '数据有误,请联系客服!',
        // user_buy_record_save_3: '参数有误,请联系管理员!',
        // user_buy_record_save_4: '小于最少限制,请重新购买!',
        // user_buy_record_save_5: '超出最大限制,请重新购买!',
        // user_buy_record_save_6: '用户VIP等级不够!',

        // web_pay_add_1: '该通道未配置',
        // web_pay_add_2: '参数有误',
        // web_pay_add_3: '支付订单创建成功',
        // web_pay_add_4: '支付订单创建失败',

        // web_pay_create_pay_1: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_pay_2: '平台卡有误',
        // web_pay_create_pay_3: '通道已关闭，请选择其他通道',
        // web_pay_create_pay_4: '提交金额不在允许范围内',

        // web_pay_create_proxy_1: '请求数据有误，提现卡号不是本人或不存在!',
        // web_pay_create_proxy_2: '您好，帐号银行卡被锁定，请联系客服!',
        // web_pay_create_proxy_3: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_proxy_4: '您好，每日最多提款次数为:3次',
        // web_pay_create_proxy_5: '您好，最小提款金额为',
        // web_pay_create_proxy_6: '您好，最大提款金额为',
        // web_pay_create_proxy_9: '提现成功!',
        // web_pay_create_proxy_10: '提现失败',

        // login_expired:'登录已过期，请重新登录',
        // login_network_error:'网络异常，请检查网络链接',

        system_success: 'Success',
        system_fail: 'Failure',
        system_add_suc: 'Add Success',
        system_add_fail: 'Add Failure',
        system_del_suc: 'Deleted successfully',
        system_del_fail: 'Failed to delete',
        system_update_suc: 'Update successful',
        system_update_fail: 'Update failed',
        system_api_error: 'Interface exceptions',
        system_opt_suc: 'Successful operation',
        system_opt_fail: 'Operation failed',
        system_opt_edit: 'Editor',
        system_opt_del: 'Delete',
        system_data_not_empty: 'Data cannot be empty',

        login_username_not_empty: `Login account can't be empty`,
        login_password_not_empty: 'Login password cannot be empty',
        login_username_not_exist: 'Login account not available',
        login_pass_err: 'Wrong login password',
        login_username_freeze: 'User has been frozen and cannot log in',
        login_username_lt_4: 'Login account number should be no less than 4 digits',
        login_password_empty: 'Please enter login password',
        login_password_lt_4: 'Login password should be no less than 4 digits',
        login_success: 'Log in successful',
        login_fail: 'Login failure',
        login_param_empty: 'Account or password is empty',
        login_param_unvalid: 'Wrong account or password',

        logout_btn: 'Cancellation',
        logout_confirm: 'Confirm logout?',
        logout_success: 'Successful write-off',
        logout_fail: 'Cancellation failure',

        user_add: 'New Users',
        user_update: 'Update users',
        user_username_repeat: 'Duplicate accounts',
        user_username_valid: 'RestrictionsBeginning with a lowercase letter, consisting of lowercase letters, numbers',
        user_password_update_placeholder: 'Please enter a new password, if it is empty, the password will not be updated',
        user_update_loginuser_limit: 'Prohibit the operation of the currently logged-in account',
        user_not_exist_update_fail: 'Modification failed, account does not exist',
        user_old_password_err: 'Old password is incorrect, please re-enter',
        user_nick_not_empty: 'Nickname cannot be empty',
        user_code_not_empty: 'Captcha cannot be empty',
        user_sms_code_expired: 'Captcha has expired',
        user_sms_code_err: 'Captcha error',
        user_invitation_code_not_exist: 'Invitation code does not exist',
        user_invitation_code_err: 'Invitation code error',
        user_level_not_configured: 'No level information, please configure level',
        user_reg_fail: 'Registration failed',
        user_send_sms_fail: 'Sending failed, please configure the content of the message',
        user_account_not_exist: 'Account does not exist',
        user_avatar_not_empty: 'The avatar address cannot be empty',
        user_transaction_password_not_empty: 'The transaction password cannot be empty',
        user_task_log_create_fail: 'Failed to generate a task record',
        user_task_not_exist: 'Failed to collect, mission does not exist',
        user_task_not_completed: 'Failed to collect, mission not completed',
        user_task_received: 'Claim failed, reward claimed',
        user_task_expired: 'Failed to collect, mission has expired',
        user_task_state_error: 'Failed to collect, mission status error',
        user_failed_to_modify_balance: 'Failed to collect, failed to amend balance',
        user_task_receive_fail: 'Failed to collect',
        user_insufficient_balance: 'Insufficient balance',

        level_config_1: 'VIP data error',
        level_config_3: 'You are already at this level, no need to upgrade',
        level_config_4: 'Failed to upgrade, failed to deduct balance',
        level_config_5: 'Upgrade failed, change user information failed',

        member_bank_1: 'Failed to bind a bank card',
        member_bank_2: 'Withdrawal method already exists',

        order_save_1: 'Please contact the Management Configuration product sub-category',
        order_save_2: 'Please contact the administrator if the data is incorrect',
        order_save_3: 'Commission update failed',
        order_save_5: 'Debit failed',
        order_save_6: 'Order placed failed',
        order_save_7: 'The number of orders has reached the current maximum order level,Please raise the level.',
        order_save_8: 'There are pending orders.',
        order_save_9: 'The order has expired, please contact customer service to renew.',
        order_save_10: 'The order has been frozen, please contact customer service to unfreeze.',
        order_save_11: 'If the balance is insufficient, the difference will need to be made up.',

        order_detail_1: 'Please contact the administrator if the data is incorrect',
        order_detail_2: 'Not enough user VIP levels',
        order_detail_3: 'Contact your Customer Service Specialist',
        order_detail_4: 'Maximum amount required for this area',
        order_detail_5: 'Please contact the Management Configuration product sub-category',
        order_detail_6: 'The order status is incorrect!',

        user_buy_record_save_1: 'Please do not double buy',
        user_buy_record_save_2: 'Please contact customer service if the data is incorrect.',
        user_buy_record_save_3: 'Please contact the administrator if there is an error in the parameters',
        user_buy_record_save_4: 'Less than the minimum limit, please re-purchase',
        user_buy_record_save_5: 'Maximum limit exceeded, please re-purchase',
        user_buy_record_save_6: 'Not enough VIP level for users',
        user_buy_record_save_7: 'You have an open order, please fill in first',
        user_buy_record_save_8: 'The order is not completed and cannot be withdrawn',
        

        web_pay_add_1: 'This channel is not configured',
        web_pay_add_2: 'Wrong parameters',
        web_pay_add_3: 'Payment order created successfully',
        web_pay_add_4: 'Payment order creation failure',

        web_pay_create_pay_1: 'Hello, as your last order has not been processed yet, please wait a minute and submit it again',
        web_pay_create_pay_2: 'Wrong platform card',
        web_pay_create_pay_3: 'Access is closed, please select another access',
        web_pay_create_pay_4: 'Submitted amount not within the allowed range',

        web_pay_create_proxy_1: 'The request data is incorrect, the withdrawal card number is not the person or does not exist',
        web_pay_create_proxy_2: 'Hello, the account card is locked, please contact customer service',
        web_pay_create_proxy_3: 'Hello, as your last order has not been processed yet, please wait a minute and submit it again',
        web_pay_create_proxy_4: 'Hello, the maximum number of withdrawals per day is: {days}',
        web_pay_create_proxy_5: 'Hello, the minimum withdrawal amount is {min}',
        web_pay_create_proxy_6: 'Hello, the maximum withdrawal amount is {max}',
        web_pay_create_proxy_9: 'Successful withdrawals',
        web_pay_create_proxy_10: 'withdrawals failure',
        web_pay_create_proxy_11:'Incorrect security password; please double check your security password or contact online customer service to change it',
        web_pay_create_proxy_12:'If the security password is entered incorrectly for too many times, the system will freeze the account. Please contact customer service to unfreeze the account',
        web_pay_create_proxy_13:"Exchange rate has been updated, please refresh",
        
        login_expired:'Login has expired. Please log in again.',
        login_network_error:'The network is abnormal. Check the network connection.',
        user_sms_fail:'The SMS verification code is valid within 2 minutes',

        user_experience_cannot: 'The balance contains {experienceGold} trial money and cannot be used for withdrawal',
        user_experience_cannot_up_vip: 'The balance contains {experienceGold} trial money and cannot be used for VIP upgrade',
        user_experience_cannot_ai: 'The balance contains {experienceGold} trial funds and cannot be used to purchase trusts'
    }
};