export default{
    MySubordinates :'Meus subordinados',
    listTheSubordinates:'listar os subordinados',
    goReports:'go relatórios',
    virtualCurrency :'moeda virtual',
    rechageText1:'Transferir quantidade especificada de dinheiro para a seguinte conta e carregar capturas de tela.',
    rechageText2:'(*Entre em contato com o atendimento ao cliente antes de recarregar e você deve assumir o risco deste canal.)',
    coinType:'tipo de moeda',
    coinAmount:'quantidade de moeda',
    channel:'canal',
    rate:'taxa',
    remark:'comentário',
    maxAmount:'quantidade máxima',
    minAmount:'quantidade mínima',
    bankPay:'pagamento de cartão bancário',
    bankName:'nome do banco',
    BeneficiaryBank:'Banco beneficiário',
    holderName:`Nome verdadeiro do titular do cartão`,
    rememberPwdText:`Por favor lembre-se da senha, se você esquecer a senha, por favor contactar o serviço de clientes.`,
    versionInfo:'Informação da versão',
    instructions:'instruções',
    minLength:'O comprimento mínimo é de {min}.',
    maxLength:'O comprimento máximo é de {max}.',
    allSub:'Todas as subordinadas',
    directSub:'Subordinados diretos',
    PIX:'Por favor, introduz a conta PIX',
    realName:'nome real'
}