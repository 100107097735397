import {
    ref
} from 'vue';
let json = sessionStorage.getItem('userDetail') || null;
let saved_userDetail = null
if(json){
    saved_userDetail = JSON.parse(json);
}
export const userDetail = ref(saved_userDetail);

// {
//     activeFlag: "Y",
//     amount: 0,
//     avatarAddress: "16",
//     createBy: 0,
//     createDate: "2021-12-27 14:40:26",
//     curToken: "e0738455-bf84-415f-885f-fcdc78257985",
//     delFlag: "N",
//     experienceGold: 0,
//     groupId: 0,
//     id: 15,
//     isOnline: null,
//     lastLoginAddress: null,
//     lastLoginDate: "2021-12-28 06:25:51",
//     lastLoginIp: "127.0.0.1",
//     lastUpdatedBy: 0,
//     lastUpdatedDate: "2021-12-28 06:25:51",
//     levelName: "VIP1",
//     levelTitle: "VIP1",
//     paymentPassword: 0,
//     toDayIncome: 0,
//     totalIncome: 0,
//     userAccount: "6666666666",
//     userNick: "ygxxxx",
//     userType: 1,
//     yesterdayIncome: 0
// }