export default {
    inviteFriends: 'Invitar a los amigos',
    message: 'mensaje',
    availableAssets: 'Activos disponibles',
    yesterdayEarnings: `Ayer`,
    cumulativeIncome: `Acumulado`,
    todayEarnings: `Hoy`,
    recharge: 'Depósito',
    withdraw: 'Retirada',
    aboutUS: 'Acerca de EE.UU.',
    whyUS: 'Por qué Estados Unidos',
    howToEarn: 'Cómo ganar',
    howToShare: 'Cómo compartir',
    missionList: 'Lista de misiones',
    commission: 'Comisión',
    BeginnersGuide: 'Guía para principiantes',
    experienceGold: 'Experiencia de oro',
    ChooseYourAvatar:'Elige tu avatar',
    integral: 'integral'
};