export default {
    inviteFriends: 'Invite friends',
    message: 'message',
    availableAssets: 'Available assets',
    yesterdayEarnings: `Yesterday`,
    cumulativeIncome: `Cumulative`,
    todayEarnings: `Today`,
    recharge: 'Recharge',
    withdraw: 'Withdraw',
    aboutUS: 'About US',
    whyUS: 'Why US',
    howToEarn: 'How To Earn',
    howToShare: 'How To Share',
    missionList: 'Mission List',
    commission: 'Commission',
    BeginnersGuide: 'Beginners Guide',
    experienceGold: 'Experience gold',
    ChooseYourAvatar:'Choose your avatar',
    integral: 'integral'
};