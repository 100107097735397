export default {
    menu: {
        index: 'MOA Önemsiyor',
        home: 'Ev',
        team: 'Takım',
        service: 'Hizmet',
        my: 'Benim',
        grabOrder:'sipariş al',
        grabOrderList2:'görev'
    },
    title: {
        download: 'indirmek',
        termOfService: 'Hizmet Süresi',
        maintenance: 'Sistem bakımda',
        registration: 'Kayıt',
        verificationCode: 'Doğrulama kodu',
        inviteFriends: 'Arkadaşları davet etmek',
        forgotPassword: 'Parolanızı mı unuttunuz',
        team: 'Takım Raporu',
        teamDesc: 'Alt ekibinize genel bakış',
        inviteTasks: 'Davet Görevleri',
        service: 'Müşteri servisi',
        siteMessage: 'İleti',
        recharge: 'şarj',
        transPassword: 'İşlem şifre yönetimi',
        password: 'Şifre yönetimi',
        information: 'Bilgi',
        withdrawal:'Çekilme yöntemi',
        address: 'Teslimat adresi',
        avatar: 'Avatar ayarları',
        bankcard: 'Bağlayıcı hesap',
        streamRecords: 'Hesap detayları',
        announcement: 'Duyuru',
        about: 'Hakkımızda',
        aboutCompany: 'Şirket Profili',
        aboutBusiness: 'Hizmet sözleşmesi',
        aboutTeam: 'Ürün Açıklaması',
        careApplication: 'Yardım Başvurusu',
        careDonation: 'Bağışlar',
        careRecipientDetail: 'Detaylar',
        careRecipientList: 'Yardım etmek istiyorum',
        depository: 'AI Güven Yönetimi',
        depositoryRecords: 'AI Güven Kayıtları',
        grab: 'Sipariş alma',
        grabRecords: 'Sipariş Kaydı',
        rechargeRecords: 'Şarj kayıtları',
        rechargeResult: 'Şarj Sonucu',
        withdraw: 'Çekil',
        withdraw1: 'Çekil',
        withdraw2: 'Çekil',
        withdrawCommission: 'Çekilme komisyonu',
        withdrawRecords: 'Çekilme kayıtları',
        prompt: 'Komut istemi',
        guessYouLike: 'Beğendin herhalde',
        comment: 'Yorum',
        whatsApp: 'Astar',
        rechargeOrderSubmitted: 'Şarj siparişi oluşturuldu',
        rechargeWithBankcard: 'Banka yoluyla ödeme',
        agencyCenter:'Ajans merkezi',
        agencyRecords:'Ajans Kayıtları',
        subordinatesManagement:'Astların yönetimi',
        subordinatesProfit:'Astların kârı',


        cardNumber:'Kart numarası',
        actualName:'Gerçek Adı',

        withdrawalAddress:'Para Çekme Adresi',
        
        Account:'Hesap Pix',
        Name:'İsim',
        Monto:'Miktar',
        Calbe:'Calbe',
        Nombre:'İsim',

        Task:'Görev listesi',
        trade:'Ticaret...',
        freeze:'Donmak',
        completed:'Tamamlanmış',

        Paid:'Paralı',
        Excuting:'Sipariş vermek',
        grabOrderDetail:'istek listesi',
        payNow:'Anında ödeme',
        pendingTransaction:'bekleyen işlem',
        paySuccess:'Ödeme başarılı',
        orderMatching:'sipariş eşleştirme...',
        OrderNow: 'Şimdi sipariş ver',
        orderCount: 'Sipariş Sayısı',
        orderCountShow:'Tamamlanan sipariş/Toplam sipariş',
        orderReturnBenJin:'anaparayı iade et',
        orderReturnYongJin:'İade komisyonu',
        orserSuccess:'başarıyla sipariş verildi',
        starPeople:'Onur listesi',
        priceOne:'mal bedeli:',
        commissionOne:'emtia komisyonu:',
        officialGuarantees: 'resmi garantiler',
        todayIncome: 'bugünün geliri',
        orderStatus: 'Sipariş durumu'
    },
    placeholder:{
        searchPhone:'Cep telefonu numarası sorgulama.',
        searchPhone1:'hesap adına göre sorgulama yapabilirsiniz.',
        coinAmount:'Lütfen sanal para sayısını girin',
        remark:'Lütfen açıklamayı girin'
    },
    button: {
        login: 'Giriş yapmak',
        logout: 'Çıkış Yap',
        signup: 'Üye olmak',
        register: 'Kayıt olmak',
        forgotPassword: 'Parolanızı mı unuttunuz',
        getVerificationCode: 'Doğrulama kodunu al',
        coolDown: 'soğutma {saniye} saniye',
        copy: 'Kopyala',
        confirm: 'Onaylamak',
        saveInformation: 'Bilgileri kaydet',
        confirmModify: 'Değişikliği onaylayın',
        transfer: 'Aktar',
        withdraw: 'Hemen çekilin',
        withdrawAll: 'Tümünü geri çek',
        addWithdrawal: 'Çekilme ekle',
        donate: 'Şimdi Bağış yap',
        donate2: 'Bağış yapmak',
        submit: 'Göndermek',
        card: 'Kart',
        account: 'Hesap',
        buyNow: 'Şimdi satın al',
        addToCart: 'Sepete ekle',
        backToHomepage: 'Ana sayfaya geri dön',
        setNow: 'Şimdi kur',
        goBack: 'Geri gitmek',
        upload: 'Yüklemek',
        search: 'Ara',
        cancel:'İptal et',
        ok:'TAMAM'
    },
    message: {
        unopen: 'henüz açılmadı! {mesaj}',
        loginInvalid: 'geçersiz hesap ya da şifre',
        mobileInvalid: 'lütfen geçerli bir cep telefonu numarası girin',
        accountNotExists: 'hesap mevcut değil',
        bindingAddressFailed: 'Bağlayıcı Teslimat adresi başarısız',
        changeAvatarError: 'Avatar ayarları başarısız',
        selectAvatar: 'Lütfen bir resim seçin',
        bindAccountSuccess: 'Bağlayıcı hesap başarılı',
        bindAccountFailed: 'Hesap bağlama başarısız',
        transferSuccess: 'aktarım başarılı',
        vipUpgradeSuccessful: 'Vip yükseltme başarılı',
        vipUpgradeFailed: 'Vip yükseltme başarısız',
        noNeedUpgrade: 'Tekrar yükseltmeye gerek yok',
        automaticOrderSuccess: 'Otomatik sipariş başarılı',
        automaticOrderFailed: 'Otomatik sipariş başarısız oldu',
        notSupportBank: 'Bu banka sistem tarafından desteklenmiyor, lütfen başka bir bankaya geçin',
        withdrawSuccess: 'Çekilme başarılı',
        withdrawFailed: 'Çekilemedi',
        withdrawLimitTimes: 'Varış sürelerinin sınırı',
        donateSuccess: 'başarılı bağış',
        donateFailed: 'bağış başarısız',
        alreadyApplied: 'Zaten başvurdunuz',
        noRecords: 'Kayıt yok',
        bindingAddressSuccessful: 'Bağlayıcı Teslimat adresi başarılı',
        avatarSettingsSuccessful: 'Avatar ayarları başarılı',
        passwordModifiedSuccessful: 'Şifre başarıyla değiştirildi',
        passwordModifiedFailed: 'Şifre değiştirilemedi',
        transactionModifiedSuccessful: 'İşlem şifresi başarılı',
        transactionModifiedFailed: 'İşlem şifresi başarısız',
        bankCardRequire: 'Lütfen önce banka kartını doldurun',
        balanceRequire: 'Yetersiz bakiye',
        withdrawPasswordRequire: 'Lütfen önce para çekme şifresini girin',
        orderError1:'Lütfen biraz bekleyin ve tekrar tıklayın',
        OrderSuccessful: 'sipariş başarılı',
        SpreadRequired: 'yayılma gerekli',
        orderTitle:"Alıcının onayı bekleniyor。 Sipariş yenileme için lütfen Müşteri Hizmetleri ile iletişime geçin.",
        orderTitle1:"Alıcıdan onay bekleniyor。 Siparişiniz verilecek ",
        orderTitle1_1:" Dondurulmuş bir duruma girin。",
    },
    label: {
        code: 'kod',
    },
    streamType: {
        0: "Herşey",
        1: 'Kullanıcı bakiyesinde artış', //用户加款
        2: 'Kullanıcı bakiyesinde azalma', //用户减款
        3: 'VIP Yükseltme', //贵宾升级
        4: 'Kullanıcı Siparişleri', //用户下单
        5: 'Verilen siparişlerde komisyon', //下单返佣
        6: 'AI Güven Siparişi Yerleştirme', //AI下单
        7: 'AI Güven İndirimleri', //AI返佣
        8: 'Görev Ödülleri', //任务奖励
        9: "Yapay para ekle", //人工加款
        10: "Yapay indirimler", //人工减款
        11: "Hediyeyi şarj et", //注册赠送
        12:"Alt indirim", //下级返佣
        13:"Altın kurtarma deneyimini yaşayın", //体验金回收
        14:"Hediyeyi şarj et", //充值赠送
        15:"siparişi iptal et", //取消订单
        16:"Arka planda manuel şarj", //后台人工充值
        17:"Bonus para yatırma" //彩金存款
    },
    date: {
        SELECT_START_DATE: 'başlangıç ​​tarihini seçin',
        SELECT_END_DATE: 'bitiş tarihini seçin',
    },
    processStatus: {
        INIT: 'İşleme',
        SUCCESS: 'Başarı',
        FAIL: 'Arıza',
        1: 'Bekleyen yorum', //待审核
        2: 'Denetim onaylandı', //审核通过
        3: 'Ödenecek', //待支付
        4: 'Başvurunun reddi', //驳回申请
        5: 'Ödemeler işleniyor', //代付处理中
        6: 'Yeniden denetim', //重新审核
        7: 'Ödeme başarısız', //代付失败通知
    },
    wsMessage: {
        '202Title': 'Başarılı şarj denetimi', //充值审核成功
        '202Content': 'Kontör yüklemelerinizden biri zaten kredilendirildi, lütfen yükleme geçmişinizi kontrol edin.', //您有一笔充值已经到账，请在充值记录里核实。
        '203Title': 'Başarısız şarj isteği', //充值审核驳回
        '203Content': 'Reddedilen bir yüklemeniz var, lütfen yeniden yükleme geçmişinizi kontrol edin.', //您有一笔充值未成功，请在充值记录里核实。
        '205Title': 'Para çekme işlemleri yapılıyor.', //提现处理中
        '205Content': 'İşlenmeye başlayan bir para çekme işleminiz var.', //您有一笔提现已经开始处理。
        '206Title': 'Para çekme işlemleri işlendi', //提现已处理
        '206Content': 'İşlenmiş bir para çekme işleminiz var, lütfen para çekme günlüğünü kontrol edin.', //您有一笔提现已经处理完成，请在提现记录里核实。
    },
    withdrawStatus: {
        INIT: 'İşleme',
        SUCCESS: 'Başarı',
        FAIL: 'Arıza',
    },
    grabStatus: {
        ALL: 'TÜMÜ',
        SUCCESS: 'Başarı',
        FAIL: 'Arıza',
    },
    rechargeType: {
        RECHARGE: 'Şarj',
        VIP_UPGRADE: 'Güncelleme',
    },
    rechargeWay:{
        PayOnline:'Çevrimiçi Öde',
        PayViaBank:'Banka Yoluyla Öde',
        Gift:'Hediye',
        GiftTitle:'Hediye tamamlama etkinlikleri',
        GiftDetail:'her şarj, yeniden şarj hediye oranı:',
        GiftDetail2:'Günlük ilk şarj, yeniden şarj hediye oranı:'
    },
    taskStatus: {
        1: 'Yapmak',
        2: 'Tamamlanmış',
        3: 'Alındı',
        4: 'Süresi doldu',

    },
    clipboard: {
        success: 'kopyalandı',
    },
    messageStatus: {
        unread: 'Okunmamış',
        readed: 'Okudum',
    },
    recharge_online:{
        channelName:'Kanal ismi: ',
        max:'maksimum şarj miktarı:',
        min:'minimum şarj miktarı:'
    },
    faqs:{
        bindCard:'Banka hesabı nasıl bağlanır',
        grab:'Sipariş nasıl alınır',
        pwd:'Fon şifresi ve giriş şifresi nasıl değiştirilir',
        recharge:'Nasıl yenilenir',
        withdraw:'Nasıl çekilir',

    },
    text: {
        withdrawCommission:`Komisyon bakiyeye devri`,
        grabExplanation: `1.Sipariş almaktan elde edeceğiniz kâr bakiyenize bağlıdır, bakiye ne kadar yüksekse, kâr da o kadar yüksek olur.
    2.Her kullanıcı günde 20 sipariş alabilir. Kâr sabit değil, günün sırasına bağlıdır.
    3. Daha yüksek seviyelerin daha yüksek getirileri olacaktır, eğer seviyeyi yükseltmek istiyorsanız, lütfen 'Vip seviye yükseltme'yi tıklayın ve yükseltmek için ödemeyi tamamlayın.
`,
        depository: `Uyarı: Fonları emanet fon havuzuna transfer ettiğinizde. Bu, fon emanet döngüsünün sona ermesinden önce fonları çekmemeyi kabul ettiğiniz anlamına gelir. Lütfen dikkatli olun. Emanet süresi dolduktan sonra, anaparanız ve geliriniz otomatik olarak olacaktır. işlem ücreti düşüldükten sonra alacaklandırılır.`,

        bankcard: `Kart numarası: TR ile başlayan 24 haneli iban hesap numarasını giriniz`,
        bankcard2:``,
        bankcard3: ``,
        bankcard4: `Gerçek Adı: Gerçek adını giriniz`,
        bankcard5: ``,
        withdraw: `AVM'den alış saati Pazartesi'den Pazar'a 09:00 - 23:00 arası olup TR + 24 haneli banka kartı olarak tanımlanmıştır. Gerçek adınızı (adınız + soyadınız) doldurmalısınız, lütfen dikkatlice kontrol edin! Yanlışsa, para çekme gelmeyecek`,
        C_service:'Müşteri hizmetleri sistemine girmek için yukarıdaki resme tıklayın. Para çekme zamanı:Pazartesi-Cuma 7am-7pm.Eğer çekilişiniz başarısız olursa,lütfen başka bir bankaya geçin ve tekrar deneyin.',
        ai_trust_management_help: `AI güven yönetimi, atıl fonlarınızı sistemin saklama fon havuzuna aktarmaktır.
    Fonlar transfer edildiğinde, sistem fon havuzundaki fonları kullanarak siparişi otomatik olarak tamamlamak için AI akıllı algoritmalarını kullanacaktır.
    Fon havuzuna aktardığınız fonlar her 24 saatte bir gelir getirecektir. Gelir miktarı, transfer tutarınız ve hosting tipiniz ile VIP üyelik seviyenize göre belirlenmektedir.
    Emanet süresi sona erdikten sonra, anaparanız ve geliriniz, işlem ücreti düşüldükten sonra otomatik olarak alacaklandırılacaktır.`

    }
};