export default {
    label: {
        totalMembers: 'Tamaño total del equipo',
        totalCommission: 'Comisión total del equipo',
        totalRecharge: 'Recarga total',
        totalWithdraw: 'Retirada total',
        commission: 'Comisión',
        members: 'Miembros',
        bonus: 'Bono',
        expireIn: 'Expira en',
        days: 'días',
        cellNo: 'Número de teléfono móvil',
        registrationDate: 'Fecha de inscripción',
        balance:'balanza',
        samedaySubcCmmission:'Subcomisión del mismo día'
    },
};