export default {
    label: {
        applyForHelp: 'Yardım Başvurusu',
        iWantHelp: 'Yardım etmek istiyorum',
        whatIsThis: "Bu nedir",
        latestNews: 'Son Haberler',
        more: 'Daha',
        donated: 'bağış',
        to: 'İle',
        time: 'Zaman',
        process: 'İşlem',
        process1: 'başvuruyu Gönder',
        process2: 'Platform denetimi',
        process3: 'Bağış yapmaya başlayın',
        nickname: 'Takma ad',
        mobile: 'Telefon numarası',
        patientName: 'Hasta adı',
        description: 'Tanım',
        donationAmount: 'Bağış miktarı',
        says: 'Diyor',
        thankYou: 'Bana sevgini gösterdiğin için teşekkür ederim',
        amount: 'Miktar',
        comment: 'Yorum',
        target: 'Hedef',
        raised: 'Kabarık',
        aims: 'Amaçları',
        helpTimes: 'Yardım süreleri',
        recipients: 'Alıcılar',
        nameCertified: 'Gerçek isim sertifikalı',
        accountsReceivable: 'Alacak hesapları:',
        idCardapproved: 'Kimlik kartı onaylandı',
        proofApproved: 'İlişki kanıtı onaylandı',
        verificationPassed: 'Banka kartı doğrulaması alındı',
        promise: 'Promosyoncular söz veriyor',
        platformStatement: 'Platform ifadesi',
        expandAll: 'Hepsini genişlet',
        closeAll: 'Hepsini Daralt',
        askFriend: 'Arkadaşından Yardım İste',
        applyForDonation: 'Bağış için başvurun',
        backgroundStory: 'Arka plan hikayesi',
        evidence: 'Kanıt',
        witnessList: 'Tanık listesi',
        progress: 'İlerlemek',
        postedGrogress: 'gönderilen ilerleme',
        Malltext: 'Yardıma ihtiyacınız varsa, lütfen platformumuza giriş yapın',
        iWantHelptext: 'Sevginizi verin ve dünyayı daha iyi hale getirin!',
    },
    hits: {
        application: `Bağış başvurusu yapıldıktan sonra platform personeli
        sizinle telefonla iletişim kurun.`,
        requireAmount:'Lütfen bağış hedef tutarını doldurun',
        comments1: `Küçük iyilikler bir sevgi okyanusunda birleşir ve umarım iyileşirsin.`,
        comments2: `İyileşebilirsin.`,
        recipientDetail1: 'Üst düzey sermaye koruması, küresel kullanıcıların güvendiği bir platform',
        joinPlatform: 'Yardıma ihtiyacınız varsa lütfen platformumuza katılın',
        dedication: 'Aşkın adanmışlığı',
        referral: 'Sevk için başvurmak bağışları hızlandırabilir',
        requestHelp: 'Yardım talebinde bulunun',
    },
    placeholder: {
        requireWhatAppID: 'Lütfen satır kimliğini girin',
        whatAppIDLength: 'whats app kimliği uzunluğu 1 ila 30 arasında olmalıdır',
        requirePatientName: 'Lütfen hasta adını girin',
        patientNameLength: 'Hasta adı uzunluğu 1 ile 50 arasında olmalıdır',
        requireDesc: 'Lütfen açıklama girin',
        descLength: 'Açıklama uzunluğu 10 ile 1000 arasında olmalıdır',
        requireAmount: 'Lütfen gerekli miktarı girin',
    },
    message: {
        notAllowed: 'Kendinize bağış yapmak yasaktır',

    },
};