export default {
    menu: {
        index: 'MOA Cares',
        home: 'Hogar',
        team: 'Equipo',
        service: 'Servicio',
        my: 'Mío',
        grabOrder:'agarrar',
        grabOrderList2:'Pedido'
    },
    title: {
        download: 'descargar',
        termOfService: 'Duración del servicio',
        maintenance: 'Sistema en mantenimiento',
        registration: 'Registro',
        verificationCode: 'Código de verificación',
        inviteFriends: 'Invitar a los amigos',
        forgotPassword: 'Olvidé mi contraseña',
        team: 'Informe del equipo',
        teamDesc: 'Visión general de su equipo subordinado',
        inviteTasks: 'Invitar a tareas',
        service: 'Servicio de atención al cliente',
        siteMessage: 'Mensaje',
        recharge: 'Recarga',
        transPassword: 'Gestión de contraseñas de transacciones',
        password: 'Gestión de contraseñas',
        information: 'Información',
        withdrawal:'Método de retirada',
        address: 'Dirección de entrega',
        avatar: 'Configuración del avatar',
        bankcard: 'Cuenta vinculante',
        streamRecords: 'Detalles de la cuenta',
        announcement: 'Anuncio',
        about: 'Sobre nosotros',
        aboutCompany: 'Perfil de la empresa',
        aboutBusiness: 'Acuerdo de servicio',
        aboutTeam: 'Descripción del producto',
        careApplication: 'Solicitar ayuda',
        careDonation: 'Donaciones',
        careRecipientDetail: 'Detalles',
        careRecipientList: 'Quiero ayudar',
        depository: 'Gestión de la confianza de la IA',
        depositoryRecords: 'Registros de AI Trust',
        grab: 'Agarre de pedidos',
        grabRecords: ' OrderRecord',
        rechargeRecords: 'Registros de recarga',
        rechargeResult: 'Resultado de la recarga',
        withdraw: 'Retirar',
        withdraw1: 'Retirada',
        withdraw2: 'Retiro de la comisión',
        withdrawCommission: 'Comisión de retirada',
        withdrawRecords: 'Registros de retirada',
        prompt: 'Prompt',
        guessYouLike: 'Supongo que te gusta',
        comment: 'Comentario',
        whatsApp: 'Línea',
        rechargeOrderSubmitted: 'Orden de recarga creada',
        rechargeWithBankcard: 'Pagar a través del banco',
        agencyCenter:'Centro de la Agencia',
        agencyRecords:'Registros de la Agencia',
        subordinatesManagement:'Gestión de subalternos',
        subordinatesProfit:'Beneficios subordinados',


        cardNumber:'Número de tarjeta',
        cardNumber2:'Número de tarjeta bancaria',
        cardNumber_1:'número corto del banco',
        cardNumber2_1:'número corto del banco',
        actualName:'Nombre real',

        withdrawalAddress:'Dirección de retirada',
        
        Account:'clabe STP',
        Name:'Nombre del banco',
        Monto:'Cantidad',
        Calbe:'Número de tarjeta bancaria',
        Nombre:'Nombre del banco',
        

        Task:'Lista de tareas',
        trade:'orden en curso',
        freeze:'Congelar',
        completed:'Completado',

        Paid:'Pagado',
        Excuting:'Submission',//Pagar ahora
        ExcutingEnd:'Ver pedidos',
        grabOrderDetail:'lista de deseos',
        payNow:'Pago inmediato',
        pendingTransaction:'transacción pendiente',
        paySuccess:'pago realizado con éxito',
        orderMatching:'la coincidencia de pedidos...',
        OrderNow: 'Pedir ahora',
        orderCount: 'Recuento de pedidos',
        orderCountShow:'Pedido completado/pedido total',
        orderReturnBenJin:'devolver el principal',
        orderReturnYongJin:'comisión de devolución',
        orserSuccess:'ordenado con éxito',
        starPeople:'Salón de la Fama',
        priceOne:'precio de los productos básicos:',
        commissionOne:'comisión de productos básicos:',
        officialGuarantees: 'Garantías oficiales',
        todayIncome: 'Ingresos de hoy',
        orderStatus: 'Estado del pedido'
    },
    placeholder:{
        searchPhone:'Puede consultar por el número de teléfono móvil.',
        searchPhone1:'puede consultar por nombre de cuenta.',
        coinAmount:'Introduzca el número de monedas virtuales',
        remark:'Por favor, introduzca el comentario'
    },
    button: {
        login: 'Iniciar sesión',
        logout: 'Cierre de sesión',
        signup: 'Regístrese ahora',
        register: 'Registro',
        forgotPassword: 'Olvidé mi contraseña',
        getVerificationCode: 'Obtener el código de verificación',
        coolDown: 'enfriamiento {segundos}s',
        copy: 'Copiar',
        confirm: 'Confirmar',
        saveInformation: 'Guardar información',
        confirmModify: 'Confirmar modificación',
        transfer: 'Transferencia',
        withdraw: 'Retirarse inmediatamente',
        withdrawAll: 'Retirar todo',
        addWithdrawal: 'Añadir retirada',
        donate: 'Donar ahora',
        donate2: 'Donar',
        submit: 'Enviar',
        card: 'Tarjeta',
        account: 'Cuenta',
        buyNow: 'Comprar ahora',
        addToCart: 'Añadir a la cesta',
        backToHomepage: 'Volver a la página de inicio',
        setNow: 'Configurar ahora',
        goBack: 'Volver',
        upload: 'Subir a',
        search: 'busque en',
        cancel:'cancelar',
        ok:'OK'
    },
    message: {
        unopen: '¡aún no está abierto! {msg}',
        loginInvalid: 'cuenta o contraseña no válida',
        mobileInvalid: 'introduzca un número de teléfono móvil válido',
        accountNotExists: 'la cuenta no existe',
        bindingAddressFailed: 'Fallo en la dirección de entrega vinculante',
        changeAvatarError: 'Fallo en la configuración del avatar',
        selectAvatar: 'Seleccione una imagen',
        bindAccountSuccess: 'Cuenta vinculante con éxito',
        bindAccountFailed: 'Fallo en la vinculación de la cuenta',
        transferSuccess: 'transferencia exitosa',
        vipUpgradeSuccessful: 'Actualización Vip exitosa',
        vipUpgradeFailed: 'Falló la actualización de Vip',
        noNeedUpgrade: 'No es necesario volver a actualizar',
        automaticOrderSuccess: 'Pedido automático realizado con éxito',
        automaticOrderFailed: 'Orden automática fallida',
        notSupportBank: 'Este banco no es compatible con el sistema, por favor, cambie a otro banco',
        withdrawSuccess: 'Retirada con éxito',
        withdrawFailed: 'Retirada fallida',
        withdrawLimitTimes: 'Límite de horas de llegada',
        donateSuccess: 'donar con éxito',
        donateFailed: 'donación fallida',
        alreadyApplied: 'Ya has aplicado',
        noRecords: 'No hay registros',
        bindingAddressSuccessful: 'Vinculación de la dirección de entrega con éxito',
        avatarSettingsSuccessful: 'Configuración del avatar con éxito',
        passwordModifiedSuccessful: 'Contraseña modificada con éxito',
        passwordModifiedFailed: 'Fallo en la modificación de la contraseña',
        transactionModifiedSuccessful: 'Contraseña de la transacción exitosa',
        transactionModifiedFailed: 'La contraseña de la transacción ha fallado',
        bankCardRequire: 'Por favor, rellene primero la tarjeta bancaria',
        balanceRequire: 'Equilibrio insuficiente',
        withdrawPasswordRequire: 'Por favor, rellene primero la contraseña de retirada',
        orderError1:'Por favor, espere un momento y haga clic de nuevo',
        OrderSuccessful: 'Pedido exitoso',
        SpreadRequired: 'Propagación requerida',
        orderTitle:'Esperando la confirmación del comprador。 Por favor, póngase en contacto con el Servicio de atención al cliente para la renovación del pedido',
        orderTitle1:"Esperando la confirmación del comprador。 Su pedido será colocado ",
        orderTitle1_1:" Entrar en un estado congelado。",
    },
    label: {
        code: 'código',
    },
    streamType: {
        0: "Todo",
        1: 'Aumento del saldo de los usuarios', //用户加款
        2: 'Disminución del saldo de los usuarios', //用户减款
        3: 'Mejora de la categoría VIP', //贵宾升级
        4: 'Pedidos de usuarios', //用户下单
        5: 'Comisión sobre los pedidos realizados', //下单返佣
        6: 'Colocación de pedidos de AI Trust', //AI下单
        7: 'Rebajas de AI Trust', //AI返佣
        8: 'Recompensas de la misión', //任务奖励
        9: "El dinero de la adición artificial", //人工加款
        10: "Rebajas artificiales", //人工减款
        11: "Regalo de recarga", //注册赠送
        12:"Reembolso subordinado", //下级返佣
        13:"Experimente la recuperación de oro", //体验金回收
        14:"Regalo de recarga", //充值赠送
        15:"️cancelar orden", //取消订单
        16:"Recarga manual en segundo plano", //后台人工充值
        17:"depósito de bonificación" //彩金存款
    },
    date: {
        SELECT_START_DATE: 'seleccione la fecha de inicio',
        SELECT_END_DATE: 'seleccione la fecha de finalización',
    },
    processStatus: {
        INIT: 'Procesamiento',
        SUCCESS: 'Éxito',
        FAIL: 'Fracaso',
        1: 'Pendiente de revisión', //待审核
        2: 'Auditoría aprobada', //审核通过
        3: 'A pagar', //待支付
        4: 'Rechazo de la solicitud', //驳回申请
        5: 'Pagos en trámite', //代付处理中
        6: 'Volver a auditar', //重新审核
        7: 'Fallo de pago', //代付失败通知
    },
    wsMessage: {
        '202Title': 'Auditoría de recarga con éxito', //充值审核成功
        '202Content': 'Una de sus recargas ya ha sido abonada, por favor compruebe en su historial de recargas.', //您有一笔充值已经到账，请在充值记录里核实。
        '203Title': 'Solicitud de recarga fallida', //充值审核驳回
        '203Content': 'Tiene una recarga que ha sido rechazada, por favor compruebe en su historial de recargas.', //您有一笔充值未成功，请在充值记录里核实。
        '205Title': 'Se están procesando los retiros.', //提现处理中
        '205Content': 'Tiene una retirada que ha comenzado a procesar.', //您有一笔提现已经开始处理。
        '206Title': 'Retiradas procesadas', //提现已处理
        '206Content': 'Tiene una retirada que ha sido procesada, por favor compruebe en el registro de retiradas.', //您有一笔提现已经处理完成，请在提现记录里核实。
    },
    withdrawStatus: {
        INIT: 'Procesamiento',
        SUCCESS: 'Éxito',
        FAIL: 'Fracaso',
    },
    grabStatus: {
        ALL: 'TODOS',
        SUCCESS: 'Éxito',
        FAIL: 'Fracaso',
    },
    rechargeType: {
        RECHARGE: 'Recarga',
        VIP_UPGRADE: 'Actualizar',
    },
    rechargeWay:{
        PayOnline:'Pagar en línea',
        PayViaBank:'Pagar a través de un banco',
        Gift:'Regalo',
        GiftTitle:'Actividades de regalo de recarga',
        GiftDetail:'cada recarga, relación de regalo de recarga:',
        GiftDetail2:'Primera recarga diaria, relación de regalo de recarga:'
    },
    taskStatus: {
        1: 'Haciendo',
        2: 'Completado',
        3: 'Recibido',
        4: 'Expirado',

    },
    clipboard: {
        success: 'Copiado',
    },
    messageStatus: {
        unread: 'Sin leer',
        readed: 'Han leído',
    },
    recharge_online:{
        channelName:'nombre del canal: ',
        max:'cantidad máxima de recarga:',
        min:'cantidad mínima de recarga:'
    },
    faqs:{
        bindCard:'Cómo vincular la cuenta bancaria',
        grab:'Cómo coger un pedido',
        pwd:'Cómo modificar la contraseña del fondo y la contraseña de acceso',
        recharge:'Cómo se rechaza',
        withdraw:'Cómo retirarse',
    },
    text: {
        withdrawCommission:`1. El beneficio de la captación de una orden depende de su saldo, cuanto mayor sea el saldo, mayor será el beneficio.
        2 El beneficio depende de la orden del día y no es fijo. Cuanto mayor sea el importe del pedido, mayor será el beneficio.
`,
        depository: `Aviso:Una vez que usted transfiere los fondos al fondo de custodia.significa que usted se compromete a no retirar los fondos antes de la expiración del ciclo de custodia de fondos.Por favor, proceda con precaución.Después de la expiración de la custodia.su principal y los ingresos se acreditarán automáticamente después de deducir los gastos de gestión.`,
        bankcard: `Abra la billetera de moneda virtual`,
        bankcard2: `Paso 1: Encuentra USDT`,
        bankcard3: `Paso 2: seleccione TRC-20`,
        bankcard4: `Paso 3: copia la dirección`,
        bankcard5: `Banco: XXXX (elija el nombre de su banco)`,
        bankcard6: `Dirección: XXXXXXXXXX (seleccione la dirección válida correcta)`,
        bankcard7: `No se requiere nombre ni nombre de billetera de moneda virtual, solo se requiere la dirección TRC-20`,
        withdraw: 'Si su retiro falla, por favor transfiera a otro banco e intente de nuevo.',
        C_service: 'Haga clic en la imagen de arriba para entrar en el sistema de atención al cliente.Horario de retirada: de lunes a viernes de 7 de la mañana a 7 de la tarde.Si su withDrawal falla, por favor, cambie a otro banco y vuelva a intentarlo.',
        ai_trust_management_help: `La gestión fiduciaria de la IA consiste en transferir sus fondos ociosos a la reserva de fondos de custodia del sistema.
Cuando se transfieren los fondos, el sistema utilizará algoritmos inteligentes de IA para completar automáticamente la orden utilizando los fondos del fondo común.
Los fondos que usted transfiera al fondo común generarán ingresos cada 24 horas. La cantidad de ingresos se basa en el importe de su transferencia y en el tipo de alojamiento y su nivel de afiliación VIP.
Una vez que el depósito expire, el capital y los ingresos se acreditarán automáticamente después de deducir los gastos de gestión.

Traducción realizada con la versión gratuita del traductor www.DeepL.com/Translator`

    }
};