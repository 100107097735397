<template>
  <router-view />

  <AModal
      v-model:visible="visible"
      centered
      :footer="null"
      :title="titleStr"
      @ok="visible = false"
      wrapClassName="Sys_Popup"
      width="85%"
      :zIndex="9999"
    >
      <div class="Sys_Popup_wrap">
        <div class="box text-pre-line underline22" @click="goUrl2">
          {{ contentStr }}
        </div>
      </div>
    </AModal>
</template>

<script>
import { ref, defineComponent, watch } from "vue";
import { useRouter } from "vue-router";
import mybus from "@/utils/mitt";
import { useI18n } from "@/lang";
import { message, notification } from "ant-design-vue";
import { user } from "@/hooks/user";
import api from "@/api";
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
export default defineComponent({
  name: "App",
  setup() {
    const visible = ref(false);
    const titleStr = ref('');
    const contentStr = ref('');
    const router = useRouter();
    const { t,locale } = useI18n();
    locale.value = localStorage.getItem('locale') || sysDataDictionaryWbObj?.value?.LANGUAGE?.dataContent
    mybus.on("GOLOGIN", (isNeedMsg=true) => {
      if (router.currentRoute.value.path === "/login") {
        return;
      }
      if(isNeedMsg){
        message.error(t("error.server.tokenError"));
      }
      router.replace("/login");
    });

    function goUrl2(){
      visible.value = false
      router.replace("/withdrawal_list");
    }
    //断开后1秒重连定时器
    window.timer = null;

    //心跳周期定时器
    window.wsHeartTimer = null;

    //等待时间计数值
    window.waitTime = 0;

    //等待时间计数周期定时器
    window.waitTimeTimer = null;

    //初始化数据、定时器
    let initWs = () => {
      
      if (window.ws) {
        window.ws.close();
        window.ws = null;
      }

      if (window.timer) {
        clearTimeout(window.timer);
        window.timer = null;
      }

      window.waitTime = 0;

      if (window.waitTimeTimer) {
        clearInterval(window.waitTimeTimer);
        window.waitTimeTimer = null;
      }

      if (window.wsHeartTimer) {
        clearInterval(window.wsHeartTimer);
        window.wsHeartTimer = null;
      }

    };

    let createWs = () => {
      initWs()
      if (!user?.value?.ws || !user.value?.curToken) {
        return;
      }
      // console.log(user.value.ws,'--------------------user.value.ws')
      window.ws = new WebSocket(user.value.ws);
      // window.ws = new WebSocket(`ws://${user.value.ws}/websocket`);

      window.ws.onopen = () => {
        window.ws.send(
          JSON.stringify({ code: "101", msg: user.value?.curToken })
        );

        //建立等待时间计数定时器
        window.waitTimeTimer = setInterval(() => {
          if (window.waitTime > 7) {
            window.ws.close();
            initWs()
            return
          }
          window.waitTime += 1;
        }, 1000);

        //建立心跳
        window.wsHeartTimer = setInterval(() => {
          if (window.ws) {
              window.ws.send(JSON.stringify({ code: "100", msg: "sss" }));
          }
        }, 1500);
      };

      window.ws.onclose = () => {
        initWs()
        //1秒后重连
        window.timer = setTimeout(() => {
          createWs();
        }, 1000);
      };

      window.ws.onmessage = (data) => {
        //等待时间归零
        window.waitTime = 0;

        let code = JSON.parse(data?.data).code;
        console.log(code,'--------ss-------')
        switch (+code) {
          case 202:
            // 充值审核成功
            notification.open({
              message: t("common.wsMessage.202Title"),
              description: t("common.wsMessage.202Content"),
              duration: 0,
            });
            api.getUserDetails();
            break;
          case 203:
            // 充值审核驳回
            notification.open({
              message: t("common.wsMessage.203Title"),
              description: t("common.wsMessage.203Content"),
              duration: 0,
            });
            api.getUserDetails();
            break;
          case 205:
            // 提现处理中
            notification.open({
              message: t("common.wsMessage.205Title"),
              description: t("common.wsMessage.205Content"),
              duration: 0,
            });
            api.getUserDetails();
            break;
          case 206:
            if(sysDataDictionaryWbObj?.value?.TKBHTCXS?.dataOnOff){
              visible.value = true
              titleStr.value = t("common.wsMessage.206Title")
              contentStr.value = t("common.wsMessage.206Content")
            }else{
              notification.open({
                message: t("common.wsMessage.206Title"),
                description: t("common.wsMessage.206Content"),
                duration: 0,
              });
            }
            
            api.getUserDetails();
            break;
            // 提现已处理
          case 207:
            //账号被冻结
            message.error(t('error.server.abnormal'))
            mybus.emit('GOLOGIN',false)
            break;
          case 208:
            //收到已派单推送
            // message.error(t('error.server.abnormal'))
            // mybus.emit('GOLOGIN',false)
            router.replace("/grabOrderList2");
            break;
          case 209:
            //收到删除推送
            // message.error(t('error.server.abnormal'))
            // mybus.emit('GOLOGIN',false)
            if (router.currentRoute.value.path === "/grabOrderList2") {
              router.go(0)
              return
            }
            router.replace("/grabOrderList2");
            break;
          case 210:
            //收到删除推送
            // message.error(t('error.server.abnormal'))
            // mybus.emit('GOLOGIN',false)         
            // console.log(router.currentRoute.value.path,'------sss')   
            if (router.currentRoute.value.path === "/recharge" || router.currentRoute.value.path === "/recharge_via_bank/1") {
              router.replace("/home");
            }
           
            break;
          default:
            break;
        }
      };
    };
    createWs();

    //http心跳
    window.heartTimer = null;
    let createHeartTimer = () => {
      clearInterval(window.heartTimer);
      if (!user.value?.curToken) {
        return;
      }
      api.heartbeat();
      window.heartTimer = setInterval(() => {
        api.heartbeat();
      }, 60000);
    };
    createHeartTimer();
    watch(user, (newVal, oldVal) => {
      if (newVal?.curToken != oldVal?.curToken) {
        createWs();
        createHeartTimer();
      }
    });
    return {
      router,
      t,
      visible,
      contentStr,
      titleStr,
      goUrl2
    };
  },
});
</script>
<style lang="scss" scoped>
.underline22{
  text-decoration: underline;
}
</style>