export default {
    placeholder: {
        mobile: 'número de celular',
        verificationCode: 'Código de verificação SMS',
        password: 'Senha',
        confirmPassword: 'Senha novamente',
        inviteCode: 'Código de convite',
        nickname: 'Apelido',
        lineId: 'Linha Conta',
    },
    signup: {
        smsSent: 'O código de identificação foi enviado para seu telefone',
        smsFailed: 'Algo errado durante o envio de SMS, por favor tente mais tarde',
        terms: {
            pre: 'Ao clicar em Cadastre-se, você concorda com nosso',
            link: 'Termos,Política de Dados e Política de Cookies',
            post: 'Você pode receber de nós notificações por SMS e pode optar por não participar a qualquer momento',
        },
    },
};