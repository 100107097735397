export default {
    label: {
        language:'Language',
        total: 'Total',
        count:'Count',
        orderProfits: 'Order Profits',
        totalCommission: 'Total Commission',
        walletBalance: 'My wallet balance',
        Funds_accepted_in_trust:'Funds accepted in trust',
        rechargeInstructions: 'Recharge instructions',
        setTransPassword: 'Set transaction password',
        password: 'Password',
        confirmPassword: 'Confirm password',
        changePassword: 'Please enter your new password',
        oldPassword: 'old password',
        newPassword: 'new password',
        confirmPasswordLower: 'confirm password',
        //use_info
        avatar: 'Avatar',
        nickname: 'Nickname',
        myCellNo: 'My cellphone number',
        myBankId: 'My bank card',
        myWithdrawal: 'My withdrawal method',
        changeUserPassword: 'Password management',
        withdrawPassword: 'Withdraw password',
        shippingAddress: 'Shipping address',
        //address
        realInfo: 'Receiving identity information',
        actualName: 'Actual name',
        actualCellNo: 'Phone number',
        addressInfo: 'Delivery address information',
        addressArea: 'Area',
        address: 'Address',
        //bankcard
        yourId: 'Your identity',
        accountName: 'Account name',
        contactMobile: 'Contact phone',
        yourAccountInfo: 'Your account information',
        bank: 'Bank',
        accountNo: 'Account number',
        //depository
        availableAssets: 'Available assets',
        yesterdayEarn: "Yesterday's earnings",
        cumulativeIncome: 'Cumulative income',
        days: 'Days',
        expectedProfits: 'Expected Profits',
        vipLevelRequired: 'VIP level required',
        fee: 'Fee',
        feeAmount:'Fee amount',
        actualToAccount:'The actual to account',
        min: 'Min',
        max: 'Max',
        time: 'Time',
        serialNumber: 'Serial number',
        orderNumber: 'order number',
        amount: 'Amount',
        profits: 'Profits',
        expireDate: 'Expire date',
        expectedReturn: 'Expected Return',
        //grab
        commission: 'Commission',
        vip: 'VIP',
        grabbedToday: 'Grabbed Today',
        availableToday: 'Available Today',
        commissionYesterday: 'Commissions yesterday',
        commissionsTotal: 'Commissions total',
        commissionsToday: 'Commissions today',
        vipLevelUpgrade: 'Vip Level Upgrade',
        automaticOrder: 'Automatic order',
        explanation: 'Explanation',
        orderRecord: ' Order Record',
        return: 'Return',
        orderAmount:'orderAmount',
        //recharge
        rechargeSuccess: 'Recharge success',
        //withdraw
        withdrawAmount: 'Withdrawal Amount',
        balance: 'Balance',
        tax: 'Tax',
        afterTax: 'After Tax',
        contactEmail: 'Contact Email',
        extraBonus: 'Extra bonus',
        selectPaymentChannel: 'Select channel',
        teamReports:'reports',

        methodType_1:'pix',
        methodType_2:'USDT-TRC20',
        methodType_3:'USDT-ERC20',
        methodType_4:'Bank card',
        pixType: 'pix type',

        teamReportsTitle:'Team reports overview',
        teamReportsDetail:'Team reports detail',
        //team records
        chargeTotalAmount:'Total recharge',
        firstChargeNumber:'The total of first recharging users',
        orderAmountTotal:'The total money of orders',
        payTotalAmount:'Total withdrawals',
        profitTotal:'Total profit',
        registerNumber:'Total registers',
        subordinateNumber:'Total subordinates',
        subordinateNumber1:'Total members',
        teamBalance:'Total money of team accounts',
        totalPeople: 'The total of users who has paid orders',

        teamOrderRecords:'Order records',

        teamAccountRecords:'Account records',

        rechargeDetail:'Recharge',
        withdrawDetail:'withdrawal',
        orderAndRebateDetail:'Order',

        agencyType:'Hierarchy',
        LastLogin:'Last login',
        myself:'Myself'

    },
    menu: {
        trustManagement: 'AI Trust Management',
        information: 'Information',
        orderRecord: 'Order record',
        accountDetails: 'Account details',
        rechargeRecord: 'Recharge record',
        withdrawRecord: 'Withdrawal record',
        inviteFriends: 'Invite Friends',
        announcement: 'Announcement',
        about: 'About us',
        download: 'App Download',
        recharge: 'Recharge',
        withdraw: 'Withdraw',
        service: 'Service',
        agencyCenter:'Agency center',
        agencyRecords:'Agency records',
    },
    hits: {
        transPassword: `Please remember the password, if you forget the password, please contact customerservice.`,
        bindAccount: `Security Tip: Please check before submit bank account info. Each
            user has only one free chance submitting bank account. We guarantee
            to keep all information confidential. * * (When you want to modify
            the information again, you need to pay {fee})`,
        // bindAccountConfirm: `Modifying bank card information requires a fee of {fee}. Are you sure you want to modify it?`,
        bindAccountConfirm: `Please double check and make sure the information is correct. Are you sure about submitting it?`,
        minimumAmount: 'Minimum Amount {amount}',
        maximumAmount: 'Maximum Amount {amount}',
        //grab
        grabTip1: 'In the rush for orders',
        grabTip2: 'Connecting to order center',
        grabTip3: 'Waiting for database response',
        grabTip4: 'Waiting for the server to allocate the order',
        minimumRecharge: 'Minimum recharge of {amount}',
        maximumRecharge: 'Maximum recharge of {amount}',
        recharge: `tip: You can find all recharge records in "Recharge Record"`,
        rechargeOrderSubmitted: 'Please wait for system administrator to audit the order.',
        rechargeBank: 'Transfer specified amount of money to the following account and upload screenshots',
    },
    placeholder: {
        password: 'Please enter password',
        oldPassword: 'Please enter your old password',
        oldPasswordLength: 'Password must be between 4 and 30 characters',
        newPassword: 'Please enter a new password',
        newPasswordLength: 'Password must be between 6 and 30 characters',
        confirmPassword: 'Please enter the password again',
        passwordMath: `Two inputs don't match!`,
        realName: 'Please enter your real name',
        area: 'Please enter area',
        contactPhone: 'Please enter the contact phone',
        validPhoneNumber: 'Please input valid mobile phone number',
        address: 'Please enter the address',
        accountName: 'Please enter your account name',
        accountNo: 'Please enter account number',
        selectIFSC: 'Please select bank',
        transAmount: 'Please Enter Transfer Amount',
        rechargeAmount: 'Please enter your amount',
        selectPayChannel: 'Please select payment channel',
        requireIFSC: 'Please enter the IFSC',
        fundPassword: 'please enter your withdraw password',
        fundCI: 'please enter your CI',
        fundCI2: 'please enter your Identification number',
        withdrawAmount: 'Please enter the withdraw amount',
        excessBalance: 'Excess balance',
        bank: 'Please enter your bank name',
        withdrawalType:'Please select a withdrawal method',
        contactEmail: 'Please enter your contact email',
    },
    agencyCenter:{
        subordinatesManagement:'subordinates management',
        rechargeROT:'recharge records of team',
        withdrawROT:'withdraw records of team',
        orderROT:'order records of team',
        accountROT:'account records of team',
        TeamRO:'Team reports overview'
    }
};