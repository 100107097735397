export default {
    somethingWentWrong: 'Something went wrong, please try later',

    signup: {
        success: 'Registration success',
        termOfService: 'You must agree our terms of service to sign up',
        invalidInviteCode: "We're sorry, the invite code is invalid",
    },
    resetPassword: {
        success: 'Password has been changed',
    },
    validator: {
        mobile: {
            required: 'Please input mobile phone number',
            invalid: 'Please input correct mobile phone number',
            exists: 'Phone number has been registered',
            notExists: 'Account not exists',
            startWith0:'Cannot start with 0',
            errorlength: 'Mobile number length is 8 - 11', //or 11                 
        },
        nickname: {
            required: 'Please enter your nickname',
            length: 'Nickname is too short',
        },
        inviteCode: {
            required: 'Please enter invite code',
            invalid: 'Invalid invite code',
        },
        password: {
            required: 'Please input password',
            length: 'Minimum length 6 characters',
        },
        checkPass: {
            required: 'Please enter password again',
            notMatch: 'Repeat password not match',
        },
        identifyCode: {
            required: 'Please input identify code from sms',
            invalid: 'Invalid identify code',
        },
    },
    grab:{
         noAvailableToday:'no available today',
         finishTasks:'You have finished all tasks today',
         insufficient:'insufficient account balance'
    },
    withDraw:{
        amountErr:'Hello, the minimum withdrawal amount is {min},the maximum withdrawal amount is {max}'
    },
    recharge:{
        noAmount:'Please select amount',
        noChannel:'Please select channel',
        noMatchLimit:'Exceeding channel limit',
        screenshotsRequired:"You must upload the transfer voucher",
        decimalErr:'Please enter up to two decimal places',
        decimalErr4:'Cannot exceed 4 decimal places',
        amountErr:'Hello, the minimum recharge amount is {min},the maximum recharge amount is {max}',
        selectBank: 'Please select the Beneficiary Bank'
    },
    server: {
        tokenError:'the login information has expired',
        startupQuery: 'Load config failed due to network error, please try later',
        abnormal:'The network is abnormal. Check the network connection',
        error400: 'Bad request',
        error401: 'Unauthorized',
        error403: 'Forbidden',
        error404: 'Resource not exists',
        error405: 'Network error',
        error408: 'Request timeout',
        error500: 'Server internal error',
        error501: 'Server internal error',
        error502: 'Server network error',
        error503: 'Server temporarily unavailable',
        error504: 'Network timeout',
        error505: 'Browser error',
        InvalidPhoneNumber: 'Invalid phone number',
        InvalidFeeRate: 'Server config error',
        NoEnoughBalance: 'Insufficient balance',
        WithdrawFrequency: 'Withdraw frequency limit',
        WithdrawAmountExceedVipLimitation: 'Withdrawal amount exceeds VIP level limit',
        TooManyWithdraws: 'Too many withdrawals today, please try tomorrow',
        MaximumVipLevel: 'Maximum VIP level reached',
        NoPaymentChannelAvailable: 'Payment channel is unavailable, please try later',
        LastVipUpgradeProcessing: 'VIP level upgrade in progress, please wait for a while',
        WithdrawNotOpenForNow: 'Out of withdraw service time range',
        WithdrawNotAllowed: 'Withdraw condition not fulfilled, please contact customer service',
        RechargeNotOpenForNow: 'Out of recharge service time range',
        InvalidRechargeAmount: 'Invalid recharge amount',
        FundPasswordNotSet: 'Please set withdraw password first',
        FundPasswordNotMatch: 'Please input correct withdraw password',
        TooManyProcessingTasks: 'Too many processing tasks',
        InvalidTask: 'Task is invalid',
        InvalidOldPassword: 'Please input correct old password',
        VipLevelLimit: 'VIP level limit',
        AccountNotExists: 'Account not exists',
        InvalidIdentifyCode: 'Please input correct identify code',
        InvalidInviteCode: 'Please input correct invite code',
        MobileHasBeenRegistered: 'Phone number has been registered',
        AmountExceedsDepositoryThreshold: 'AI trust management amount exceeds threshold',
        TooManyGrabsToday: 'You have finished all tasks today',
        ValidationError: 'Form value contains invalid value',
        BankcardExists: 'The bank card information already exists',
        DonationComplete: 'Recipient has received enough donation, thank you for your kindly help',
        GrabNotComplete: 'Please complete all order tasks today before initiating withdrawal',
        AlreadySubmitDonationApplication: "We're sorry but you can't apply donation multiple times",
        DonationLimited: 'Thank you for your generosity, but you should recharge some money before donate',
        AgentNotExists: 'Agent not found',
        AccountBanned: 'Account activity limit',
        SomethingWentWrong: 'Something went wrong',
        SystemMaintenance: 'System under maintenance, please try later',
        ServerError: 'Server temporarily down, please try later',
        GrabFrequency: 'Grab frequency limit',

    },
};