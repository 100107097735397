export default {
    title: ({
        named
    }) => {
        const typeName = named('mensaje Tipo');
        switch (typeName) {
            case 'RECHARGE_AUDIT_REJECT':
                return 'Recharge failed';
            case 'RECHARGE_SUCCESS':
                return 'Recharge success';
            case 'VIP_UPGRADE_SUCCESS':
                return `Upgrade success`;
            case 'WITHDRAW_AUDIT_PASS':
                return `Withdrawal audit result`;
            case 'WITHDRAW_AUDIT_REJECT':
                return `Withdrawal audit result`;
            case 'WITHDRAW_SUCCESS':
                return `Withdrawal complete`;
            case 'WITHDRAW_FAILED':
                return `Withdrawal failed`;
            case 'SYSTEM_BONUS':
                return `Bonus received`;
            case 'SYSTEM_PENALTY':
                return `Account change`;
            case 'TASK_ACCOMPLISHED':
                return `Task accomplished`;
            case 'DEPOSITORY_PROFITS':
                return `AI trust profits`;
            case 'DEPOSITORY_TRANSOUT':
                return 'AI trust complete';
            case 'DONATION_AUDIT':
                return `Donation application`;
            case 'DONATION_RECEIVED':
                return `Donation money received`;
            case 'DONATION_COMPLETE':
                return `Donation complete`;
            case 'OTHER':
                return `${named('title')}`;
            default:
                return '';
        }
    },

    content: ({
        named,
        linked
    }) => {
        return linked(`message.content_${named('messageType')}`);
    },
    content_OTHER: '{contenido}',
    content_RECHARGE_AUDIT_REJECT: 'Estimado miembro de VIP{vip_level}, la revisión de recarga iniciada por usted en {inserted_at} fue rechazada. Si tiene alguna duda, póngase en contacto con el servicio de atención al cliente.',
    content_RECHARGE_SUCCESS: 'Estimado miembro VIP{vip_level}, el número de pedido que inició en {inserted_at} es: ¡La recarga de {order_no} ha llegado!;',
    content_VIP_UPGRADE_SUCCESS: 'Estimado socio, ¡ha ascendido a VIP{upgrade_level} en {inserted_at}!',
    content_WITHDRAW_AUDIT_PASS: 'Estimado miembro VIP{vip_level}, su solicitud de retiro iniciada el {inserted_at}, ha sido aprobada, y la cantidad recibida es de {amount}. Por favor, preste atención a su cuenta bancaria',
    content_WITHDRAW_SUCCESS: 'Estimado miembro VIP{vip_level}, su solicitud de retiro iniciada en {inserted_at}, ha llegado con éxito, y la cantidad recibida es {amount}. Por favor, preste atención a su cuenta bancaria',
    content_WITHDRAW_FAILED: 'Estimado miembro VIP{vip_level}, la revisión de la retirada iniciada por usted en {inserted_at} fue fallida',
    content_WITHDRAW_AUDIT_REJECT: 'Estimado miembro VIP{vip_level}, la revisión de la retirada iniciada por usted en {inserted_at} fue rechazada. Motivo del rechazo: Información de la tarjeta bancaria incorrecta. Si tiene alguna duda, póngase en contacto con el servicio de atención al cliente.',
    content_SYSTEM_BONUS: 'Estimado miembro de VIP{vip_level}, ha recibido {importe} emitido por el administrador de la plataforma, por favor compruebe su cuenta.',
    content_SYSTEM_PENALTY: 'Estimados miembros de VIP{vip_level}, su cuenta ha cambiado. Si tiene alguna duda, póngase en contacto con el servicio de atención al cliente.',
    content_TASK_ACCOMPLISHED: 'Estimado miembro VIP{vip_level}, ha completado la tarea de invitación, y la recompensa {bonus} ha sido acreditada.',
    content_DEPOSITORY_PROFITS: 'Estimado miembro de VIP{vip_level}, ya ha generado ingresos de {beneficios} cuando se transfirió a la gestión fiduciaria de AI el {inserted_at}',
    content_DEPOSITORY_TRANSOUT: 'Estimado miembro VIP{vip_level}, el {importe} que transfirió al patrimonio de gestión fiduciaria de AI el {inserted_at} ha expirado, por favor, preste atención a los cambios en su cuenta',
    content_DONATION_AUDIT: 'Estimado miembro VIP{vip_level}, su solicitud de donación ha sido auditada: {estado_de_auditoría}',
    content_DONATION_RECEIVED: 'Estimado miembro VIP{vip_level}, ha recibido un total de {amountReceived} donación, por favor, compruebe su cuenta.',
};