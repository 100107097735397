export default {
    menu: {
        index: 'MOA Peduli',
        home: 'Rumah',
        team: 'Tim',
        service: 'Melayani',
        my: 'Ku',
        grabOrder:'ambilPesanan',
        grabOrderList2:'tugas'
    },
    title: {
        download: 'unduh',
        termOfService: 'Ketentuan Layanan',
        maintenance: 'Sistem dalam pemeliharaan',
        registration: 'Registrasi',
        verificationCode: 'Kode verifikasi',
        inviteFriends: 'Mengundang teman-teman',
        forgotPassword: 'Tidak ingat kata sandi',
        team: 'Laporan Tim',
        teamDesc: 'Ikhtisar tim bawahan Anda',
        inviteTasks: 'Undang Tugas',
        service: 'Pelayanan pelanggan',
        siteMessage: 'Pesan',
        recharge: 'Isi ulang',
        transPassword: 'Manajemen kata sandi transaksi',
        password: 'Manajemen kata sandi',
        information: 'Informasi',
        withdrawal:'Metode penarikan',
        address: 'Alamat pengiriman',
        avatar: 'Pengaturan Avatar',
        bankcard: 'Mengikat akun',
        streamRecords: 'Detail akun',
        announcement: 'Pengumuman',
        about: 'Tentang kami',
        aboutCompany: 'Profil Perusahaan',
        aboutBusiness: 'Perjanjian Layanan',
        aboutTeam: 'Deskripsi Produk',
        careApplication: 'Terapkan untuk Bantuan',
        careDonation: 'Donasi',
        careRecipientDetail: 'rincian',
        careRecipientList: 'Aku ingin membantu',
        depository: 'Manajemen Kepercayaan AI',
        depositoryRecords: 'Catatan Kepercayaan AI',
        grab: 'Pengambilan pesanan',
        grabRecords: ' Catatan pesanan',
        rechargeRecords: 'Isi ulang catatan',
        rechargeResult: 'Hasil Isi Ulang',
        withdraw: 'Menarik',
        withdraw1: 'Menarik',
        withdraw2: 'Menarik',
        withdrawCommission: 'Komisi penarikan',
        withdrawRecords: 'Catatan penarikan',
        prompt: 'Mengingatkan',
        guessYouLike: 'Kira kamu suka',
        comment: 'Komentar',
        whatsApp: 'Garis',
        rechargeOrderSubmitted: 'Isi ulang pesanan dibuat',
        rechargeWithBankcard: 'Bayar melalui bank',
        agencyCenter:'Pusat agensi',
        agencyRecords:'Catatan Agensi',
        subordinatesManagement:'Manajemen bawahan',
        subordinatesProfit:'Keuntungan bawahan',


        cardNumber:'Nomor kartu',
        cardNumber2:'Nomor kartu',
        actualName:'Nama Asli',

        withdrawalAddress:'Alamat Penarikan',
        
        Account:'Pix akun',
        Name:'Nama',
        Monto:'Jumlah',
        Calbe:'Calbe',
        Nombre:'Nama',

        Task:'Daftar tugas',
        trade:'Berdagang...',
        freeze:'Membekukan',
        completed:'Lengkap',

        Paid:'Dibayar',
        Excuting:'Melaksanakan perintah',
        ExcutingEnd:'Melaksanakan perintah',
        grabOrderDetail:'daftar keinginan',
        payNow:'Pembayaran langsung',
        pendingTransaction:'transaksi yang tertunda',
        paySuccess:'pembayaran berhasil',
        orderMatching:'pesanan sesuai...',
        OrderNow: 'Pesan sekarang',
        orderCount: 'Jumlah Pesanan',
        orderCountShow:'Selesai pesanan/Total pesanan',
        orderReturnBenJin:'kembalikan kepala sekolah',
        orderReturnYongJin:'komisi pengembalian',
        orserSuccess:'berhasil dipesan',
        starPeople:'ketenaran',
        priceOne:'harga komoditas:',
        commissionOne:'komisi komoditas:',
        officialGuarantees: 'jaminan resmi',
        todayIncome: 'pendapatan hari ini',
        orderStatus: 'status pemesanan'
    },
    placeholder:{
        searchPhone:'Anda dapat menanyakan dengan nomor ponsel.',
        searchPhone1:'Anda dapat menanyakan berdasarkan nama akun.',
        coinAmount:'Silakan masukkan jumlah koin virtual',
        remark:'Silakan masukkan komentar'
    },
    button: {
        login: 'Gabung',
        logout: 'Keluar',
        signup: 'Mendaftar',
        register: 'Daftar',
        forgotPassword: 'Tidak ingat kata sandi',
        getVerificationCode: 'Dapatkan kode verifikasi',
        coolDown: 'dinginkan {second}s',
        copy: 'Salinan',
        confirm: 'Mengonfirmasi',
        saveInformation: 'Simpan informasi',
        confirmModify: 'Konfirmasi modifikasi',
        transfer: 'Transfer',
        withdraw: 'Tarik segera',
        withdrawAll: 'Tarik semua',
        addWithdrawal: 'Tambahkan penarikan',
        donate: 'Donasi Sekarang',
        donate2: 'Menyumbangkan',
        submit: 'Kirim',
        card: 'Kartu',
        account: 'Akun',
        buyNow: 'Beli sekarang',
        addToCart: 'Masukkan ke keranjang',
        backToHomepage: 'Kembali ke beranda',
        setNow: 'Siapkan sekarang',
        goBack: 'Kembali',
        upload: 'Mengunggah',
        search: 'Cari',
        cancel:'membatalkan',
        ok:'Oke'
    },
    message: {
        unopen: 'belum buka! {msg}',
        loginInvalid: 'akun atau kata sandi tidak valid',
        mobileInvalid: 'silakan masukkan nomor ponsel yang valid',
        accountNotExists: 'akun tidak ada',
        bindingAddressFailed: 'Mengikat alamat Pengiriman gagal',
        changeAvatarError: 'Pengaturan avatar gagal',
        selectAvatar: 'Silakan pilih gambar',
        bindAccountSuccess: 'Pengikatan akun berhasil',
        bindAccountFailed: 'Pengikatan akun gagal',
        transferSuccess: 'transfer berhasil',
        vipUpgradeSuccessful: 'Peningkatan vip berhasil',
        vipUpgradeFailed: 'Peningkatan vip gagal',
        noNeedUpgrade: 'Tidak perlu mengupgrade lagi',
        automaticOrderSuccess: 'Pesanan otomatis berhasil',
        automaticOrderFailed: 'Pesanan otomatis gagal',
        notSupportBank: 'Bank ini tidak didukung oleh sistem, silakan ganti ke bank lain',
        withdrawSuccess: 'Penarikan berhasil',
        withdrawFailed: 'Penarikan gagal',
        withdrawLimitTimes: 'Batas waktu kedatangan',
        donateSuccess: 'donasi berhasil',
        donateFailed: 'donasi gagal',
        alreadyApplied: 'Anda sudah melamar',
        noRecords: 'Tidak ada catatan',
        bindingAddressSuccessful: 'Mengikat alamat Pengiriman berhasil',
        avatarSettingsSuccessful: 'Pengaturan avatar berhasil',
        passwordModifiedSuccessful: 'Kata sandi berhasil diubah',
        passwordModifiedFailed: 'Kata sandi diubah gagal',
        transactionModifiedSuccessful: 'Kata sandi transaksi berhasil',
        transactionModifiedFailed: 'Kata sandi transaksi gagal',
        bankCardRequire: 'Silakan isi kartu bank terlebih dahulu',
        balanceRequire: 'Saldo tidak mencukupi',
        withdrawPasswordRequire: 'Silakan isi kata sandi penarikan terlebih dahulu',
        orderError1:'Mohon tunggu sebentar dan klik lagi',
        OrderSuccessful: 'pesanan sukses',
        SpreadRequired: 'Diperlukan sebaran',
        orderTitle:"Menunggu konfirmasi pembeli。 Silakan hubungi Layanan Pelanggan untuk pembaruan pesanan",
        orderTitle1:"Menunggu konfirmasi dari pembeli。 Pesanan Anda akan dilakukan ",
        orderTitle1_1:" Masukkan keadaan beku。",
    },
    label: {
        code: 'code',
    },
    streamType: {
        0: "Semua",
        1: 'Peningkatan saldo pengguna', //用户加款
        2: 'Penurunan saldo pengguna', //用户减款
        3: 'Peningkatan VIP', //贵宾升级
        4: 'Pesanan Pengguna', //用户下单
        5: 'Komisi atas pesanan yang ditempatkan', //下单返佣
        6: 'Penempatan Pesanan AI Trust', //AI下单
        7: 'Rabat Kepercayaan AI', //AI返佣
        8: 'Hadiah Misi', //任务奖励
        9: "Buatan menambahkan uang", //人工加款
        10: "Rabat buatan", //人工减款
        11: "isi ulang hadiah", //注册赠送
        12:"rabat bawahan", //下级返佣
        13:"Rasakan pemulihan emas", //体验金回收
        14:"isi ulang hadiah", //充值赠送
        15:"️Batalkan pesanan", //取消订单
        16:"Isi ulang manual di latar belakang", //后台人工充值
        17:"Setoran bonus" //彩金存款
    },
    date: {
        SELECT_START_DATE: 'pilih tanggal mulai',
        SELECT_END_DATE: 'pilih tanggal akhir',
    },
    processStatus: {
        INIT: 'Pengolahan',
        SUCCESS: 'Kesuksesan',
        FAIL: 'Kegagalan',
        1: 'Ulasan Tertunda', //待审核
        2: 'Audit disetujui', //审核通过
        3: 'Hutang', //待支付
        4: 'Penolakan lamaran', //驳回申请
        5: 'Pembayaran sedang diproses', //代付处理中
        6: 'Audit ulang', //重新审核
        7: 'Kegagalan pembayaran', //代付失败通知
    },
    wsMessage: {
        '202Title': 'Audit isi ulang yang berhasil', //充值审核成功
        '202Content': 'Salah satu top-up Anda telah dikreditkan, silakan periksa riwayat top-up Anda.', //您有一笔充值已经到账，请在充值记录里核实。
        '203Title': 'Permintaan isi ulang gagal', //充值审核驳回
        '203Content': 'Anda memiliki isi ulang yang ditolak, silakan periksa riwayat isi ulang Anda.', //您有一笔充值未成功，请在充值记录里核实。
        '205Title': 'Penarikan sedang diproses.', //提现处理中
        '205Content': 'Anda memiliki penarikan yang telah mulai diproses.', //您有一笔提现已经开始处理。
        '206Title': 'Penarikan diproses', //提现已处理
        '206Content': 'Anda memiliki penarikan yang telah diproses, silakan periksa di log penarikan.', //您有一笔提现已经处理完成，请在提现记录里核实。
    },
    withdrawStatus: {
        INIT: 'Pengolahan',
        SUCCESS: 'Kesuksesan',
        FAIL: 'Kegagalan',
    },
    grabStatus: {
        ALL: 'ALL',
        SUCCESS: 'Kesuksesan',
        FAIL: 'Kegagalan',
    },
    rechargeType: {
        RECHARGE: 'Isi ulang',
        VIP_UPGRADE: 'Meningkatkan',
    },
    rechargeWay:{
        PayOnline:'Bayar Online',
        PayViaBank:'Bayar Melalui Bank',
        Gift:'Hadiah',
        GiftTitle:'Kegiatan hadiah isi ulang',
        GiftDetail:'setiap isi ulang, rasio hadiah isi ulang:',
        GiftDetail2:'Isi ulang pertama setiap hari, rasio hadiah isi ulang:'
    },
    taskStatus: {
        1: 'Sedang mengerjakan',
        2: 'Lengkap',
        3: 'diterima',
        4: 'Kedaluwarsa',

    },
    clipboard: {
        success: 'disalin',
    },
    messageStatus: {
        unread: 'Belum dibaca',
        readed: 'Telah membaca',
    },
    recharge_online:{
        channelName:'nama saluran:',
        max:'jumlah isi ulang maksimum:',
        min:'jumlah isi ulang minimum:'
    },
    faqs:{
        bindCard:'Cara mengikat rekening bank',
        grab:'Cara ambil pesanan',
        pwd:'Cara mengubah kata sandi dana dan kata sandi login',
        recharge:'Bagaimana cara mengisi ulang?',
        withdraw:'Bagaimana cara menarik?',
    },
    text: {
        withdrawCommission:`Transfer komisi ke saldo`,
        grabExplanation: `1. Keuntungan Anda dari mengambil pesanan tergantung pada saldo Anda, semakin tinggi saldo, semakin tinggi keuntungannya.
        2.Setiap pengguna dapat mengambil 20 pesanan per hari. Keuntungan tergantung pada pesanan hari itu, tidak tetap.
        3. Level yang lebih tinggi akan memiliki pengembalian yang lebih tinggi, jika Anda ingin meningkatkan level, silakan klik 'Peningkatan level VIP' dan selesaikan pembayaran untuk meningkatkan.
`,
        depository: `Pemberitahuan: Setelah Anda mentransfer dana ke kumpulan dana escrow. Artinya Anda setuju untuk tidak menarik dana sebelum berakhirnya siklus dana escrow. Harap lanjutkan dengan hati-hati. Setelah escrow berakhir. Pokok dan pendapatan Anda akan secara otomatis dikreditkan setelah dikurangi biaya penanganan.`,
        bankcard: `Jika akun PIX adalah CPF: isi kode CPF 11 digit`,
        bankcard2: `Jika akun PIX adalah nomor ponsel: isi 13 digit nomor ponsel plus 62 (62XXXXXXXXXXX)`,
        bankcard3: `Jika akun PIX adalah alamat email: Isi alamat email secara langsung`,
        bankcard4: `Akun PIX dapat berupa: CPF, telepon, email, atau EVP/kunci (EVP/kunci adalah kode yang panjang dan rumit)`,
        bankcard5: `Metode pembayaran telepon harus diawali dengan 62, jika tidak, pembayaran langsung akan gagal!`,
        bankcard6: ``,
        bankcard7: ``,
        withdraw: 'Waktu penarikan. Senin sampai Jumat jam 10 pagi sampai jam 10 malam, jika penarikan Anda gagal, silakan transfer ke bank lain dan coba lagi.',
        C_service: 'Klik gambar di atas untuk masuk ke sistem customer service.Waktu penarikan:Senin-Jumat 10:00 - 22:00.Jika Withdraw Anda gagal, silakan pindah ke bank lain dan coba lagi.',
        ai_trust_management_help: `Manajemen kepercayaan AI adalah mentransfer dana menganggur Anda ke kumpulan dana penyimpanan sistem.
        Saat dana ditransfer, sistem akan menggunakan algoritme cerdas AI untuk menyelesaikan pesanan secara otomatis menggunakan dana di kumpulan dana.
        Dana yang Anda transfer ke kumpulan dana akan menghasilkan pendapatan setiap 24 jam. Jumlah pendapatan didasarkan pada jumlah transfer dan jenis hosting serta tingkat keanggotaan VIP Anda.
        Setelah escrow berakhir, pokok dan pendapatan Anda akan secara otomatis dikreditkan setelah dikurangi biaya penanganan.`

    }
};