export default{
    MySubordinates :'mis subordinados',
    listTheSubordinates:'lista de los subordinados',
    goReports:'ir a los informes',
    virtualCurrency :'moneda virtual',
    rechageText1:'Transfiere la cantidad de dinero especificada a la siguiente cuenta y sube las capturas de pantalla.',
    rechageText2:'(*Por favor, póngase en contacto con el servicio de atención al cliente antes de recargar y deberá asumir el riesgo de este canal).',
    coinType:'tipo de moneda',
    coinAmount:'cantidad de monedas',
    channel:'canal',
    rate:'tasa',
    remark:'comentario',
    maxAmount:'cantidad máxima',
    minAmount:'cantidad mínima',
    bankPay:'pago con tarjeta bancaria',
    bankName:'nombre del banco',
    BeneficiaryBank:'Banco beneficiario',
    holderName:`Nombre real del titular de la tarjeta`,
    rememberPwdText:`Por favor, recuerde la contraseña, si la olvida, póngase en contacto con el servicio de atención al cliente.`,
    versionInfo:'Información sobre la versión',
    instructions:'Instrucciones',
    minLength:'La longitud mínima es {min}.',
    maxLength:'La longitud máxima es {max}.',
    allSub:'Todos los subordinados',
    directSub:'Subordinados directos',
    PIX:'Subordinados directos',
    realName:'Nombre real'
}