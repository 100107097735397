export default {
    inviteFriends: 'Mengundang teman-teman',
    message: 'pesan',
    availableAssets: 'Aset yang tersedia',
    yesterdayEarnings: `Kemarin`,
    cumulativeIncome: `Kumulatif`,
    todayEarnings: `Hari ini`,
    recharge: 'Isi ulang',
    withdraw: 'Menarik',
    aboutUS: 'Tentang kami',
    whyUS: 'Mengapa AS',
    howToEarn: 'Cara Menghasilkan',
    howToShare: 'Cara Berbagi',
    missionList: 'Daftar Misi',
    commission: 'Komisi',
    BeginnersGuide: 'Panduan Pemula',
    experienceGold: 'Pengalaman emas',
    ChooseYourAvatar:'Pilih avatarmu',
    integral: 'integral'
};