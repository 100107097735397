export default {
    label: {
        totalMembers: 'Toplam takım büyüklüğü',
        totalCommission: 'Toplam Takım Komisyonu',
        totalRecharge: 'Toplam şarj',
        totalWithdraw: 'Toplam çekilme',
        commission: 'Komisyon',
        members: 'Üyeler',
        bonus: 'Bonus',
        expireIn: 'Son kullanma tarihi',
        days: 'günler',
        cellNo: 'Cep telefonu numarası',
        registrationDate: 'Kayıt Tarihi',
        balance:'denge',
        samedaySubcCmmission:'Aynı gün alt komisyonu'
    },
};