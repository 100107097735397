export default {
    label: {
        advisory: 'Assessoria',
        complaint: 'Reclamação',
        faq: 'Perguntas mais freqüentes',
    },
    message: {
        system_success: 'Sucesso',
        system_fail: 'Falha',
        system_add_suc: 'Adicionar Sucesso',
        system_add_fail: 'Adicionar falha',
        system_del_suc: 'Eliminado com sucesso',
        system_del_fail: 'Falha em excluir',
        system_update_suc: 'Atualização bem sucedida',
        system_update_fail: 'Atualização falhou',
        system_api_error: 'Exceções de interface',
        system_opt_suc: 'Operação bem sucedida',
        system_opt_fail: 'A operação falhou',
        system_opt_edit: 'Editor',
        system_opt_del: 'Excluir',
        system_data_not_empty: 'Os dados não podem estar vazios',

        login_username_not_empty: `A conta de login não pode estar vazia`,
        login_password_not_empty: 'A senha de login não pode estar vazia',
        login_username_not_exist: 'Conta de login não disponível',
        login_pass_err: 'Senha de login errada',
        login_username_freeze: 'O usuário foi congelado e não pode fazer login',
        login_username_lt_4: 'O número da conta de login não deve ser inferior a 4 dígitos',
        login_password_empty: 'Por favor, digite a senha de login',
        login_password_lt_4: 'A senha de login não deve ser inferior a 4 dígitos',
        login_success: 'Entrar com sucesso',
        login_fail: 'Falha no login',
        login_param_empty: 'A conta ou senha está vazia',
        login_param_unvalid: 'Conta ou senha incorreta',

        logout_btn: 'Cancelamento',
        logout_confirm: 'Confirmar logout?',
        logout_success: 'Baixa contábil bem-sucedida',
        logout_fail: 'Falha no cancelamento',

        user_add: 'New Users',
        user_update: 'Atualizar usuários',
        user_username_repeat: 'Contas duplicadas',
        user_username_valid: 'RestriçõesBecomeço com uma letra minúscula, consistindo de letras minúsculas, números',
        user_password_update_placeholder: 'Favor inserir uma nova senha, se ela estiver vazia, a senha não será atualizada',
        user_update_loginuser_limit: 'Proibir a operação da conta atualmente registrada',
        user_not_exist_update_fail: 'A modificação falhou, a conta não existe',
        user_old_password_err: 'A senha antiga está incorreta, favor entrar novamente',
        user_nick_not_empty: 'O apelido não pode estar vazio',
        user_code_not_empty: 'O Captcha não pode estar vazio',
        user_sms_code_expired: 'O Captcha expirou',
        user_sms_code_err: 'Erro do Captcha',
        user_invitation_code_not_exist: 'O código do convite não existe',
        user_invitation_code_err: 'Erro no código do convite',
        user_level_not_configured: 'Sem informações de nível, por favor configure o nível',
        user_reg_fail: 'O registro falhou',
        user_send_sms_fail: 'O envio falhou, por favor configure o conteúdo da mensagem',
        user_account_not_exist: 'A conta não existe',
        user_avatar_not_empty: 'O endereço do avatar não pode estar vazio',
        user_transaction_password_not_empty: 'A senha da transação não pode estar vazia',
        user_task_log_create_fail: 'Falha em gerar um registro de tarefa',
        user_task_not_exist: 'Falha na coleta, a missão não existe',
        user_task_not_completed: 'Falha na coleta, missão não concluída',
        user_task_received: 'Reclamação fracassada, recompensa reclamada',
        user_task_expired: 'Falha na coleta, a missão expirou',
        user_task_state_error: 'Falha na coleta, erro de status da missão',
        user_failed_to_modify_balance: 'Falha na coleta, falha na emenda do equilíbrio',
        user_task_receive_fail: 'Falha na coleta',
        user_insufficient_balance: 'Equilíbrio insuficiente',

        level_config_1: 'Erro de dados VIP',
        level_config_3: 'Você já está neste nível, não há necessidade de atualização',
        level_config_4: 'Falha na atualização, falha na dedução do equilíbrio',
        level_config_5: 'Falha na atualização, falha na mudança de informações do usuário',
        member_bank_1: 'Falha em ligar um cartão bancário',
        member_bank_2: 'O método de retirada já existe',

        order_save_1: 'Favor entrar em contato com a subcategoria de produto Configuração de Gerenciamento',
        order_save_2: 'Favor entrar em contato com o administrador se os dados estiverem incorretos',
        order_save_3: 'A atualização da Comissão falhou',
        order_save_5: 'O débito falhou',
        order_save_6: 'O pedido feito falhou',
        order_save_7: 'O número de pedidos atingiu o nível máximo de pedido atual, por favor, aumente o nível.',
        order_save_8: 'Existem pedidos pendentes.',
        order_save_9: 'O pedido expirou, entre em contato com o atendimento ao cliente para renová-lo.',
        order_save_10: 'O pedido foi congelado, entre em contato com o atendimento ao cliente para descongelar.',
        order_save_11: 'Se o saldo for insuficiente, a diferença precisará ser compensada.',

        order_detail_1: 'Favor entrar em contato com o administrador se os dados estiverem incorretos',
        order_detail_2: 'Não há níveis VIP de usuário suficientes',
        order_detail_3: 'Há muitas pessoas na fila, se você quiser continuar com a tarefa',
        order_detail_4: 'Montante máximo necessário para esta área',
        order_detail_5: 'Favor entrar em contato com a subcategoria de produto Configuração de Gerenciamento',
        order_detail_6: 'O status do pedido está incorreto!',

        user_buy_record_save_1: 'Por favor, não compre duas vezes',
        user_buy_record_save_2: 'Favor entrar em contato com o serviço de atendimento ao cliente se os dados estiverem incorretos.',
        user_buy_record_save_3: 'Favor entrar em contato com o administrador se houver um erro nos parâmetros',
        user_buy_record_save_4: 'Menos do que o limite mínimo, favor recomprar',
        user_buy_record_save_5: 'Limite máximo excedido, favor recomprar',
        user_buy_record_save_6: 'Não há nível VIP suficiente para os usuários',
        user_buy_record_save_7: 'Você tem um pedido inacabado, preencha-o primeiro',
        user_buy_record_save_8: 'O pedido não foi concluído e não pode ser retirado',
        
        
        web_pay_add_1: 'Este canal não está configurado',
        web_pay_add_2: 'Parâmetros errados',
        web_pay_add_3: 'Ordem de pagamento criada com sucesso',
        web_pay_add_4: 'Falha na criação de ordens de pagamento',

        web_pay_create_pay_1: 'Olá, como seu último pedido ainda não foi processado, por favor espere um minuto e envie-o novamente',
        web_pay_create_pay_2: 'Cartão da plataforma errada',
        web_pay_create_pay_3: 'O acesso está fechado, favor selecionar outro acesso',
        web_pay_create_pay_4: 'Montante enviado não dentro da faixa permitida',

        web_pay_create_proxy_1: 'Os dados da solicitação estão incorretos, o número do cartão de saque não é a pessoa ou não existe',
        web_pay_create_proxy_2: 'Olá, o cartão de conta está bloqueado, favor entrar em contato com o serviço de atendimento ao cliente',
        web_pay_create_proxy_3: 'Olá, como seu último pedido ainda não foi processado, por favor espere um minuto e envie-o novamente',
        web_pay_create_proxy_4: 'Olá, o número máximo de retiradas por dia é: {days}',
        web_pay_create_proxy_5: 'Olá, a quantidade mínima de retirada é {min}',
        web_pay_create_proxy_6: 'Olá, a quantidade máxima de retirada é {max}',
        web_pay_create_proxy_9: 'Retiradas bem sucedidas',
        web_pay_create_proxy_10: 'falhas nas retiradas',
        web_pay_create_proxy_11:'Senha de segurança incorreta; favor verificar novamente sua senha de segurança ou entrar em contato com o serviço de atendimento ao cliente on-line para modificá-la',
        web_pay_create_proxy_12:'Se a senha de segurança for inserida incorretamente por demasiadas vezes, o sistema irá congelar a conta. Favor entrar em contato com o serviço de atendimento ao cliente para descongelar a conta',
        web_pay_create_proxy_13:"A taxa de câmbio foi atualizada, por favor, atualize",
        
        login_expired:'O login expirou. Por favor, faça o login novamente.',
        login_network_error:'A rede é anormal. Verifique a conexão da rede.',
        user_sms_fail:'O código de verificação SMS é válido dentro de 2 minutos',

        user_experience_cannot: 'O saldo contém {experienceGold} dinheiro de teste e não pode ser usado para saqueThe balance contains {experienceGold} trial money and cannot be used for withdrawal',
        user_experience_cannot_up_vip: 'O saldo contém {experienceGold} dinheiro de teste e não pode ser usado para upgrade VIP',
        user_experience_cannot_ai: 'O saldo contém {experienceGold} fundos de teste e não pode ser usado para comprar fundos'
    }
};