export default {
    label: {
        language:'Bahasa',
        total: 'Total',
        count:'Menghitung',
        orderProfits: 'Keuntungan Pesanan',
        totalCommission: 'Total Komisi',
        walletBalance: 'Saldo dompet saya',
        Funds_accepted_in_trust:'Dana diterima dengan amanah',
        rechargeInstructions: 'Instruksi isi ulang',
        setTransPassword: 'Setel kata sandi transaksi',
        password: 'Kata sandi',
        confirmPassword: 'Konfirmasi sandi',
        changePassword: 'Silakan masukkan kata sandi baru Anda',
        oldPassword: 'password lama',
        newPassword: 'kata sandi baru',
        confirmPasswordLower: 'konfirmasi sandi',
        //use_info
        avatar: 'Avatar',
        nickname: 'Nama panggilan',
        myCellNo: 'Nomor ponsel saya',
        myBankId: 'Kartu bank saya',
        myWithdrawal: 'Metode penarikan saya',
        changeUserPassword: 'Manajemen kata sandi',
        withdrawPassword: 'Tarik kata sandi',
        shippingAddress: 'Alamat Pengiriman',
        //address
        realInfo: 'Menerima informasi identitas',
        actualName: 'Nama Asli',
        actualCellNo: 'Nomor telepon',
        addressInfo: 'Informasi alamat pengiriman',
        addressArea: 'Daerah',
        address: 'Alamat',
        //bankcard
        yourId: 'Identitas Anda',
        accountName: 'Nama akun',
        contactMobile: 'Hubungi telepon',
        yourAccountInfo: 'Informasi akun Anda',
        bank: 'Bank',
        accountNo: 'Nomor akun',
        //depository
        availableAssets: 'Aset yang tersedia',
        yesterdayEarn: "Penghasilan kemarin",
        cumulativeIncome: 'Penghasilan kumulatif',
        days: 'Hari',
        expectedProfits: 'Keuntungan yang Diharapkan',
        vipLevelRequired: 'Diperlukan tingkat VIP',
        fee: 'Biaya',
        feeAmount:'Jumlah biaya',
        actualToAccount:'Yang sebenarnya untuk diperhitungkan',
        min: 'min',
        max: 'Maks',
        time: 'Waktu',
        serialNumber: 'Nomor seri',
        orderNumber: 'jumlah order',
        amount: 'Jumlah',
        profits: 'Keuntungan',
        expireDate: 'Tanggal kadaluarsa',
        expectedReturn: 'Pengembalian yang Diharapkan',
        //grab
        commission: 'Komisi',
        vip: 'VIP',
        grabbedToday: 'Diambil Hari Ini',
        availableToday: 'Tersedia Hari Ini',
        commissionYesterday: 'Komisi kemarin',
        commissionsTotal: 'Jumlah komisi',
        commissionsToday: 'Komisi hari ini',
        vipLevelUpgrade: 'Peningkatan Tingkat VIP',
        automaticOrder: 'Pesanan otomatis',
        explanation: 'Penjelasan',
        orderRecord: ' Catatan Pesanan',
        return: 'Kembali',
        orderAmount:'jumlah pesanan',
        //recharge
        rechargeSuccess: 'Isi ulang sukses',
        //withdraw
        withdrawAmount: 'Jumlah penarikan',
        balance: 'Keseimbangan',
        tax: 'Pajak',
        afterTax: 'Setelah Pajak',
        contactEmail: 'Kontak Email',
        extraBonus: 'Bonus tambahan',
        selectPaymentChannel: 'Pilih saluran',
        teamReports:'laporan',

        methodType_1:'pix',
        methodType_2:'USDT-TRC20',
        methodType_3:'USDT-ERC20',
        methodType_4:'kartu bank',
        pixType: 'pix jenis',

        teamReportsTitle:'Ikhtisar laporan tim',
        teamReportsDetail:'Detail laporan tim',
        //team records
        chargeTotalAmount:'Isi ulang total',
        firstChargeNumber:'Total pengguna pengisian ulang pertama',
        orderAmountTotal:'Jumlah uang pesanan',
        payTotalAmount:'Jumlah penarikan',
        profitTotal:'Total keuntungan',
        registerNumber:'Jumlah register',
        subordinateNumber:'Jumlah bawahan',
        subordinateNumber1:'Jumlah anggota',
        teamBalance:'Total uang dari rekening tim',
        totalPeople: 'Total pengguna yang telah membayar pesanan',

        teamOrderRecords:'Catatan pesanan',

        teamAccountRecords:'Catatan akun',

        rechargeDetail:'Isi ulang',
        withdrawDetail:'penarikan',
        orderAndRebateDetail:'Memesan',

        agencyType:'Hirarki',
        LastLogin:'Login terakhir',
        myself:'Saya sendiri'

    },
    menu: {
        trustManagement: 'Manajemen Kepercayaan AI',
        information: 'Informasi',
        orderRecord: 'Catatan pesanan',
        accountDetails: 'Detail akun',
        rechargeRecord: 'Isi ulang catatan',
        withdrawRecord: 'Catatan penarikan',
        inviteFriends: 'Mengundang teman-teman',
        announcement: 'Pengumuman',
        about: 'Tentang kami',
        download: 'Unduh Aplikasi',
        recharge: 'Isi ulang',
        withdraw: 'Menarik',
        service: 'Melayani',
        agencyCenter:'Pusat agensi',
        agencyRecords:'Catatan agensi',
    },
    hits: {
        transPassword: `Harap ingat kata sandi, jika Anda lupa kata sandi, silakan hubungi layanan pelanggan.`,
        bindAccount: `Tip Keamanan: Harap periksa sebelum mengirimkan info rekening bank. Setiap
        pengguna hanya memiliki satu kesempatan gratis mengirimkan rekening bank. Kami menjamin
        untuk menjaga semua informasi rahasia. * * (Bila Anda ingin memodifikasi
        informasinya lagi, Anda harus membayar {fee})`,
        // bindAccountConfirm: `Modifying bank card information requires a fee of {fee}. Are you sure you want to modify it?`,
        bindAccountConfirm: `Silakan periksa kembali dan pastikan informasinya benar. Apakah Anda yakin akan mengirimkannya?`,
        minimumAmount: 'Jumlah Minimum  {amount}',
        maximumAmount: 'Jumlah maksimum {amount}',
        //grab
        grabTip1: 'Terburu-buru untuk pesanan',
        grabTip2: 'Menghubungkan ke pusat pesanan',
        grabTip3: 'Menunggu respons basis data',
        grabTip4: 'Menunggu server mengalokasikan pesanan',
        minimumRecharge: 'Isi ulang minimum {amount}',
        maximumRecharge: 'Isi ulang maksimum {amount}',
        recharge: `tip: Anda dapat menemukan semua catatan isi ulang di "Catatan Isi Ulang"`,
        rechargeOrderSubmitted: 'Harap tunggu hingga administrator sistem mengaudit pesanan.',
        rechargeBank: 'Transfer sejumlah uang tertentu ke akun berikut dan unggah tangkapan layar',
    },
    placeholder: {
        password: 'Silakan masukkan kata sandi',
        oldPassword: 'Silakan masukkan kata sandi lama Anda',
        oldPasswordLength: 'Kata sandi harus antara 4 dan 30 karakter',
        newPassword: 'Silakan masukkan kata sandi baru',
        newPasswordLength: 'Kata sandi harus antara 6 dan 30 karakter',
        confirmPassword: 'Silakan masukkan kata sandi lagi',
        passwordMath: `Dua input tidak cocok!`,
        realName: 'Silakan masukkan nama asli Anda',
        area: 'Silakan masukkan area',
        contactPhone: 'Silakan masukkan nomor kontak',
        validPhoneNumber: 'Silakan masukkan nomor ponsel yang valid',
        address: 'Silakan masukkan alamatnya',
        accountName: 'Silakan masukkan nama akun Anda',
        accountNo: 'Silakan masukkan nomor rekening',
        selectIFSC: 'Silakan pilih bank',
        transAmount: 'Silakan Masukkan Jumlah Transfer',
        rechargeAmount: 'Silakan masukkan jumlah Anda',
        selectPayChannel: 'Silakan pilih saluran pembayaran',
        requireIFSC: 'Silakan masukkan IFSC',
        fundPassword: 'silakan masukkan kata sandi penarikan Anda',
        fundCI: 'Por favor ingrese su CI',
        fundCI2: 'silakan masukkan nomor identifikasi Anda',
        withdrawAmount: 'Silakan masukkan jumlah penarikan',
        excessBalance: 'Saldo berlebih',
        bank: 'Silakan masukkan nama bank Anda',
        withdrawalType:'Silakan pilih metode penarikan',
        contactEmail: 'Silakan masukkan email kontak Anda',
    },
    agencyCenter:{
        subordinatesManagement:'manajemen bawahan',
        rechargeROT:'isi ulang catatan tim',
        withdrawROT:'menarik catatan tim',
        orderROT:'memesan catatan tim',
        accountROT:'catatan akun tim',
        TeamRO:'Ikhtisar laporan tim'
    }
};