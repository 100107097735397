export default {
    label: {
        totalMembers: 'Total ukuran tim',
        totalCommission: 'Total Komisi Tim',
        totalRecharge: 'Isi ulang total',
        totalWithdraw: 'Penarikan total',
        commission: 'Komisi',
        members: 'Anggota',
        bonus: 'Bonus',
        expireIn: 'Kedaluwarsa dalam',
        days: 'hari',
        cellNo: 'Nomor ponsel',
        registrationDate: 'Tanggal registrasi',
        balance:'keseimbangan',
        samedaySubcCmmission:'Sub-komisi di hari yang sama'
    },
};