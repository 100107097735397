export default {
    placeholder: {
        mobile: 'Mobile phone number',
        verificationCode: 'Verification code from sms',
        password: 'Password',
        confirmPassword: 'Password again',
        inviteCode: 'Invite code',
        nickname: 'Nickname',
        lineId: 'Line Account',
    },
    signup: {
        smsSent: 'Identify code has been sent to your phone',
        smsFailed: 'Something wrong while sending SMS, please try later',
        terms: {
            pre: 'By clicking Sign Up, you agree to our',
            link: 'Terms,Data Policy and Cookies Policy.',
            post: 'You might receive SMS notifications from us and can opt out any time',
        },
    },
};