export default {
    inviteFriends: 'Convide amigos',
    message: 'mensagem',
    availableAssets: 'Recursos disponíveis',
    yesterdayEarnings: `Ontem`,
    cumulativeIncome: `Cumulativo`,
    todayEarnings: `Hoje`,
    recharge: 'Recarrega',
    withdraw: 'Retirar',
    aboutUS: 'Sobre nós',
    whyUS: 'Por que nós',
    howToEarn: 'Como ganhar',
    howToShare: 'Como compartilhar',
    missionList: 'Lista de missões',
    commission: 'Comissão',
    BeginnersGuide: 'Guia do iniciante',
    experienceGold: 'Experiência de ouro',
    ChooseYourAvatar:'Escolha seu avatar',
    integral: 'integrante'
};