export default {
    placeholder: {
        mobile: 'Introduce el número de móvil',
        verificationCode: 'Código de verificación de sms',
        password: 'Contraseña de inicio de sesión',
        confirmPassword: 'Confirme su contraseña',
        inviteCode: 'Introduzca el Código de invitación',
        nickname: 'Introduzca el apodo',
        lineId: 'Cuenta de línea',
    },
    signup: {
        smsSent: 'El código de identificación ha sido enviado a su teléfono',
        smsFailed: 'Algo salió mal al enviar SMS, por favor intente más tarde',
        terms: {
            pre: 'Al hacer clic en Registrarse, usted acepta nuestros',
            link: 'Términos, Política de Datos y Política de Cookies',
            post: 'Es posible que reciba notificaciones por SMS de nosotros y puede optar por no participar en cualquier momento',
        },
    },
};