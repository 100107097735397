export default {
    label: {
        applyForHelp: 'Solicite ajuda',
        iWantHelp: 'Eu quero ajudar',
        whatIsThis: "O que é isso",
        latestNews: 'Últimas notícias',
        more: 'Mais',
        donated: 'doado',
        to: 'Para',
        time: 'Tempo',
        process: 'Processo',
        process1: 'enviar inscrição',
        process2: 'Auditoria da plataforma',
        process3: 'Comece a doar',
        nickname: 'Apelido',
        mobile: 'Número de telefone',
        patientName: 'Nome do paciente',
        description: 'Descrição',
        donationAmount: 'Valor da doação',
        says: 'Diz',
        thankYou: 'Obrigado por me mostrar amor',
        amount: 'Montante',
        comment: 'Comente',
        target: 'Alvo',
        raised: 'Criado',
        aims: 'Mira',
        helpTimes: 'Tempos de ajuda',
        recipients: 'Destinatários',
        nameCertified: 'Certificado de nome real',
        accountsReceivable: 'Contas recebíveis:',
        idCardapproved: 'Cartão de identificação aprovado',
        proofApproved: 'Prova de relacionamento aprovada',
        verificationPassed: 'O recebimento da verificação do cartão bancário foi aprovado',
        promise: 'Os promotores prometem',
        platformStatement: 'Declaração da plataforma',
        expandAll: 'Expandir todos',
        closeAll: 'Recolher todos',
        askFriend: 'Peça ajuda ao seu amigo',
        applyForDonation: 'Solicitar doação',
        backgroundStory: 'História de fundo',
        evidence: 'Evidência',
        witnessList: 'Lista de testemunhas',
        progress: 'Progresso',
        postedGrogress: 'progresso postado',
        Malltext: 'Se precisar de ajuda, faça login em nossa plataforma',
        iWantHelptext: 'Dê o seu amor e faça o mundo melhor!',
    },
    hits: {
        application: `Após o envio do pedido de doação, a equipe da plataforma entrará em contato por telefone.`,
        requireAmount: 'Por favor, preencha o valor da meta de doação',
        comments1: `Pequenos atos de bondade se fundem em um oceano de amor, e espero que você fique bem`,
        comments2: `Que você se recupere.`,
        recipientDetail1: 'Proteção de capital de alto nível, uma plataforma confiável para usuários globais',
        joinPlatform: 'Se precisar de ajuda, junte-se à nossa plataforma',
        dedication: 'Dedicação de amor',
        referral: 'Solicitar uma indicação pode acelerar as doações',
        requestHelp: 'Iniciar um pedido de ajuda',
    },
    placeholder: {
        requireWhatAppID: 'Insira o id da linha',
        whatAppIDLength: 'O comprimento do ID do aplicativo deve ser de 1 a 30',
        requirePatientName: 'Insira o nome do paciente',
        patientNameLength: 'O comprimento do nome do paciente deve ser de 1 a 50',
        requireDesc: 'Por favor, insira a descrição',
        descLength: 'O comprimento da descrição deve ser de 10 a 1000',
        requireAmount: 'Insira o valor necessário',
    },
    message: {
        notAllowed: 'Doação para si mesmo não é permitido',
    },
};