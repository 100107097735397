export default{
    MySubordinates :'astlarım',
    listTheSubordinates:'astları listele',
    goReports:'git raporları',
    virtualCurrency :'sanal para birimi',
    rechageText1:'Belirtilen tutarı aşağıdaki hesaba aktarın.',
    rechageText2:'(*Şarj etmeden önce lütfen müşteri hizmetleri ile iletişime geçin ve bu kanalın riskini almalısınız.)',
    coinType:'para tipi',
    coinAmount:'para miktarı',
    channel:'kanal',
    rate:'oran',
    remark:'açıklama',
    maxAmount:'maksimum miktar',
    minAmount:'minimum miktar',
    bankPay:'banka kartı ödeme',
    bankName:'banka adı',
    BeneficiaryBank:'Yararlanıcı Bankası',
    holderName:'Kart sahibinin gerçek adı',
    rememberPwdText:`Lütfen şifreyi hatırlayın, şifreyi unutursanız lütfen müşteri hizmetleri ile iletişime geçin.`,
    versionInfo:'Versiyon bilgisi',
    instructions:'Talimatlar',
    minLength:`Minimum uzunluk {min}'dir.`,
    maxLength:'Maksimum uzunluk {max}.',
    allSub:'Tüm astlar',
    directSub:'Doğrudan astlar',
    PIX:'Lütfen PIX hesabını girin',
    realName:'Gerçek ad'
}