import {
    createApp
} from 'vue';
import api from '@/api'
import store from './vuex/store'
import 'ant-design-vue/dist/antd.css';
import {
    Form,
    Input,
    Modal,
    Select,
    Spin,
    Button,
    Carousel,
    Collapse,
    Radio,
    message,
    Checkbox,
    DatePicker,
    Card,
    Drawer,
    Table,
    // Layout,
    Menu
    // CollapsePannel,
} from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import './Content/iconfont/iconfont.css';
import './Content/System_Ui.css';
import './Content/System_Ui_Skin.css';
import {
    i18n,
} from './lang';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import moment from 'moment-timezone';
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
import { switchZone } from '@/utils/formatter'
import {CURRENCY_SYMBOL}from '@/utils/constant'
// import VConsole from 'vconsole';
// new VConsole();
NProgress.configure({
    showSpinner: false
});
NProgress.start();
api.sysDataDictionaryWb()
    .then((res) => {
        moment.tz.setDefault(switchZone(sysDataDictionaryWbObj.value?.TIMEZONE?.dataContent));
        document.title=sysDataDictionaryWbObj.value?.WZMC?.dataContent
        document.getElementById('icon').href=
        sysDataDictionaryWbObj.value?.TXHQDZ?.dataContent+
        sysDataDictionaryWbObj.value?.SJDIOC?.dataContent
        let NATION = sysDataDictionaryWbObj.value?.NATION?.dataContent
        switch (NATION) {
            case 'NIGERIA':
                CURRENCY_SYMBOL.value = '₦'
                break;
            case 'BRAZIL':
                CURRENCY_SYMBOL.value = 'R$'
                break;
            case 'MEXICO':
                CURRENCY_SYMBOL.value = '$'
                break;  
            case 'INDONESIA':
                CURRENCY_SYMBOL.value = 'RP'
                break; 
            case 'TURKEY':
                CURRENCY_SYMBOL.value = 'TL'
                break;              
            default:

                break;
        }
        
        
        if(res && res.data && res.data.success){
            createApp(App)
            .use(router)
            .use(Form)
            .use(Input)
            .use(Modal)
            .use(Spin)
            .use(Button)
            .use(Carousel)
            .use(Collapse)
            .use(Select)
            .use(Radio)
            .use(Checkbox)
            .use(DatePicker)
            .use(Card)
            .use(Drawer)
            // .use(Layout)
            .use(Menu)
            .use(Table)
            .use(i18n)
            .use(store)
            .mount('#app');
        }
        NProgress.done();
    })
    .catch((err) => {
        console.log(err)
        message.error({
            content: i18n.global.t('error.server.startupQuery'),
            duration: 0,
            key: 'checkStatus',
        });
    });