import axios from 'axios';
import {
    message
} from 'ant-design-vue';
import mybus from '@/utils/mitt'
// import { checkStatus } from '@/checkStatus'
import {
    user
} from '@/hooks/user';
import {
    userDetail
} from '@/hooks/userDetail';
import {
    inviteCode
} from '@/hooks/inviteCode';
import {
    lunContent,
    tanContent
} from '@/hooks/announcement'
import {
    sysDataDictionaryWbObj
} from '@/hooks/sysDataDictionaryWbObj'
import aes from '@/utils/aes'

import { formatServerMsg } from '@/utils/formatter'
axios.defaults.baseURL = "/"
axios.interceptors.request.use(
    config => {
        const CancelToken = axios.CancelToken;
        if (user && user.value && user.value.curToken) {
            config.headers.token = user.value.curToken
            return config
        } else {
            let notNeedToken = ['/web/user/sendSms', '/web/user/register', '/web/user/login', '/web/user/register', '/web/user/forgotPassword', '/web/sysDataDictionaryWb/list']
            if (notNeedToken.includes(config.url)) {
                return config
            }
            mybus.emit('GOLOGIN')
            return {
                ...config,
                cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request'))
            };
        }
    },
    err => {
        return Promise.reject(err)
    }
)
axios.interceptors.response.use((res) => {
    if (res && res.data) {
        if (!res.data.success) {
            let notNeedTost = ['/web/content/tanContent', '/web/content/lunContent']
            if (!(notNeedTost.includes(res.config.url))) {
                setTimeout(() => {
                    if('' + res.data.retCode === '100003' || res.config.url==='/web/user/heartbeat'){
                        return
                    }
                    if(res.data.message == 'web_pay_create_proxy_12'){
                        //有ws的推送提示
                        mybus.emit('GOLOGIN',false)
                        return
                    }
                    if(res.config.url === '/web/payment/order/online/pay/create'){
                        message.error(res.data.message)
                    }else{
                        message.error(formatServerMsg(res.data.message))
                    }
                })
            }
            if ('' + res.data.retCode === '100003') {
                mybus.emit('GOLOGIN')
            }
        }
    }
    return res
}, (err) => {
    // checkStatus(res.status)
    message.error('' + err)
    return Promise.reject(err)
})

export default {
    //系统参数
    sysDataDictionaryWb: async function () {
        let res = await axios.get('/web/sysDataDictionaryWb/list')
        if (res.data.success) {
            let obj = {}
            let list = res.data.data
            if (list) {
                list.forEach(item => {
                    if (+item.dataOnOff === 1) {
                        obj[item.dataCode] = item
                    }
                });
            }
            sysDataDictionaryWbObj.value = obj
        }
        return res
    },
    //登录
    login: async function (form) {
        form.passWord=aes.encrypt(form.passWord)
        let res = await axios.post('/web/user/login', form)
        if (res.data.success) {
            user.value = res.data.data
            sessionStorage.setItem('user', JSON.stringify(user.value))
        }
        return res;
    },
    //注册
    regist: async function (form) {
        form.passWord=aes.encrypt(form.passWord)
        let res = await axios.post('/web/user/register', form)
        if (res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    //获取短信验证码
    sendSms: async function (userAccount) {
        let res = await axios({
            method: 'post',
            url: '/web/user/sendSms',
            params: {
                userAccount
            }
        })
        if (res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    //忘记密码（重置密码）
    forgotPassword: async function (form) {
        form.passWord=aes.encrypt(form.passWord)
        let res = await axios.post('/web/user/forgotPassword', form)
        if (res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    //用户登出
    logout: async function () {
        let res = await axios.get('/web/user/login/out')
        if (res && res.data && res.data.success) {
            console.log('logout')
        }
        return res
    },
    //查询会员详情
    getUserDetails: async function () {
        let res = await axios.get('/web/user/getUserDetails')
        if (res && res.data && res.data.success) {
            userDetail.value = res.data.data
            sessionStorage.setItem('userDetail', JSON.stringify(userDetail.value))
        }
        return res
    },
    //查询会员的提款方式
    getUserWithdrawal: async function () {
        let res = await axios.post('/web/withdrawalMethod/userList')
        if (res && res.data && res.data.success) {
            sessionStorage.setItem('userWithdrawal', JSON.stringify(res.data.data))
        }
        return res
    },
    //查询所有提款方式
    getAllWithdrawal: async function () {
        let res = await axios.post('/web/withdrawalMethod/list')
        return res
    },
    
    //修改用户头像
    updateAvatar: async function (avatarAddress) {
        let res = await axios.post('/web/user/updateAvatar', {
            avatarAddress
        })
        if (res && res.data && res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    //获取推广二维码
    getInvitationCode: async function () {
        let res = await axios.get('/web/invitationCode/getInvitationCode')
        if (res && res.data && res.data.success) {
            inviteCode.value = res.data.data
        }
        return res
    },
    //客户轮播公告
    lunContent: async function () {
        let res = await axios.get('/web/content/lunContent')
        if (res && res.data && res.data.success) {
            lunContent.value = res.data.data
        }
        return res
    },
    //客户弹窗公告
    tanContent: async function () {
        let res = await axios.get('/web/content/tanContent')
        if (res && res.data && res.data.success) {
            tanContent.value = res.data.data
        }
        return res
    },
    //获取基础银行
    sysPlatformBank: async function () {
        let res = await axios.get('/web/sysPlatformBank/getList')
        return res
        // 返回
        // [
        //     {
        //         "bankCode": "",
        //         "bankName": "",
        //         "createBy": 0,
        //         "createDate": "",
        //         "delFlag": "",
        //         "enableStatus": "",
        //         "iconAddress": "",
        //         "id": 0,
        //         "lastUpdatedBy": 0,
        //         "lastUpdatedDate": "",
        //         "maxMoney": 0,
        //         "memberBankCode": "",
        //         "minMoney": 0
        //     }
        // ]
    },
    //获取银行卡
    getBankCardList: async function () {
        let res = await axios.get('/web/memberBank/getList')
        return res
        // 返回
        // [{
        // 	"bankCode": "",
        // 	"bankCord": "",
        // 	"bankName": "",
        // 	"contactEmail": "",
        // 	"contactPhone": "",
        // 	"enableStatus": 0,
        // 	"id": 0,
        // 	"isLocking": 0,
        // 	"openAccountAddress": "",
        // 	"openAccountName": ""
        // }]
    },
    //绑定银行卡
    bindBankCard: async function (form) {
        let res = await axios.post('/web/memberBank/save', form)
        if (res && res.data && res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
        //参数
        // {
        //     "bankCode": "",
        //     "bankCord": "",
        //     "bankName": "",
        //     "contactEmail": "",
        //     "contactPhone": "",
        //     "openAccountName": ""
        // }
    },
    // 获取入款通道
    getPayChannelList: async function () {
        let res = await axios.get('/web/PayChannel/getPayChannelList')
        return res
        // {
        //     "localPayChannelPoList": [
        //         {
        //             "accountName": "",
        //             "accountNumber": "",
        //             "bankBranch": "",
        //             "bankName": "",
        //             "icon": "",
        //             "id": 0,
        //             "maxMoney": 0,
        //             "minMoney": 0,
        //             "payName": ""
        //         }
        //     ]
        // }
    },
    //本地支付
    localPay: async function (form) {
        let res = await axios.post('/web/payment/order/local/pay/create', form)
        return res
        // 内页有其他提示

        //参数
        // {
        //     "accountNumber": "",
        //     "amount": 0,
        //     "bankName": "",
        //     "channelId": 0,
        //     "payImg": "",
        //     "realName": ""
        // }
    },
    //设置交易密码
    userDebitPassword: async function (form) {
        form.userDebitPassword=aes.encrypt(form.userDebitPassword)
        let res = await axios.post('/web/user/userDebitPassword', form)
        if (res && res.data && res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
        //参数
        // {
        //     "invitationCode": "",
        //     "passWord": "",
        //     "smsCode": "",
        //     "userAccount": "",
        //     "userDebitPassword": "",
        //     "userNick": ""
        // }
    },
    //提现
    withdrawal: async function (form) {
        form.password=aes.encrypt(form.password)
        let res = await axios.post('/web/payment/order/proxy/pay/create', form)
        return res
        //内页有其他提示

        //参数
        // {
        //     "amount": 0,
        //     "myBankId": 0,
        //     "password": ""
        // }
    },

    //提现到余额
    withdrawalCommission: async function (form) {
        let res = await axios.post('/web/derTaskDetails/getCommission', form)
        return res
        //内页有其他提示

        //参数
        // {
        //     "amount": 0,
        //     "myBankId": 0,
        //     "password": ""
        // }
    },

    //点击申请订单
    webOrderTask: async function () {
        let res = await axios.post('/web/orderTask/orderTask')
        return res
    },
    //获取订单任务
    webDerTaskDetails: async function () {
        let res = await axios.post('/web/derTaskDetails/list')
        return res
    },
    //完成订单任务支付
    webDerTaskDetailsAdd: async function (form) {
        let res = await axios.post('/web/derTaskDetails/add',form)
        return res
    },

    //获取滚屏数据
    webOrderTaskRecords: async function () {
        let res = await axios.get('/web/orderTask/orderTaskRecords')
        return res
    },
    //获取滚屏数据--首页
    webHomeRecords: async function () {
        let res = await axios.post('/web/sysDataDictionaryWb/getHomeConfigList')
        return res
    },

    //商品大类
    productList: async function () {
        let res = await axios.post('/web/order/productList', {
            current: 1,
            size: 500
        })
        return res

        //返回
        // [
        //     {
        //       "name": "办公区域",
        //       "id": 1,
        //       "amount": 0.02,
        //       "level": 1,
        //       "img": "/doc.html",
        //       "largestAmount": 500
        //     },
        //     {
        //       "name": "服装5000专区",
        //       "id": 2,
        //       "amount": 0.56,
        //       "level": 2,
        //       "img": "/doc.html",
        //       "largestAmount": 5000
        //     }
        //   ]
    },

    // 订单（金额佣金统计）
    orderTotal: async function (form) {
        let res = await axios.post('/web/order/orderTotal', form)
        return res
        // 参数
        // {
        //     productId:1
        // }
        //返回
        // availableToday: null     今日有售
        // balanceTotal: 6666545    总资产
        // commissionToday: null    今日佣金
        // commissionTotal: null    总佣金
        // commissionYesterday: null   昨日佣金
        // productManageVo: null     产品大类专区
        // todayTo: 0      今天抢到的数量
    },

    //下订单明细
    orderDetail: async function (form) {
        console.log(form)
        let res = await axios.post('/web/order/detail', form)
        return res

        //返回
        // createDate: "2021-12-31 00:29:38"
        // id: null
        // img: "/ea/19324/"
        // orderAmount: 495.3
        // orderAmountTotal: 12870
        // orderCnt: 26
        // orderNo: "F21123100123884936864"
        // productName: null
        // productSubsetId: 1
        // productSubsetName: "毛笔"
        // profit: 9.906
        // userName: null
    },
    orderSave: async function (form) {
        console.log(form)
        let res = await axios.post('/web/order/save', form)
        if (res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    // 贵宾等级列表
    levelConfigGetAll: async function () {
        let res = await axios.get('/web/levelConfig/getAll')
        return res

        //返回 [{
        // "dayOrderData": 0,
        // "id": 0,
        // "sellPrice": 0,
        // "userRank": "",
        // "userTitle": ""
        // }]

    },
    // 贵宾升级
    vipUpgrade: async function (form) {
        let res = await axios.get('/web/levelConfig/vipUpgrade', {
            params: form
        })
        if (res && res.data && res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
    },
    // 自动下单列表
    orderAiList: async function () {
        let res = await axios.get('/web/order/orderConfigList')
        // 返回
        // [{
        //     days: 1,
        //     fee: 0.55,
        //     id: null,
        //     level: 1,
        //     maxAmount: 500,
        //     minAmount: 1,
        //     minEarnings:0.1
        //     maxEarnings:0.2
        // }]
        return res
    },
    //购买自动下单天数
    // /web/order/userBuyRecorSave
    userBuyRecorSave: async function (form) {
        let res = await axios.post('/web/order/userBuyRecorSave', form)
        if (res && res.data && res.data.success) {
            message.info(formatServerMsg(res.data.message))
        }
        return res
        //参数
        // {
        //     amount:1,
        //     orderConfigId:2
        // }
    },
    // 购买自动下单天数记录查询
    userBuyRecordList: async function (form) {
        let res = await axios.post('/web/order/userBuyRecordList', form)
        return res
        //参数
        // {
        //     current:1,
        //     size:10
        // }

        //返回
        // {
        //     ...
        //     [
        // amount: 501
        // createDate: "2021-12-31 23:00:48"
        // days: 1
        // delFlag: "N"
        // endDate: null
        // fee: 0.55
        // id: 3
        // initialAmount: 501
        // level: 1
        // maxEarnings: 0
        // minEarnings: 0
        // orderConfigId: 1
        // userAccount: "+86 3333333333"
        // userId: 1019
        //     ]
        // }
    },

    // 订单记录 
    orderList: async function (form) {
        let res = await axios.post('/web/order/list', form)
        return res
        //返回
        // {
        //     ...
        //     [      
        //     // createDate: "2021-12-31 23:49:18"
        //     // id: 11
        //     // img: "/ea/19324/"
        //     // orderAmount: 505.206
        //     // orderAmountTotal: null
        //     // orderCnt: null
        //     // orderNo: "G211231231204020425984"
        //     // productName: "办公区域"
        //     // productSubsetId: null
        //     // productSubsetName: "毛笔"
        //     // profit: 9.906
        //     // unitPrice: null
        //     // userName: "+86 3333333333"]
        // }

    },
    // 交易记录
    amountLog: async function (form) {
        let res = await axios.post('/web/payment/order/amount/log', form)
        return res
        // 参数
        // {
        //     type:1 "用户加款", 2 "用户减款",3,"贵宾升级",4"用户下单",5"下单返佣",6"AI信用下单",7"AI信用返佣"
        //     startTime:
        //     endTime:
        //     current:
        //     size:
        // }

        //返回
        // [
        //     ...
        //             // {
        // //     amount: 120
        // //     atfAmount: 6664260.34
        // //     befAmount: 6664140.34
        // //     createBy: 1012
        // //     createDate: "2022-01-01 19:45:52"
        // //     id: 87
        // //     lastUpdatedBy: null
        // //     lastUpdatedDate: null
        // //     logType: 5
        // //     operator: 1012
        // //     remark: "下单返佣"
        // //     supUserId: 1000
        // //     userAccount: "+86 7777777777"
        // //     userId: 1012
        // //     userType: 1
        // // }
        // ]

    },
    //充值记录
    payLog: async function (form) {
        let res = await axios.post('/web/payment/order/pay/order', form)
        return res
    },
    //提现记录
    proxyOrder: async function (form) {
        let res = await axios.post('/web/payment/order/proxy/order', form)
        return res
    },
    // 用户端--修改用户密码需要登录
    updatePwd: async function (form) {
        form.newPwd=aes.encrypt(form.newPwd)
        form.oldPwd=aes.encrypt(form.oldPwd)
        let res = await axios.post('/web/user/updatePwd', form)
        return res
        //参数
        // {
        //     "newPwd": "",
        //     "oldPwd": "",
        // }
    },

    // 站内信
    userMessage: async function (form){
        let res = await axios.post('/web/user/message',form)
        return res
    },
    // 团队报表
    teamReport: async function (){
        let res = await axios.get('/web/user/teamReport')
        return res
    },
    // 各级别团队成员统计
    teamMemberCount: async function (){
        let res = await axios.get('/web/user/teamMemberCount')
        return res
    },
    // 团队成员
    teamMember: async function (form){
        let res = await axios.post('/web/user/teamMember',form)
        return res
        // {
        //     levelName:'VIP1'
        //     current:1,
        //     size:10
        // }
    },
    // 任务进度
    taskSchedule: async function (form){
        let res = await axios.post('/web/user/taskSchedule',form)
        return res
    },
    //领取奖励
    receiveTaskReward:async function (form){
        let res = await axios.get('/web/user/receiveTaskReward',{
            params:form
        })
        return res
    },
    // 心跳用于更新账户在线状态
    heartbeat:async function (){
       let res = axios.get('/web/user/heartbeat')
       return res
    },
    // 用户地址列表
    userShippingAddress:async function (){
        let res = axios.post('/web/userShippingAddress/list')
        return res
    },

    // 编辑用户地址
    userShippingAddressUpdate:async function (form){
        let res = axios.post('/web/userShippingAddress/update',form)
        return res
    },

    // 用户地址增加
    userShippingAddressAdd:async function (form){
        let res = axios.post('/web/userShippingAddress/add',form)
        return res
    },
    // AI 信托数据
    incomeBreakdown:async function (){
        let res = axios.post('/web/incomeBreakdown/detail')
        return res
    },
    // 线上支付
    onlinePayCreate:async function(form) {
        let res = axios.get('/web/payment/order/online/pay/create',{
            params:form
        })
        return res
    },
    // 绑卡可选银行卡
    bankList:async function(){
        let res = axios.post('/web/payment/order/bank/list')
        return res

    },
    // 团队报表按时间查询
    depositAndWithdrawal:async function(form){
        let res = axios.post('/web/agencyCenter/depositAndWithdrawal',form)
        return res
    },
    // 代理中心报表
    angencyTeamReport:async function(form){
        let res = axios.post('/web/agencyCenter/teamReport',form)
        return res
    },
    // 下级下单记录
    agencyOrderRecord:async function(form){
        let res = axios.post('/web/agencyCenter/orderRecord',form)
        return res
    },

    //下级交易记录
    agencyTransactionRecord:async function(form){
        let res = axios.post('/web/agencyCenter/transactionRecord',form)
        return res
    },
    //下级充值记录
    agencyRechargeRrecord:async function(form){
        let res = axios.post('/web/agencyCenter/rechargeRrecord',form)
        return res
    },
    //下级提现记录
    agencyWithdrawalRecords:async function(form){
        let res = axios.post('/web/agencyCenter/withdrawalRecords',form)
        return res
    },
    //下级会员
    getMemberManagement:async function(form){
        let res = axios.post('/web/user/getMemberManagement',form)
        return res
    },
    //下级会员盈利
    agencySubReport:async function(form){
        let res = axios.post('/web/agencyCenter/subReport',form)
        return res
    },
    //虚拟币支付
    coinPayCreate:async function(form){
        let res = axios.post('/web/payment/order/coin/pay/create',form)
        return res
    },

    //查询可下单数和已完成数
    getOrdersPlaced:async function(form){
        let res = axios.post('/web/orderList/ordersPlaced',form)
        return res
    },
    //新增订单
    createOrder:async function(form){
        let res = axios.post('/web/orderList/createOrder',form)
        return res
    },
    //查询订单
    queryOrder: async function (form) {
        let res = await axios.post('/web/orderList/queryOrder', form)
        return res
    },

    //提交支付
    submitOrder: async function (form) {
        let res = await axios.get('/web/orderList/submit?id='+form.id)
        return res
    },
    
    
}