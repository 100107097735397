export default {
    menu: {
        index: 'MOA Cares',
        home: 'Início',
        team: 'Equipe',
        service: 'Serviço',
        my: 'Meu',
        grabOrder:'fazer dinheiro',
        grabOrderList2:'Tarefa'
    },
    title: {
        download: 'baixar',
        termOfService: 'Termo de Serviço',
        maintenance: 'Sistema em manutenção',
        registration: 'Registro',
        verificationCode: 'Código de verificação',
        inviteFriends: 'Convidar amigos',
        forgotPassword: 'Esqueci minha senha',
        team: 'Relatório da equipe',
        teamDesc: 'Visão geral de sua equipe subordinada',
        inviteTasks: 'Convidar Tarefas',
        service: 'Atendimento ao cliente',
        siteMessage: 'Mensagem',
        recharge: 'Recarga',
        transPassword: 'Gerenciamento de senhas de transação',
        password: 'Gerenciamento de senhas',
        information: 'Informações',
        withdrawal: 'Método de retirada',
        address: 'Endereço de entrega',
        avatar: 'Configurações Avatar',
        bankcard: 'Conta vinculante',
        streamRecords: 'Detalhes da conta',
        announcement: 'Anúncio',
        about: 'Sobre nós',
        aboutCompany: 'Perfil da empresa',
        aboutBusiness: 'Contrato de serviço',
        aboutTeam: 'Descrição do produto',
        careApplication: 'Solicite ajuda',
        careDonation: 'Doações',
        careRecipientDetail: 'Detalhes',
        careRecipientList: 'Eu quero ajudar',
        depository: 'Gerenciamento de confiança de IA',
        depositoryRecords: 'Registros de confiança AI',
        grab: 'Agarramento de pedidos',
        grabRecords: ' Registro do pedido',
        rechargeRecords: 'Recarga de registros',
        rechargeResult: 'Resultado do Recarregamento',
        withdraw: 'Retirada',
        withdraw1: 'Retirada',
        withdraw2: 'Retirada',
        withdrawCommission: 'Comissão de saque',
        withdrawRecords: 'Registros de saque',
        prompt: 'Pronto',
        guessYouLike: 'Acho que você gosta de',
        comment: 'Comentário',
        whatsApp: 'Linha',
        rechargeOrderSubmitted: 'Ordem de recarga criada',
        rechargeWithBankcard: 'Pagamento via banco',
        agencyCenter:'Centro de agências',
        agencyRecords:'Registros da Agência',
        subordinatesManagement:'Gestão de subordinados',
        subordinatesProfit:'Lucro subordinado',

        cardNumber:'Número do cartão',
        cardNumber2:'Número do cartão',
        actualName:'Nome real',

        withdrawalAddress:'Endereço de retirada',
        
        Account:'Conta PIX',
        Name:'Nome',
        Monto:'Quantia',
        Calbe:'Calbe',
        Nombre:'Nome',

        Task:'Tarefa',
        trade:'Transação...',
        freeze:'Congeladas',
        completed:'Completado',
        Paid:'Pago',
        Excuting:'Executar pedido',
        ExcutingEnd:'Executar pedido',
        grabOrderDetail:'Lista de Pedidos',
        payNow:'pagar imediatamente'  ,
        pendingTransaction:'Transação pendente',
        paySuccess:'pagamento bem sucedido',
        orderMatching:'Correspondência de pedido...',
        OrderNow: 'peça agora',
        orderCount: 'Número de tarefas',
        orderCountShow:'Completed order/Total order',
        orderReturnBenJin:'Principal a ser devolvido',
        orderReturnYongJin:'Comissão a ser reembolsada',
        orserSuccess:'pedido com sucesso',
        starPeople:'Hall da Fama',
        priceOne:'Preço da mercadoria:',
        commissionOne:'Comissão de commodities:',
        officialGuarantees: 'garantias oficiais',
        todayIncome: 'renda de hoje',
        orderStatus: 'status do pedido'
    },
    placeholder:{
        searchPhone:'Você pode consultar pelo número do telefone celular.',
        searchPhone1:'você pode consultar por nome de conta.',
        coinAmount:'Por favor, digite o número de moedas virtuais',
        remark:'Por favor, digite a observação '
    },
    button: {
        login: 'Conecte-se',
        logout: 'Sair',
        signup: 'Inscrever-se',
        register: 'Registro',
        forgotPassword: 'Esqueceu a senha',
        getVerificationCode: `Código de Verificação`,
        coolDown: 'esfriar {second}s',
        copy: 'cópia de',
        confirm: 'confirme',
        saveInformation: 'Salvar informações',
        confirmModify: 'Confirmar modificação',
        transfer: 'Transferir',
        withdraw: 'Retirar imediatamente',
        withdrawAll: 'Retirar tudo',
        addWithdrawal: 'Adicionar retirada',        
        donate: 'DOE agora',
        donate2: 'Doar',
        submit: 'Enviar',
        card: 'Cartão',
        account: 'Conta',
        buyNow: 'Compre Agora',
        addToCart: 'Adicionar ao Carrinho',
        backToHomepage: 'Voltar à página inicial',
        setNow: 'Configurar agora',
        goBack: 'Volte',
        upload: 'Envio',
        search: 'procurar',
        cancel:'cancelar',
        ok:'OK'
    },
    message: {
        unopen: 'ainda não abriu! {msg}',
        loginInvalid: 'conta ou senha inválida',
        mobileInvalid: 'por favor, insira um número de celular válido',
        accountNotExists: 'conta não existe',
        bindingAddressFailed: 'Falha no endereço de entrega de vinculação',
        changeAvatarError: 'Falha nas configurações do avatar',
        selectAvatar: 'Por favor selecione uma imagem',
        bindAccountSuccess: 'Vinculação de conta bem-sucedida',
        bindAccountFailed: 'Falha na vinculação da conta',
        transferSuccess: 'transferência bem sucedida',
        vipUpgradeSuccessful: 'Atualização vip bem-sucedida',
        vipUpgradeFailed: 'Falha na atualização do VIP',
        noNeedUpgrade: 'Não há necessidade de atualizar novamente',
        automaticOrderSuccess: 'Pedido automático bem-sucedido',
        automaticOrderFailed: 'Automatic order failed',
        notSupportBank: 'Este banco não é suportado pelo sistema, altere para outro banco',
        withdrawSuccess: 'Retirada bem-sucedida',
        withdrawFailed: 'Falha na retirada',
        withdrawLimitTimes: 'Limite de horários de chegada',
        donateSuccess: 'doar com sucesso',
        donateFailed: 'onate falhou',
        alreadyApplied: 'Você já se candidatou',
        noRecords: 'eu não me lembro',
        bindingAddressSuccessful: 'Endereço de entrega de vinculação bem-sucedido',
        avatarSettingsSuccessful: 'Configurações de avatar bem-sucedidas',
        passwordModifiedSuccessful: 'Senha modificada com sucesso',
        passwordModifiedFailed: 'Falha na modificação da senha',
        transactionModifiedSuccessful: 'Senha da transação bem-sucedida',
        transactionModifiedFailed: 'Falha na senha da transação',
        bankCardRequire: 'Por favor, preencha o cartão bancário primeiro',
        balanceRequire: 'Saldo insuficiente',
        withdrawPasswordRequire: 'Por favor, preencha a senha de retirada primeiro',
        orderError1:'Aguarde um momento e clique novamente',
        OrderSuccessful: 'Pedido bem-sucedido',
        SpreadRequired: 'Spread requerido',
        orderTitle:"Aguardando a confirmação do comprador。 Entre em contato com o Atendimento ao cliente para renovação do pedido",
        orderTitle1:"Aguardando confirmação do comprador。 Seu pedido será feito ",
        orderTitle1_1:" Digite um estado congelado。",
    },
    label: {
        code: 'código',
    },
    streamType: {
        0: "Tudo",
        1: 'Aumento no saldo do usuário', //用户加款----Aumento no saldo do usuário
        2: 'Diminuição do saldo do usuário', //用户减款----Diminuição do saldo do usuário
        3: 'Atualização VIP', //贵宾升级----Atualização VIP
        4: 'Pedidos do usuário', //用户下单----Pedidos do usuário
        5: 'Comissão sobre pedidos feitos', //下单返佣----Comissão sobre pedidos feitos
        6: 'Colocação de pedido de confiança de IA', //AI下单----Colocação de pedido de confiança de IA
        7: 'Descontos de confiança de IA', //AI返佣----Descontos de confiança de IA
        8: 'Recompensas da Missão', //任务奖励----Recompensas da Missão
        9: "Artificial adicionar dinheiro", //人工加款----Artificial adicionar dinheiro
        10: "Descontos artificiais", //人工减款----Descontos artificiais
        11: "Recarregar presente", //注册赠送----Incentivos de registro
        12:"Desconto subordinado", //下级返佣----Desconto subordinado
        13:"Experimente a recuperação de ouro", //体验金回收----Desconto subordinado
        14:"Recarregar presente", //充值赠送
        15:"cancelar pedido", //取消订单
        16:"Recarga manual em segundo plano", //后台人工充值
        17:"Depósito de bônus" //彩金存款
    },
    date: {
        SELECT_START_DATE: 'selecione a data de início',
        SELECT_END_DATE: 'selecione a data de término',
    },
    processStatus: {
        INIT: 'Em processamento',
        SUCCESS: 'Sucesso',
        FAIL: 'Fracasso',
        1: 'Revisão pendente', //待审核----Revisão pendente
        2: 'Auditoria aprovada', //审核通过----Auditoria aprovada
        3: 'A pagar', //待支付----A pagar
        4: 'Rejeição do pedido', //驳回申请----Rejeição do pedido
        5: 'Pagamentos sendo processados', //代付处理中----Pagamentos sendo processados
        6: 'Re-auditoria', //重新审核----Re-auditoria
        7: 'Falha no pagamento', //代付失败通知----Falha no pagamento
    },
    wsMessage: {
        '202Title': 'Auditoria de recarga bem-sucedida', //充值审核成功----Auditoria de recarga bem-sucedida
        '202Content': 'Uma de suas recargas já foi creditada, verifique seu histórico de recargas.', //您有一笔充值已经到账，请在充值记录里核实。----Uma de suas recargas já foi creditada, verifique seu histórico de recargas
        '203Title': 'Falha na solicitação de recarga', //充值审核驳回----Falha na solicitação de recarga
        '203Content': 'Você tem uma recarga que foi rejeitada, verifique seu histórico de recargas.', //您有一笔充值未成功，请在充值记录里核实。----Você tem uma recarga que foi rejeitada, verifique seu histórico de recargas
        '205Title': 'As retiradas estão sendo processadas.', //提现处理中----As retiradas estão sendo processadas
        '205Content': 'Você tem uma retirada que começou a ser processada.', //您有一笔提现已经开始处理。----Você tem uma retirada que começou a ser processada
        '206Title': 'Retiradas processadas', //提现已处理----Retiradas processadas
        '206Content': 'Você tem uma retirada que foi processada, verifique no registro de retirada.', //您有一笔提现已经处理完成，请在提现记录里核实。----Você tem uma retirada que foi processada, verifique no registro de retirada
    },
    withdrawStatus: {
        INIT: 'Em processamento',
        SUCCESS: 'Sucesso',
        FAIL: 'Fracasso',
    },
    grabStatus: {
        ALL: 'TUDO',
        SUCCESS: 'Sucesso',
        FAIL: 'Fracasso',
    },
    rechargeType: {
        RECHARGE: 'Recarrega',
        VIP_UPGRADE: 'Melhoria',
    },
    rechargeWay:{
        PayOnline:'Pague on-line',
        PayViaBank:'Pagamento via banco',
        Gift:'Presente',
        GiftTitle:'Atividades de brindes',
        GiftDetail:'each recharge, recharge gift ratio:',
        GiftDetail2:'Daily first recharge, recharge gift ratio:'
    },
    taskStatus: {
        1: 'Fazendo',
        2: 'Concluído',
        3: 'Recebido',
        4: 'Expirado',

    },
    clipboard: {
        success: 'copiado',
    },
    messageStatus: {
        unread: 'Não lida',
        readed: 'Tenho lido',
    },
    recharge_online:{
        channelName:'nome do canal: ',
        max:'valor máximo de recarga:',
        min:'valor mínimo de recarga:'
    },
    faqs:{
        bindCard:'Como vincular conta bancária',
        grab:'Como pegar um pedido',
        pwd:'Como modificar a senha do fundo e a senha de login',
        recharge:'Como recarregar',
        withdraw:'Como retirar',
    },
    text: {
        withdrawCommission:`Transferência de comissão para saldo`,
        grabExplanation: `1.Seu lucro de pegar pedidos depende do seu saldo, quanto maior o saldo, maior o lucro.
    2. Cada usuário pode pegar 20 pedidos por dia. O lucro depende da ordem do dia, não é fixo.
    3. Níveis mais altos terão retornos mais altos, se você quiser atualizar o nível, clique em 'Atualização de nível VIP' e conclua o pagamento para atualizar.
`,
        depository: `Aviso: Uma vez que você transfere os fundos para o fundo de garantia. isso significa que você concorda em não retirar os fundos antes do término do ciclo de garantia do fundo. Por favor, prossiga com cautela. depois de deduzir a taxa de manuseio`,
        bankcard: `Se a conta PIX for CPF: preencha o código CPF de 11 dígitos`,
        bankcard2: `Se a conta PIX for um número de telemóvel: preencha o número de telemóvel de 13 dígitos mais 55 (55XXXXXXXXXXX)`,
        bankcard3: `Se a conta PIX for um endereço de e-mail: Preencha o endereço de e-mail diretamente`,
        bankcard4: `A conta PIX pode ser: CPF, telefone, email ou EVP/chave (EVP/chave é um código longo e complexo)`,
        bankcard5: `O método de pagamento por telefone precisa ser prefixado com 55, caso contrário, o pagamento direto falhará!`,
        bankcard6: ``,
        bankcard7: ``,
        withdraw: 'Se a retirada falhar, favor alterar as informações de retirada novamente.',
        C_service: 'Clique na imagem acima para entrar no sistema de atendimento ao cliente. Horário de retirada: segunda a sexta, das 7h am às 7h pm. Se o seu saque falhar, mude para outro banco e tente novamente.',
        ai_trust_management_help: `O gerenciamento de confiança da IA é transferir seus fundos ociosos para o pool de fundos de custódia do sistema.
        Quando os fundos são transferidos, o sistema usará algoritmos inteligentes de IA para concluir automaticamente o pedido usando os fundos do pool de fundos.
        Os fundos que você transferir para o pool de fundos gerarão receita a cada 24 horas. O valor da receita é baseado no valor da transferência e no tipo de hospedagem e no seu nível de associação VIP.
        Depois que o depósito expirar, seu principal e sua renda serão creditados automaticamente após a dedução da taxa de manuseio.`

    }
};