export default {
    title: ({
        named
    }) => {
        const typeName = named('messageType');
        switch (typeName) {
            case 'RECHARGE_AUDIT_REJECT':
                return 'Falha na recarga';
            case 'RECHARGE_SUCCESS':
                return 'Sucesso de recarga';
            case 'VIP_UPGRADE_SUCCESS':
                return `Sucesso de upgrade`;
            case 'WITHDRAW_AUDIT_PASS':
                return `Resultado da auditoria de retirada`;
            case 'WITHDRAW_AUDIT_REJECT':
                return `Resultado da auditoria de retirada`;
            case 'WITHDRAW_SUCCESS':
                return `Retirada completa`;
            case 'WITHDRAW_FAILED':
                return `Retirada falha`;
            case 'SYSTEM_BONUS':
                return `Bônus recebido`;
            case 'SYSTEM_PENALTY':
                return `Mudança de conta`;
            case 'TASK_ACCOMPLISHED':
                return `Tarefa cumprida`;
            case 'DEPOSITORY_PROFITS':
                return `AI confia nos lucros`;
            case 'DEPOSITORY_TRANSOUT':
                return 'Confiança de IA completa';
            case 'DONATION_AUDIT':
                return `Pedido de doação`;
            case 'DONATION_RECEIVED':
                return `Dinheiro de doação recebido`;
            case 'DONATION_COMPLETE':
                return `Doação completa`;
            case 'OTHER':
                return `${named('title')}`;
            default:
                return '';
        }
    },

    content: ({
        named,
        linked
    }) => {
        return linked(`message.content_${named('messageType')}`);
    },
    content_OTHER: '{content}',
    content_RECHARGE_AUDIT_REJECT: 'Caro membro VIP{vip_level}, a revisão de recarga iniciada por você no {inserted_at} foi rejeitada. Se você tiver alguma dúvida, entre em contato com o atendimento ao cliente.',
    content_RECHARGE_SUCCESS: 'Caro membro VIP{vip_level}, o número de pedido iniciado em {inserted_at} é: {order_no}a recarga chegou!;',
    content_VIP_UPGRADE_SUCCESS: 'Caro membro, você atualizou para VIP{upgrade_level} em {inserted_at}!',
    content_WITHDRAW_AUDIT_PASS: 'Caro membro VIP{vip_level}, seu pedido de retirada iniciado em {inserted_at}, foi aprovado e o valor recebido é {valor}. Por favor, preste atenção em sua conta bancária.',
    content_WITHDRAW_SUCCESS: 'Caro membro VIP{vip_level}, seu aplicativo de retirada iniciado em {inserted_at}, foi recebido com sucesso, e o valor recebido é {valor}. Por favor, preste atenção em sua conta bancária',
    content_WITHDRAW_FAILED: 'Caro membro VIP{vip_level}, a revisão de retirada iniciada por você em {inserted_at} foi falhada',
    content_WITHDRAW_AUDIT_REJECT: 'Caro membro VIP{vip_level}, a revisão de retirada iniciada por você em {inserted_at} foi rejeitada. Motivo de rejeição: Informações incorretas do cartão bancário. Se você tiver alguma dúvida, entre em contato com o atendimento ao cliente.',
    content_SYSTEM_BONUS: 'Caro membro VIP{vip_level}, você recebeu {valor} emitido pelo administrador da plataforma, por favor, verifique sua conta.',
    content_SYSTEM_PENALTY: 'Queridos membros VIP{vip_level}, sua conta mudou. Se você tiver alguma dúvida, entre em contato com o atendimento ao cliente.',
    content_TASK_ACCOMPLISHED: 'Caro membro VIP{vip_level}, você completou a tarefa de convite, e a recompensa {bônus} foi creditada!',
    content_DEPOSITORY_PROFITS: 'Caro membro VIP{vip_level}, você já gerou {lucros} quando você foi transferido para a gerência de confiança de IA em {inserted_at}',
    content_DEPOSITORY_TRANSOUT: 'Caro membro VIP{vip_level}, o {valor} que você transferiu para a riqueza de gerenciamento de confiança de IA em {inserted_at} expirou, por favor preste atenção às alterações em sua conta',
    content_DONATION_AUDIT: 'Caro membro VIP{vip_level}, seu pedido de doação foi auditado: {audit_status}',
    content_DONATION_RECEIVED: 'Caro membro VIP{vip_level}, você recebeu um total de {amountReceived} doação, por favor, verifique sua conta.',
};

