export default {
    menu: {
        index: 'MOA Cares',
        home: 'Home',
        team: 'Team',
        service: 'Service',
        my: 'My',
        grabOrder:'grabOrder',
        grabOrderList2:'task'
    },
    title: {
        download: 'download',
        termOfService: 'Term Of Service',
        maintenance: 'System under maintenance',
        registration: 'Registration',
        verificationCode: 'Verification code',
        inviteFriends: 'Invite Friends',
        forgotPassword: 'Forgot Password',
        team: 'Team Report',
        teamDesc: 'Overview of your subordinate team',
        inviteTasks: 'Invite Tasks',
        service: 'Customer Service',
        siteMessage: 'Message',
        recharge: 'Recharge',
        transPassword: 'Transaction password management',
        password: 'Password management',
        information: 'Information',
        withdrawal:'Withdrawal method',
        address: 'Delivery Address',
        avatar: 'Avatar settings',
        bankcard: 'Binding account',
        streamRecords: 'Account details',
        announcement: 'Announcement',
        about: 'About us',
        aboutCompany: 'Company Profile',
        aboutBusiness: 'Service Agreement',
        aboutTeam: 'Product description',
        careApplication: 'Apply for Help',
        careDonation: 'Donations',
        careRecipientDetail: 'Details',
        careRecipientList: 'I want to Help',
        depository: 'AI Trust Management',
        depositoryRecords: 'AI Trust Records',
        grab: 'Order grabbing',
        grabRecords: ' OrderRecord',
        rechargeRecords: 'Recharge records',
        rechargeResult: 'Recharge Result',
        withdraw: 'Withdraw',
        withdraw1: 'Withdraw',
        withdraw2: 'Withdraw',
        withdrawCommission: 'Withdrawal commission',
        withdrawRecords: 'Withdrawal records',
        prompt: 'Prompt',
        guessYouLike: 'Guess you like',
        comment: 'Comment',
        whatsApp: 'Line',
        rechargeOrderSubmitted: 'Recharge order created',
        rechargeWithBankcard: 'Pay via bank',
        agencyCenter:'Agency center',
        agencyRecords:'Agency Records',
        subordinatesManagement:'Subordinates management',
        subordinatesProfit:'Subordinates profit',


        cardNumber:'Card Number',
        cardNumber2:'Card Number',
        actualName:'Actual Name',

        withdrawalAddress:'Withdrawal Address',
        
        Account:'Account Pix',
        Name:'Name',
        Monto:'Amount',
        Calbe:'Calbe',
        Nombre:'Name',

        Task:'Task List',
        trade:'Trade...',
        freeze:'Freeze',
        completed:'Completed',

        Paid:'Paid',
        Excuting:'Excuting an order',
        ExcutingEnd:'Excuting an order',
        grabOrderDetail:'wishlist',
        payNow:'Immediate payment',
        pendingTransaction:'pending transaction',
        paySuccess:'payment successful',
        orderMatching:'order matching...',
        OrderNow: 'Order Now',
        orderCount: 'Order Count',
        orderCountShow:'Completed order/Total order',
        orderReturnBenJin:'return the principal',
        orderReturnYongJin:'return commission',
        orserSuccess:'successfully ordered',
        starPeople:'Hall of Fame',
        priceOne:'commodity price:',
        commissionOne:'commodity commission:',
        officialGuarantees: 'official guarantees',
        todayIncome: 'today income',
        orderStatus: 'order Status'
    },
    placeholder:{
        searchPhone:'You can query by the cellphone number.',
        searchPhone1:'you can query by account name.',
        coinAmount:'Please enter the number of virtual coins',
        remark:'Please enter the remark'
    },
    button: {
        login: 'Login',
        logout: 'Logout',
        signup: 'Sign Up',
        register: 'Register',
        forgotPassword: 'Forgot password',
        getVerificationCode: 'Get verification code',
        coolDown: 'cool down {second}s',
        copy: 'Copy',
        confirm: 'Confirm',
        saveInformation: 'Save information',
        confirmModify: 'Confirm modification',
        transfer: 'Transfer',
        withdraw: 'Withdraw immediately',
        withdrawAll: 'Withdraw all',
        addWithdrawal: 'Add withdrawal',
        donate: 'Donate now',
        donate2: 'Donate',
        submit: 'Submit',
        card: 'Card',
        account: 'Account',
        buyNow: 'Buy now',
        addToCart: 'Add to cart',
        backToHomepage: 'Back to homepage',
        setNow: 'Set up now',
        goBack: 'Go back',
        upload: 'Upload',
        search: 'search',
        cancel:'cancel',
        ok:'OK'
    },
    message: {
        unopen: 'not yet open! {msg}',
        loginInvalid: 'invalid account or password',
        mobileInvalid: 'please input valid mobile phone number',
        accountNotExists: 'account not exists',
        bindingAddressFailed: 'Binding Delivery address failed',
        changeAvatarError: 'Avatar settings failed',
        selectAvatar: 'Please select a picture',
        bindAccountSuccess: 'Binding account successful',
        bindAccountFailed: 'Binding account failed',
        transferSuccess: 'transfer successful',
        vipUpgradeSuccessful: 'Vip upgrade successful',
        vipUpgradeFailed: 'Vip upgrade failed',
        noNeedUpgrade: 'No need to upgrade again',
        automaticOrderSuccess: 'Automatic order successful',
        automaticOrderFailed: 'Automatic order failed',
        notSupportBank: 'This bank is not supported by the system, please change to another bank',
        withdrawSuccess: 'Withdraw successful',
        withdrawFailed: 'Withdraw failed',
        withdrawLimitTimes: 'Limit of arrival times',
        donateSuccess: 'donate successful',
        donateFailed: 'donate failed',
        alreadyApplied: 'You have already applied',
        noRecords: 'No records',
        bindingAddressSuccessful: 'Binding Delivery address successful',
        avatarSettingsSuccessful: 'Avatar settings successful',
        passwordModifiedSuccessful: 'Password modified successful',
        passwordModifiedFailed: 'Password modified failed',
        transactionModifiedSuccessful: 'Transaction password successful',
        transactionModifiedFailed: 'Transaction password failed',
        bankCardRequire: 'Please fill in the bank card first',
        balanceRequire: 'Insufficient balance',
        withdrawPasswordRequire: 'Please fill in the withdraw password first',
        orderError1:'Please wait a moment and click again',
        OrderSuccessful: 'Order successful',
        SpreadRequired: 'Spread Required',
        orderTitle:"Waiting for buyer's confirmation。 Please contact Customer Service for order renewal",
        orderTitle1:'Awaiting confirmation from the buyer。 Your order will be placed',
        orderTitle1_1:' Enter a frozen state。',
    },
    label: {
        code: 'code',
    },
    streamType: {
        0: "All",
        1: 'Increase in user balance', //用户加款
        2: 'Decrease in user balance', //用户减款
        3: 'VIP Upgrade', //贵宾升级
        4: 'User Orders', //用户下单
        5: 'Commission on orders placed', //下单返佣
        6: 'AI Trust Order Placement', //AI下单
        7: 'AI Trust Rebates', //AI返佣
        8: 'Mission Rewards', //任务奖励
        9: "Artificial add money", //人工加款
        10: "Artificial rebates", //人工减款
        11: "Recharge gift", //注册赠送
        12:"Subordinate rebate", //下级返佣
        13:"Experience gold recovery", //体验金回收
        14:"Recharge gift", //充值赠送
        15:"️Cancel order", //取消订单
        16:"Manual recharge in the background", //后台人工充值
        17:"Bonus deposit" //彩金存款
    },
    date: {
        SELECT_START_DATE: 'select start date',
        SELECT_END_DATE: 'select end date',
    },
    processStatus: {
        INIT: 'Processing',
        SUCCESS: 'Success',
        FAIL: 'Failure',
        1: 'Pending review', //待审核
        2: 'Audit approved', //审核通过
        3: 'Payable', //待支付
        4: 'Rejection of application', //驳回申请
        5: 'Payments being processed', //代付处理中
        6: 'Re-audit', //重新审核
        7: 'Payment failure', //代付失败通知
    },
    wsMessage: {
        '202Title': 'Successful recharge audit', //充值审核成功
        '202Content': 'One of your top-ups has already been credited, please check in your top-up history.', //您有一笔充值已经到账，请在充值记录里核实。
        '203Title': 'Failed recharge request', //充值审核驳回
        '203Content': 'You have a recharge that has been rejected, please check in your recharge history.', //您有一笔充值未成功，请在充值记录里核实。
        '205Title': 'Withdrawals are being processed.', //提现处理中
        '205Content': 'You have a withdrawal that has started processing.', //您有一笔提现已经开始处理。
        '206Title': 'Withdrawals processed', //提现已处理
        '206Content': 'You have a withdrawal that has been processed, please check in the withdrawal log.', //您有一笔提现已经处理完成，请在提现记录里核实。
    },
    withdrawStatus: {
        INIT: 'Processing',
        SUCCESS: 'Success',
        FAIL: 'Failure',
    },
    grabStatus: {
        ALL: 'ALL',
        SUCCESS: 'Success',
        FAIL: 'Failure',
    },
    rechargeType: {
        RECHARGE: 'Recharge',
        VIP_UPGRADE: 'Upgrade',
    },
    rechargeWay:{
        PayOnline:'Pay Online',
        PayViaBank:'Pay Via Bank',
        Gift:'Gift',
        GiftTitle:'Top-up gift activities',
        GiftDetail:'each recharge, recharge gift ratio:',
        GiftDetail2:'Daily first recharge, recharge gift ratio:'
    },
    taskStatus: {
        1: 'Doing',
        2: 'Completed',
        3: 'Rreceived',
        4: 'Expired',

    },
    clipboard: {
        success: 'Copied',
    },
    messageStatus: {
        unread: 'Unread',
        readed: 'Have read',
    },
    recharge_online:{
        channelName:'channel name: ',
        max:'maximum recharge amount:',
        min:'minimum recharge amount:'
    },
    faqs:{
        bindCard:'How to bind bank accout',
        grab:'How to grab an order',
        pwd:'How to modify the fund password and login password',
        recharge:'How to rechage',
        withdraw:'How to withdraw',
    },
    text: {
        withdrawCommission:`Commission transfer to balance`,
        grabExplanation: `1.Your profit from grabbing orders depends on your balance,the higher the balance,the higher the profit.
    2.Each user can grab 20 orders per day.Profit depends on the order of the day,not fixed.
    3.Higher levels will have higher returns,if you want to upgrade the level,please click 'Vip level upgrade' and complete payment to upgrade.
`,
        depository: `Notice:Once you transfer the funds to the escrow fund pool.it means that you agree not to withdraw the funds before the expiration of the fund escrow cycle.Please proceed with caution.After the escrow expires.your principal and income will be automatically credited after deducting the handling fee.`,
        bankcard: `If the PIX account is CPF: fill in the 11-digit CPF code`,
        bankcard2: `If the PIX account is a mobile number: fill in the 13-digit mobile number plus 55 (55XXXXXXXXXXX)`,
        bankcard3: `If the PIX account is an email address: Fill in the email address directly`,
        bankcard4: `The PIX account can be: CPF, phone, email or EVP/key (EVP/key is a long and complex code)`,
        bankcard5: `Phone payment method needs to be prefixed with 55, otherwise direct payment will fail!`,
        bankcard6: ``,
        bankcard7: ``,
        withdraw: 'Withdrawal times. Monday to Friday 7am to 7pm, if your withdrawal fails, please transfer to another bank and try again.',
        C_service: 'Click on the picture above to enter the customer service system.Withdraw time:Monday-Friday 7am-7pm.If your withDrawal fails,please change to another bank and try again.',
        ai_trust_management_help: `AI trust management is to transfer your idle funds to the system's custody fund pool.
    When the funds are transferred,the system will use AI intelligent algorithms to automatically complete the order using the funds in the fund pool.
    The funds you transfer into the fund pool will generate income every 24 hours.The amount of income is based on your transfer amount and hosting type and your VIP membership level.
    After the escrow expires,your principal and income will be automatically credited afer deducting the handling fee.`

    }
};