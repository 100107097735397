export default {
    label: {
        applyForHelp: 'Apply for Help',
        iWantHelp: 'I want to Help',
        whatIsThis: "What's This",
        latestNews: 'Latest News',
        more: 'More',
        donated: 'donated',
        to: 'To',
        time: 'Time',
        process: 'Process',
        process1: 'submit application',
        process2: 'Platform audit',
        process3: 'Start donating',
        nickname: 'Nickname',
        mobile: 'Telephone number',
        patientName: 'Patient name',
        description: 'Description',
        donationAmount: 'Donation amount',
        says: 'Says',
        thankYou: 'Thank you for showing love to me',
        amount: 'Amount',
        comment: 'Comment',
        target: 'Target',
        raised: 'Raised',
        aims: 'Aims',
        helpTimes: 'Help times',
        recipients: 'Recipients',
        nameCertified: 'Real-name certified',
        accountsReceivable: 'Accounts receivable:',
        idCardapproved: 'ID card approved',
        proofApproved: 'Relationship proof approved',
        verificationPassed: 'Receiving bank card verification passed',
        promise: 'Promoters promise',
        platformStatement: 'Platform statement',
        expandAll: 'Expand All',
        closeAll: 'Collapse all',
        askFriend: 'Ask Your Friend To Help',
        applyForDonation: 'Apply for donation',
        backgroundStory: 'Background Story',
        evidence: 'Evidence',
        witnessList: 'Witness list',
        progress: 'Progress',
        postedGrogress: 'posted progress',
        Malltext: 'If you need help, please login to our platform',
        iWantHelptext: 'Give your love and make the world better!',
    },
    hits: {
        application: `After the donation application is submitted, the platform staff will
        contact you by phone.`,
        requireAmount: 'Please fill in the donation target amount',
        comments1: `Little acts of kindness merge into an ocean of love, and I hope you get well.`,
        comments2: `May you recover.`,
        recipientDetail1: 'Top-level capital protection, a platform trusted by global users',
        joinPlatform: 'If you need help, please join our platform',
        dedication: 'Dedication of love',
        referral: 'Applying for a referral can speed up donations',
        requestHelp: 'Initiate a request for help',
    },
    placeholder: {
        requireWhatAppID: 'Please input line id',
        whatAppIDLength: 'whats app id length should be 1 to 30',
        requirePatientName: 'Please input patient name',
        patientNameLength: 'Patient name length should be 1 to 50',
        requireDesc: 'Please input description',
        descLength: 'Description length should be 10 to 1000',
        requireAmount: 'Please input amount required',
    },
    message: {
        notAllowed: 'Donation to yourself is not allowed',
    },
};