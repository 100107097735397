export default {
    title: ({
        named
    }) => {
        const typeName = named('messageType');
        switch (typeName) {
            case 'RECHARGE_AUDIT_REJECT':
                return 'Recharge failed';
            case 'RECHARGE_SUCCESS':
                return 'Recharge success';
            case 'VIP_UPGRADE_SUCCESS':
                return `Upgrade success`;
            case 'WITHDRAW_AUDIT_PASS':
                return `Withdrawal audit result`;
            case 'WITHDRAW_AUDIT_REJECT':
                return `Withdrawal audit result`;
            case 'WITHDRAW_SUCCESS':
                return `Withdrawal complete`;
            case 'WITHDRAW_FAILED':
                return `Withdrawal failed`;
            case 'SYSTEM_BONUS':
                return `Bonus received`;
            case 'SYSTEM_PENALTY':
                return `Account change`;
            case 'TASK_ACCOMPLISHED':
                return `Task accomplished`;
            case 'DEPOSITORY_PROFITS':
                return `AI trust profits`;
            case 'DEPOSITORY_TRANSOUT':
                return 'AI trust complete';
            case 'DONATION_AUDIT':
                return `Donation application`;
            case 'DONATION_RECEIVED':
                return `Donation money received`;
            case 'DONATION_COMPLETE':
                return `Donation complete`;
            case 'OTHER':
                return `${named('title')}`;
            default:
                return '';
        }
    },

    content: ({
        named,
        linked
    }) => {
        return linked(`message.content_${named('messageType')}`);
    },
    content_OTHER: '{content}',
    content_RECHARGE_AUDIT_REJECT: 'Dear VIP{vip_level} member, the recharge review initiated by you on {inserted_at} was rejected. If you have any questions, please contact customer service.',
    content_RECHARGE_SUCCESS: 'Dear VIP{vip_level} member, the order number you initiated on {inserted_at} is: {order_no}’s recharge has arrived!;',
    content_VIP_UPGRADE_SUCCESS: 'Dear member, you have upgraded to VIP{upgrade_level} on {inserted_at}!',
    content_WITHDRAW_AUDIT_PASS: 'Dear VIP{vip_level} member, your withdrawal application initiated on {inserted_at}, has been approved, and the amount received is {amount}. Please pay attention to your bank account',
    content_WITHDRAW_SUCCESS: 'Dear VIP{vip_level} member, your withdrawal application initiated on {inserted_at}, has been successfully arrived, and the amount received is {amount}. Please pay attention to your bank account',
    content_WITHDRAW_FAILED: 'Dear VIP{vip_level} member, the withdrawal review initiated by you on {inserted_at} was failed',
    content_WITHDRAW_AUDIT_REJECT: 'Dear VIP{vip_level} member, the withdrawal review initiated by you on {inserted_at} was rejected. Reason for rejection: Incorrect bank card information. If you have any questions, please contact customer service.',
    content_SYSTEM_BONUS: 'Dear VIP{vip_level} member, you have received {amount} issued by platform administrator, please check your account.',
    content_SYSTEM_PENALTY: 'Dear VIP{vip_level} members, your account has changed. If you have any questions, please contact customer service.',
    content_TASK_ACCOMPLISHED: 'Dear VIP{vip_level} member, you have completed the invitation task, and the reward {bonus} has been credited!',
    content_DEPOSITORY_PROFITS: 'Dear VIP{vip_level} member, you have already generated {profits} income when you transferred to AI trust management on {inserted_at}',
    content_DEPOSITORY_TRANSOUT: 'Dear VIP{vip_level} member, the {amount} that you transferred to the AI trust management wealth on {inserted_at} has expired, please pay attention to the changes in your account',
    content_DONATION_AUDIT: 'Dear VIP{vip_level} member, your donation application has been audited: {audit_status}',
    content_DONATION_RECEIVED: 'Dear VIP{vip_level} member, you have received a total of {amountReceived} donation, please check your account.',
};