export default {
    label: {
        advisory: 'Asesoramiento',
        complaint: 'Reclamación',
        faq: 'Preguntas frecuentes',
    },
    message: {
        // system_success: '成功',
        // system_fail: '失败',
        // system_add_suc: '新增成功',
        // system_add_fail: '新增失败',
        // system_del_suc: '删除成功',
        // system_del_fail: '删除失败',
        // system_update_suc: '更新成功',
        // system_update_fail: '更新失败',
        // system_api_error: '接口异常',
        // system_opt_suc: '操作成功',
        // system_opt_fail: '操作失败',
        // system_opt_edit: '编辑',
        // system_opt_del: '删除',
        // system_data_not_empty: '数据不能为空',

        // login_username_not_empty: '登录账号不能为空',
        // login_password_not_empty: '登录密码不能为空',
        // login_username_not_exist: '登录账号不存在',
        // login_pass_err: '登录密码错误',
        // login_username_freeze: '用户已冻结,不能登录',
        // login_username_lt_4: '登录账号不应低于4位',
        // login_password_empty: '请输入登录密码',
        // login_password_lt_4: '登录密码不应低于4位',
        // login_success: '登录成功',
        // login_fail: '登录失败',
        // login_param_empty: '账号或密码为空',
        // login_param_unvalid: '账号或密码错误',

        // logout_btn: '注销',
        // logout_confirm: '确认注销登录?',
        // logout_success: '注销成功',
        // logout_fail: '注销失败',


        // user_add: '新增用户',
        // user_update: '更新用户',
        // user_username_repeat: '账号重复',
        // user_username_valid: '限制以小写字母开头，由小写字母、数字组成',
        // user_password_update_placeholder: '请输入新密码，为空则不更新密码',
        // user_update_loginuser_limit: '禁止操作当前登录账号',
        // user_not_exist_update_fail: '修改失败,账号不存在',
        // user_old_password_err: '旧密码错误请重新输入',
        // user_nick_not_empty: '昵称不能为空',
        // user_code_not_empty: '验证码不能为空',
        // user_sms_code_expired: '验证码已过期',
        // user_sms_code_err: '验证码错误',
        // user_invitation_code_not_exist: '邀请码不存在',
        // user_invitation_code_err: '邀请码错误',
        // user_level_not_configured: '无等级信息,请配置等级',
        // user_reg_fail: '注册失败',
        // user_send_sms_fail: '发送失败,请配置短信内容',
        // user_account_not_exist: '账户不存在',
        // user_avatar_not_empty: '头像地址不能为空',
        // user_transaction_password_not_empty: '交易密码不能为空',
        // user_task_log_create_fail: '生成任务记录失败',
        // user_task_not_exist: '领取失败,任务不存在',
        // user_task_not_completed: '领取失败,任务未完成',
        // user_task_received: '领取失败,奖励已领取',
        // user_task_expired: '领取失败,任务已过期',
        // user_task_state_error: '领取失败,任务状态错误',
        // user_failed_to_modify_balance: '领取失败,修改余额失败',
        // user_task_receive_fail: '领取失败',
        // user_insufficient_balance: '余额不足',

        // level_config_1: 'VIP数据错误',
        // level_config_3: '您已经是此等级,无需升级',
        // level_config_4: '升级失败,扣除余额失败',
        // level_config_5: '升级失败,修改用户信息失败',

        // member_bank_1: '绑定银行卡失败',

        // order_save_1: '请联系管理配置产品小类!',
        // order_save_2: '数据有误，请联系管理员!',
        // order_save_3: '更新佣金失败!',
        // order_save_5: '扣款失败!',
        // order_save_6: '下单失败!!!',
        // order_save_7: '订单数量已达到当前最大订单等级，请提高等级。!!!',
        // order_detail_1: '数据有误，请联系管理员!',
        // order_detail_2: '用户VIP等级不够!',
        // order_detail_3: '每天只能抢购20单',
        // order_detail_4: '该专区需要最大金额',
        // order_detail_5: '请联系管理配置产品小类!',
        // order_detail_6: '订单状态有误!',

        // user_buy_record_save_1: '请勿重复购买!',
        // user_buy_record_save_2: '数据有误,请联系客服!',
        // user_buy_record_save_3: '参数有误,请联系管理员!',
        // user_buy_record_save_4: '小于最少限制,请重新购买!',
        // user_buy_record_save_5: '超出最大限制,请重新购买!',
        // user_buy_record_save_6: '用户VIP等级不够!',

        // web_pay_add_1: '该通道未配置',
        // web_pay_add_2: '参数有误',
        // web_pay_add_3: '支付订单创建成功',
        // web_pay_add_4: '支付订单创建失败',

        // web_pay_create_pay_1: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_pay_2: '平台卡有误',
        // web_pay_create_pay_3: '通道已关闭，请选择其他通道',
        // web_pay_create_pay_4: '提交金额不在允许范围内',

        // web_pay_create_proxy_1: '请求数据有误，提现卡号不是本人或不存在!',
        // web_pay_create_proxy_2: '您好，帐号银行卡被锁定，请联系客服!',
        // web_pay_create_proxy_3: '您好，由于您的上一笔订单暂未处理，请等待一分钟后再次提交!',
        // web_pay_create_proxy_4: '您好，每日最多提款次数为:3次',
        // web_pay_create_proxy_5: '您好，最小提款金额为',
        // web_pay_create_proxy_6: '您好，最大提款金额为',
        // web_pay_create_proxy_9: '提现成功!',
        // web_pay_create_proxy_10: '提现失败',

        // login_expired:'登录已过期，请重新登录',
        // login_network_error:'网络异常，请检查网络链接',

        system_success: 'Éxito',
        system_fail: 'Fracaso',
        system_add_suc: 'Añadir éxito',
        system_add_fail: 'Añadir Fracaso',
        system_del_suc: 'Borrado con éxito',
        system_del_fail: 'Fallo en la eliminación',
        system_update_suc: 'Actualización exitosa',
        system_update_fail: 'Actualización fallida',
        system_api_error: 'Excepciones de la interfaz',
        system_opt_suc: 'Operación exitosa',
        system_opt_fail: 'Operación fallida',
        system_opt_edit: 'Editor',
        system_opt_del: 'Borrar',
        system_data_not_empty: 'Los datos no pueden estar vacíos',

        login_username_not_empty: `La cuenta de acceso no puede estar vacía`,
        login_password_not_empty: 'La contraseña de acceso no puede estar vacía',
        login_username_not_exist: 'Cuenta de acceso no disponible',
        login_pass_err: 'Contraseña de acceso incorrecta',
        login_username_freeze: 'El usuario ha sido congelado y no puede conectarse',
        login_username_lt_4: 'El número de cuenta de acceso no debe tener menos de 4 dígitos',
        login_password_empty: 'Por favor, introduzca la contraseña de acceso',
        login_password_lt_4: 'La contraseña de acceso no debe tener menos de 4 dígitos',
        login_success: 'Inicio de sesión con éxito',
        login_fail: 'Fallo de inicio de sesión',
        login_param_empty: 'La cuenta o la contraseña están vacías',
        login_param_unvalid: 'Cuenta o contraseña incorrecta',

        logout_btn: 'Anulación',
        logout_confirm: '¿Confirmar el cierre de sesión?',
        logout_success: 'Cancelación con éxito',
        logout_fail: 'Fallo de anulación',

        user_add: 'Nuevos usuarios',
        user_update: 'Actualizar usuarios',
        user_username_repeat: 'Cuentas duplicadas',
        user_username_valid: 'RestriccionesComienza con una letra minúscula y se compone de letras minúsculas, números',
        user_password_update_placeholder: 'Por favor, introduzca una nueva contraseña, si está vacía, la contraseña no se actualizará',
        user_update_loginuser_limit: 'Prohibir el funcionamiento de la cuenta actualmente conectada',
        user_not_exist_update_fail: 'Modificación fallida, la cuenta no existe',
        user_old_password_err: 'La antigua contraseña es incorrecta, por favor, vuelva a introducirla',
        user_nick_not_empty: 'El apodo no puede estar vacío',
        user_code_not_empty: 'El captcha no puede estar vacío',
        user_sms_code_expired: 'El captcha ha caducado',
        user_sms_code_err: 'Error de Captcha',
        user_invitation_code_not_exist: 'El código de invitación no existe',
        user_invitation_code_err: 'Error de código de invitación',
        user_level_not_configured: 'No hay información de nivel, por favor configure el nivel',
        user_reg_fail: 'Registro fallido',
        user_send_sms_fail: 'El envío ha fallado, por favor configure el contenido del mensaje',
        user_account_not_exist: 'La cuenta no existe',
        user_avatar_not_empty: 'La dirección del avatar no puede estar vacía',
        user_transaction_password_not_empty: 'La contraseña de la transacción no puede estar vacía',
        user_task_log_create_fail: 'No se ha podido generar un registro de tareas',
        user_task_not_exist: 'No se ha podido recoger, la misión no existe',
        user_task_not_completed: 'No se ha recogido, la misión no se ha completado',
        user_task_received: 'Reclamación fallida, recompensa reclamada',
        user_task_expired: 'No se ha podido recoger, la misión ha expirado',
        user_task_state_error: 'Fallo en la recogida, error de estado de la misión',
        user_failed_to_modify_balance: 'No se ha cobrado, no se ha modificado el saldo',
        user_task_receive_fail: 'No se ha recogido',
        user_insufficient_balance: 'Equilibrio insuficiente',

        level_config_1: 'Error de datos VIP',
        level_config_3: 'Ya está en este nivel, no es necesario actualizarlo',
        level_config_4: 'No se ha actualizado, no se ha deducido el saldo',
        level_config_5: 'Fallo en la actualización, fallo en el cambio de información del usuario',

        member_bank_1: 'No se ha podido vincular una tarjeta bancaria',
        member_bank_2: 'El método de retirada ya existe',

        order_save_1: 'Póngase en contacto con la subcategoría de productos de Configuración de la gestión',
        order_save_2: 'Por favor, póngase en contacto con el administrador si los datos son incorrectos',
        order_save_3: 'Falló la actualización de la Comisión',
        order_save_5: 'Débito fallido',
        order_save_6: 'El pedido realizado ha fallado',
        order_save_7: 'El número de pedidos ha alcanzado el nivel máximo de pedidos actual, aumente el nivel.',
        order_save_8: 'Hay pedidos pendientes.',
        order_save_9: 'El pedido ha caducado, comuníquese con el servicio de atención al cliente para renovarlo.',
        order_save_10: 'El pedido ha sido congelado, comuníquese con el servicio al cliente para descongelarlo.',
        order_save_11: 'Si el saldo es insuficiente, será necesario compensar la diferencia.',
        
        order_detail_1: 'Por favor, póngase en contacto con el administrador si los datos son incorrectos',
        order_detail_2: 'No hay suficientes niveles VIP de usuario',
        order_detail_3: 'Póngase en contacto con su especialista del servicio de atención al cliente',
        order_detail_4: 'Cantidad máxima requerida para esta zona',
        order_detail_5: 'Póngase en contacto con la subcategoría de productos de Configuración de la gestión',
        order_detail_6: 'El estado del pedido es incorrecto.',

        user_buy_record_save_1: 'Por favor, no compre dos veces',
        user_buy_record_save_2: 'Por favor, póngase en contacto con el servicio de atención al cliente si los datos son incorrectos.',
        user_buy_record_save_3: 'Por favor, póngase en contacto con el administrador si hay un error en los parámetros',
        user_buy_record_save_4: 'Menos del límite mínimo, por favor vuelva a comprar',
        user_buy_record_save_5: 'Límite máximo superado, por favor, vuelva a comprar',
        user_buy_record_save_6: 'No hay suficiente nivel VIP para los usuarios',
        user_buy_record_save_7: 'Tiene un pedido abierto, por favor, rellénelo primero',
        user_buy_record_save_8: 'El pedido no se ha completado y no puede ser retirado',
        

        web_pay_add_1: 'Este canal no está configurado',
        web_pay_add_2: 'Parámetros erróneos',
        web_pay_add_3: 'Orden de pago creada con éxito',
        web_pay_add_4: 'Fallo en la creación de la orden de pago',

        web_pay_create_pay_1: 'Hola, como su último pedido no ha sido procesado todavía, por favor espere un minuto y envíelo de nuevo',
        web_pay_create_pay_2: 'Tarjeta de plataforma equivocada',
        web_pay_create_pay_3: 'El acceso está cerrado, por favor seleccione otro acceso',
        web_pay_create_pay_4: 'La cantidad presentada no está dentro del rango permitido',

        web_pay_create_proxy_1: 'Los datos de la solicitud son incorrectos, el número de la tarjeta de retirada no es la persona o no existe',
        web_pay_create_proxy_2: 'Hola, la tarjeta de la cuenta está bloqueada, por favor, póngase en contacto con el servicio al cliente',
        web_pay_create_proxy_3: 'Hola, como su último pedido no ha sido procesado todavía, por favor espere un minuto y envíelo de nuevo',
        web_pay_create_proxy_4: 'Hola, el número máximo de retiros por día es: {días}',
        web_pay_create_proxy_5: 'Hola, la cantidad mínima de retiro es {min}',
        web_pay_create_proxy_6: 'Hola, la cantidad máxima de retiro es {max}',
        web_pay_create_proxy_9: 'Retiradas con éxito',
        web_pay_create_proxy_10: 'fracaso de las retiradas',
        web_pay_create_proxy_11:'Contraseña de seguridad incorrecta; vuelva a comprobar su contraseña de seguridad o póngase en contacto con el servicio de atención al cliente en línea para cambiarla',
        web_pay_create_proxy_12:'Si la contraseña de seguridad se introduce incorrectamente demasiadas veces, el sistema congelará la cuenta. Póngase en contacto con el servicio de atención al cliente para descongelar la cuenta',
        web_pay_create_proxy_13:"El tipo de cambio ha sido actualizado, por favor actualice",
        
        login_expired:'El acceso ha caducado. Por favor, inicie sesión de nuevo.',
        login_network_error:'La red es anormal. Compruebe la conexión de red.',
        user_sms_fail:'El código de verificación por SMS es válido en 2 minutos',

        user_experience_cannot: 'El saldo contiene dinero de prueba de {experienceGold} y no se puede utilizar para retirar',
        user_experience_cannot_up_vip: 'El saldo contiene dinero de prueba de {experienceGold} y no se puede utilizar para la actualización VIP',
        user_experience_cannot_ai: 'El saldo contiene fondos de prueba de {experienceGold} y no puede utilizarse para comprar fideicomisos'
    }
};