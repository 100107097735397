// import moment from 'moment-timezone';
import dayjs from 'dayjs';
import { userDetail } from "@/hooks/userDetail";
import {
    useI18n
} from "@/lang";
const {
    t
} = useI18n();
import service from '@/lang/en/service';
import {
    CURRENCY_SYMBOL
} from './constant';
import {
    sysDataDictionaryWbObj
} from '@/hooks/sysDataDictionaryWbObj'


export const formatServerMsg = (val) => {
    if (service?.message?. [val]) {
        if (val === 'web_pay_create_proxy_4') {
            return t('service.message.' + val, {
                days: sysDataDictionaryWbObj?.value?.MRTKCS?.dataContent
            })
        }
        if (val === 'web_pay_create_proxy_5') {
            return t('service.message.' + val, {
                min: sysDataDictionaryWbObj?.value?.TKZDJE?.dataContent
            })
        }
        if (val === 'web_pay_create_proxy_6') {
            return t('service.message.' + val, {
                max: sysDataDictionaryWbObj?.value?.TKZGJE?.dataContent
            })
        }

        // console.log(userDetail.value?(+userDetail.value.experienceGold.toFixed(8)):0,'-----userDetail')
        let val2 = userDetail.value?(+userDetail.value.experienceGold.toFixed(8)):0
        var s = formatDecimal(val2, 2)
        var ss = `${CURRENCY_SYMBOL.value}${s}`

        if (val === 'user_experience_cannot') {
            return t('service.message.' + val, {
                experienceGold: ss
            })
        }

        if (val === 'user_experience_cannot_up_vip') {
            return t('service.message.' + val, {
                experienceGold: ss
            })
        }

        if (val === 'user_experience_cannot_ai') {
            return t('service.message.' + val, {
                experienceGold: ss
            })
        }
        
        return t('service.message.' + val)
    } else {
        return ''
    }
}

function formatDecimal(num, decimal) {
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
}

function MoveDecimalPoint_100(val) {
    let index = val.toString().indexOf('.')
    if (index === -1) {
        return +(val.toString() + '00')
    } else {
        let valStr = val.toString()
        let intPart = valStr.slice(0, index)
        let decPart = valStr.slice(index + 1) + '00'
        let resStr = intPart + decPart.slice(0, 2) + '.' + decPart.slice(2)
        return +resStr
    }
}
export const formatMoney = (val, prefix = true) => {
    try {
        val = +val.toFixed(8)
        var s = formatDecimal(val, 2)
        if (prefix) {
            return `${CURRENCY_SYMBOL.value}${s}`;
        } else {
            return s;
        }
    } catch (error) {
        return false
    }
};

export const formatMoneySign = (val) => {
    try {
        val = +val.toFixed(8)
        var s = formatDecimal(val, 2)
        return val > 0 ? `+${s}` : s;
    } catch (error) {
        return false
    }

};

export const formatPercentage = (val) => {
    try {
        val = +val.toFixed(8)
        var s = formatDecimal(MoveDecimalPoint_100(val), 2)
        return `${s}%`;
    } catch (error) {
        return false
    }

};

export function formatServerDateTime(val, format = 'YYYY-MM-DD HH:mm:ss') {
    let time = val.replace(/-/g, "/") + ' ' + sysDataDictionaryWbObj?.value?.TIMEZONE?.dataContent
    if (time) {
        return dayjs(time).format(format);
    }
}

export function formatDateName(val) {
    let time = val.replace(/-/g, "/") + ' ' + sysDataDictionaryWbObj?.value?.TIMEZONE?.dataContent
    if (time) {
        return dayjs(time).format('MMMM YYYY');
    }
}

export function formatDate(val, format = 'YYYY-MM-DD') {
    let time = val.replace(/-/g, "/") + ' ' + sysDataDictionaryWbObj?.value?.TIMEZONE?.dataContent
    if (time) {
        return dayjs(time).format(format);
    }
}

export function formatShortDateTime(val) {
    let time = val.replace(/-/g, "/") + ' ' + sysDataDictionaryWbObj?.value?.TIMEZONE?.dataContent
    if (time) {
        return dayjs(time).format('MM-DD HH:mm');
    }
}

export function formatMobile(val) {
    if (val) {
        const reg = /(\d{3})\d{5}(\d{2})/;
        return val.replace(reg, '$1*****$2');
    }
}

//时区转地区
export function switchZone(val) {
    const tz = {
        'GMT-11': 'Pacific/Midway',
        'GMT-1100': 'Pacific/Midway',

        'GMT-10': 'Pacific/Honolulu',
        'GMT-1000': 'Pacific/Honolulu',

        'GMT-9': 'Pacific/Gambier',
        'GMT-0900': 'Pacific/Gambier',

        'GMT-8': 'America/Anchorage',
        'GMT-0800': 'America/Anchorage',

        'GMT-7': 'America/Los_Angeles',
        'GMT-0700': 'America/Los_Angeles',

        'GMT-6': 'America/Chihuahua',
        'GMT-0600': 'America/Chihuahua',

        'GMT-5': 'America/Chicago',
        'GMT-0500': 'America/Chicago',

        'GMT-4': 'America/New_York',
        'GMT-0400': 'America/New_York',

        'GMT-3': 'America/Halifax',
        'GMT-0300': 'America/Halifax',

        'GMT-2': 'America/Godthab',
        'GMT-0200': 'America/Godthab',

        'GMT-1': 'Atlantic/Cape_Verde',
        'GMT-0100': 'Atlantic/Cape_Verde',

        'GMT+0': 'Atlantic/Azores',
        'GMT+0000': 'Atlantic/Azores',

        'GMT+1': 'Africa/Lagos',
        'GMT+0100': 'Africa/Lagos',

        'GMT+2': 'Europe/Amsterdam',
        'GMT+0200': 'Europe/Amsterdam',

        'GMT+3': 'Europe/Athens',
        'GMT+0300': 'Europe/Athens',

        'GMT+4': 'Asia/Dubai',
        'GMT+0400': 'Asia/Dubai',

        'GMT+5': 'Asia/Karachi',
        'GMT+0500': 'Asia/Karachi',

        'GMT+6': 'Asia/Almaty',
        'GMT+0600': 'Asia/Almaty',

        'GMT+7': 'Asia/Bangkok',
        'GMT+0700': 'Asia/Bangkok',

        'GMT+8': 'Asia/Shanghai',
        'GMT+0800': 'Asia/Shanghai',

        'GMT+9': 'Asia/Tokyo',
        'GMT+0900': 'Asia/Tokyo',

        'GMT+10': 'Australia/Brisbane',
        'GMT+1000': 'Australia/Brisbane',

        'GMT+11': 'Asia/Magadan',
        'GMT+1100': 'Asia/Magadan',

        'GMT+12': 'Pacific/Fiji',
        'GMT+1200': 'Pacific/Fiji',

        'GMT+13': 'Pacific/Tongatapu',
        'GMT+1300': 'Pacific/Tongatapu',

    }
    if (val.indexOf('GMT') === 0 && tz[val]) {
        return tz[val]
    } else {
        return 'Africa/Lagos'
    }
}