export default {
    title: ({
        named
    }) => {
        const typeName = named('messageType');
        switch (typeName) {
            case 'RECHARGE_AUDIT_REJECT':
                return 'Isi ulang gagal';
            case 'RECHARGE_SUCCESS':
                return 'Isi ulang sukses';
            case 'VIP_UPGRADE_SUCCESS':
                return `Tingkatkan kesuksesan`;
            case 'WITHDRAW_AUDIT_PASS':
                return `Hasil audit penarikan`;
            case 'WITHDRAW_AUDIT_REJECT':
                return `Hasil audit penarikan`;
            case 'WITHDRAW_SUCCESS':
                return `Penarikan selesai`;
            case 'WITHDRAW_FAILED':
                return `Penarikan gagal`;
            case 'SYSTEM_BONUS':
                return `Bonus diterima`;
            case 'SYSTEM_PENALTY':
                return `Perubahan akun`;
            case 'TASK_ACCOMPLISHED':
                return `Tugas selesai`;
            case 'DEPOSITORY_PROFITS':
                return `AI memercayai keuntungan`;
            case 'DEPOSITORY_TRANSOUT':
                return 'Kepercayaan AI selesai';
            case 'DONATION_AUDIT':
                return `Aplikasi donasi`;
            case 'DONATION_RECEIVED':
                return `Uang donasi diterima`;
            case 'DONATION_COMPLETE':
                return `Donasi selesai`;
            case 'OTHER':
                return `${named('title')}`;
            default:
                return '';
        }
    },

    content: ({
        named,
        linked
    }) => {
        return linked(`message.content_${named('messageType')}`);
    },
    content_OTHER: '{content}',
    content_RECHARGE_AUDIT_REJECT: 'Anggota VIP{vip_level} yang terhormat, tinjauan isi ulang yang Anda lakukan pada {inserted_at} ditolak. Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan.',
    content_RECHARGE_SUCCESS: 'Anggota VIP{vip_level} yang terhormat, nomor pesanan yang Anda mulai pada {inserted_at} adalah: isi ulang {order_no} telah tiba!;',
    content_VIP_UPGRADE_SUCCESS: 'Anggota yang terhormat, Anda telah meningkatkan ke VIP{upgrade_level} di {inserted_at}!',
    content_WITHDRAW_AUDIT_PASS: 'Anggota VIP{vip_level} yang terhormat, aplikasi penarikan Anda yang dimulai pada {inserted_at}, telah disetujui, dan jumlah yang diterima adalah {amount}. Harap perhatikan rekening bank Anda',
    content_WITHDRAW_SUCCESS: 'Anggota VIP{vip_level} yang terhormat, aplikasi penarikan Anda yang dimulai pada {inserted_at}, telah berhasil diterima, dan jumlah yang diterima adalah {amount}. Harap perhatikan rekening bank Anda',
    content_WITHDRAW_FAILED: 'Anggota VIP{vip_level} yang terhormat, peninjauan penarikan yang Anda lakukan pada {inserted_at} gagal',
    content_WITHDRAW_AUDIT_REJECT: 'Anggota VIP{vip_level} yang terhormat, peninjauan penarikan yang Anda lakukan pada {inserted_at} ditolak. Alasan penolakan: Informasi kartu bank salah. Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan.',
    content_SYSTEM_BONUS: 'Anggota VIP{vip_level} yang terhormat, Anda telah menerima {amount} yang dikeluarkan oleh administrator platform, harap periksa akun Anda.',
    content_SYSTEM_PENALTY: 'Anggota VIP{vip_level} yang terhormat, akun Anda telah berubah. Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan.',
    content_TASK_ACCOMPLISHED: 'Anggota VIP{vip_level} yang terhormat, Anda telah menyelesaikan tugas undangan, dan hadiah {bonus} telah dikreditkan!',
    content_DEPOSITORY_PROFITS: 'Anggota VIP{vip_level} yang terhormat, Anda telah menghasilkan {profits} pendapatan saat Anda mentransfer ke manajemen kepercayaan AI di {inserted_at}',
    content_DEPOSITORY_TRANSOUT: 'Anggota VIP{vip_level} yang terhormat, {amount} yang Anda transfer ke kekayaan manajemen kepercayaan AI di {inserted_at} telah kedaluwarsa, harap perhatikan perubahan di akun Anda',
    content_DONATION_AUDIT: 'Anggota VIP{vip_level} yang terhormat, permohonan donasi Anda telah diaudit: {audit_status}',
    content_DONATION_RECEIVED: 'Anggota VIP{vip_level} yang terhormat, Anda telah menerima total donasi {amountReceived}, harap periksa akun Anda.',
};