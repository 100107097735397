export default {
    label: {
        language:'Idioma',
        total: 'Total',
        count:'Cuenta',
        orderProfits: 'Pedir beneficios',
        totalCommission: 'Comisión total',
        walletBalance: 'El saldo de mi cartera',
        Funds_accepted_in_trust:'Fondos aceptados en fideicomiso',
        rechargeInstructions: 'Instrucciones de recarga',
        setTransPassword: 'Establecer la contraseña de la transacción',
        password: 'Contraseña',
        confirmPassword: 'Confirmar contraseña',
        changePassword: 'Por favor, introduzca su nueva contraseña',
        oldPassword: 'contraseña antigua',
        newPassword: 'nueva contraseña',
        confirmPasswordLower: 'confirmar contraseña',
        //use_info
        avatar: 'Avatar',
        nickname: 'Apodo',
        myCellNo: 'Mi número de teléfono móvil',
        myBankId: 'Mi tarjeta bancaria',
        myWithdrawal: 'Mi método de retirada',
        changeUserPassword: 'Gestión de contraseñas',
        withdrawPassword: 'Contraseña de comercio',
        shippingAddress: 'Dirección de recepción',
        //address
        realInfo: 'Recepción de información sobre la identidad',
        actualName: 'Nombre real',
        actualCellNo: 'Número de teléfono',
        addressInfo: 'Información sobre la dirección de entrega',
        addressArea: 'Área',
        address: 'Dirección',
        //bankcard
        yourId: 'Su identidad',
        accountName: 'Nombre de la cuenta',
        contactMobile: 'Teléfono de contacto',
        yourAccountInfo: 'Los datos de su cuenta',
        bank: 'Banco',
        accountNo: 'Número de cuenta',
        //depository
        availableAssets: 'Activos disponibles',
        yesterdayEarn: "Las ganancias de ayer",
        cumulativeIncome: 'Ingresos acumulados',
        days: 'Días',
        expectedProfits: 'Beneficios esperados',
        vipLevelRequired: 'Nivel VIP requerido',
        fee: 'Tasa',
        feeAmount:'Importe de la tasa',
        actualToAccount:'El actual a cuenta',
        min: 'Min',
        max: 'Max',
        time: 'hora',
        serialNumber: 'Código de pedido',
        orderNumber: 'número de pedido',
        amount: 'Importe',
        profits: 'Beneficios',
        expireDate: 'Fecha de caducidad',
        expectedReturn: 'Rendimiento esperado',
        //grab
        commission: 'Comisión',
        vip: 'VIP',
        grabbedToday: 'Agarrado hoy',
        availableToday: 'Disponible hoy',
        commissionYesterday: 'Comisiones ayer',
        commissionsTotal: 'Total de comisiones',
        commissionsToday: 'Comisiones de hoy',
        vipLevelUpgrade: 'Actualización del nivel Vip',
        automaticOrder: 'Orden automática',
        explanation: 'Explicación',
        orderRecord: 'Registro de pedidos',
        return: 'Volver',
        orderAmount:'pedido Cantidad',
        //recharge
        rechargeSuccess: 'Éxito de la recarga',
        //withdraw
        withdrawAmount: 'Importe de la retirada',
        balance: 'Balanza',
        tax: 'Impuesto',
        afterTax: 'Después de impuestos',
        contactEmail: 'Correo electrónico de contacto',
        extraBonus: 'Bonificación extra',
        selectPaymentChannel: 'Seleccione el canal',
        teamReports:'informa',

        methodType_1:'STP',
        methodType_2:'USDT-TRC20',
        methodType_3:'USDT-ERC20',
        methodType_4:'Tarjeta bancaria',
        pixType: 'pix tipo',
        

        teamReportsTitle:'Resumen de los informes del equipo',
        teamReportsDetail:'Detalle de los informes del equipo',
        //team records
        chargeTotalAmount:'Recarga total',
        firstChargeNumber:'El total de usuarios de primera recarga',
        orderAmountTotal:'El dinero total de los pedidos',
        payTotalAmount:'Retiradas totales',
        profitTotal:'Beneficio total',
        registerNumber:'Total de registros',
        subordinateNumber:'Total de subordinados',
        subordinateNumber1:'Total de miembros',
        teamBalance:'Dinero total de las cuentas del equipo',
        totalPeople: 'El total de usuarios que han pagado pedidos',

        teamOrderRecords:'Registros de pedidos',

        teamAccountRecords:'Registros de cuentas',

        rechargeDetail:'Recarga',
        withdrawDetail:'retirada',
        orderAndRebateDetail:'Pida',

        agencyType:'Jerarquía',
        LastLogin:'Último acceso',
        myself:'Yo mismo'

    },
    menu: {
        trustManagement: 'Gestión de la confianza de la IA',
        information: 'Información',
        orderRecord: 'Registro de pedidos',
        accountDetails: 'Detalles contables',
        rechargeRecord: 'Registro de recarga',
        withdrawRecord: 'Registro de retirada',
        inviteFriends: 'Invitar a los amigos',
        announcement: 'Anuncio',
        about: 'Sobre nosotros',
        download: 'Descarga de la aplicación',
        recharge: 'Recarga',
        withdraw: 'Retirar',
        service: 'Servicio',
        agencyCenter:'Centro de la Agencia',
        agencyRecords:'Registros de la Agencia',
    },
    hits: {
        transPassword: `Por favor, recuerde la contraseña, si la olvida, póngase en contacto con el servicio de atención al cliente.`,
        bindAccount: `Consejo de seguridad: Por favor, compruebe antes de enviar la información de la cuenta bancaria. Cada usuario de
            usuario sólo tiene una oportunidad de enviar su cuenta bancaria. Garantizamos
            mantener la confidencialidad de toda la información. * * (Cuando usted quiere modificar
            la información de nuevo, usted tiene que pagar {fee})`,
        // bindAccountConfirm: `Modifying bank card information requires a fee of {fee}. Are you sure you want to modify it?`,
        bindAccountConfirm: `Por favor, compruebe que la información es correcta. ¿Está seguro de enviarla?`,
        minimumAmount: 'Importe mínimo {importe}',
        maximumAmount: 'Importe máximo {importe}',
        //grab
        grabTip1: 'En las prisas por los pedidos',
        grabTip2: 'Conexión con el centro de pedidos',
        grabTip3: 'Esperando la respuesta de la base de datos',
        grabTip4: 'Esperando a que el servidor asigne el pedido',
        minimumRecharge: 'Recarga mínima de {importe}',
        maximumRecharge: 'Recarga máxima de {importe}',
        recharge: `Consejo: Puede encontrar todos los registros de recarga en "Registro de Recarga"."`,
        rechargeOrderSubmitted: 'Por favor, espere a que el administrador del sistema audite la orden.',
        rechargeBank: 'Transfiera la cantidad de dinero especificada a la siguiente cuenta y suba las capturas de pantalla',
    },
    placeholder: {
        password: 'Por favor, introduzca la contraseña',
        oldPassword: 'Por favor, introduzca su antigua contraseña',
        oldPasswordLength: 'La contraseña debe tener entre 4 y 30 caracteres',
        newPassword: 'Por favor, introduzca una nueva contraseña',
        newPasswordLength: 'La contraseña debe tener entre 6 y 30 caracteres',
        confirmPassword: 'Por favor, introduzca la contraseña de nuevo',
        passwordMath: `Dos entradas no coinciden.`,
        realName: 'Por favor, introduzca su nombre real',
        area: 'Por favor, introduzca la zona',
        contactPhone: 'Por favor, introduzca el teléfono de contacto',
        validPhoneNumber: 'Introduzca un número de teléfono móvil válido',
        address: 'Por favor, introduzca la dirección',
        accountName: 'Por favor, introduzca el nombre de su cuenta',
        accountNo: 'Por favor, introduzca el número de cuenta',
        accountNo_1: 'Por favor, Cuenta simple soles',
        selectIFSC: 'Seleccione el banco',
        transAmount: 'Por favor, introduzca el importe de la transferencia',
        rechargeAmount: 'Por favor, introduzca su importe',
        selectPayChannel: 'Seleccione el canal de pago',
        requireIFSC: 'Por favor, introduzca el IFSC',
        fundPassword: 'por favor, introduzca su contraseña de retirada',
        fundCI: 'Por favor ingrese su IC',
        fundCI2: 'please enter your Número de identificación',
        withdrawAmount: 'Por favor, introduzca el importe de la retirada',
        excessBalance: 'Exceso de saldo',
        bank: 'Por favor, introduzca el nombre de su banco',
        withdrawalType:'Seleccione un método de retirada',
        contactEmail: 'Por favor, introduzca su correo electrónico de contacto',
    },
    agencyCenter:{
        subordinatesManagement:'gestión de subalternos',
        rechargeROT:'Registro de recarga',
        withdrawROT:'Registros de retirada',
        orderROT:'ordenar los registros del equipo',
        accountROT:'registros de cuentas del equipo',
        TeamRO:'Resumen de los informes del equipo'
    }
};