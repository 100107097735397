export default {
    label: {
        totalMembers: 'Total team size',
        totalCommission: 'Total Team Commission',
        totalRecharge: 'Total recharge',
        totalWithdraw: 'Total withdrawal',
        commission: 'Commission',
        members: 'Members',
        bonus: 'Bonus',
        expireIn: 'Expire in',
        days: 'days',
        cellNo: 'Cellphone number',
        registrationDate: 'Registration date',
        balance:'balance',
        samedaySubcCmmission:'Same-day sub-commission'
    },
};