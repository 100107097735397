export default {
    label: {
        applyForHelp: 'Solicitar ayuda',
        iWantHelp: 'Quiero ayudar',
        whatIsThis: "¿Qué es esto?",
        latestNews: 'Últimas noticias',
        more: 'Más',
        donated: 'donado',
        to: 'A',
        time: 'Tiempo',
        process: 'Proceso',
        process1: 'presentar la solicitud',
        process2: 'Auditoría de la plataforma',
        process3: 'Empezar a donar',
        nickname: 'Apodo',
        mobile: 'Número de teléfono',
        patientName: 'Nombre del paciente',
        description: 'Descripción',
        donationAmount: 'Importe de la donación',
        says: 'Dice',
        thankYou: 'Gracias por mostrarme amor',
        amount: 'Importe',
        comment: 'Comentario',
        target: 'Objetivo',
        raised: 'Elevado',
        aims: 'Objetivos',
        helpTimes: 'Tiempos de ayuda',
        recipients: 'Destinatarios',
        nameCertified: 'Nombre real certificado',
        accountsReceivable: 'Cuentas por cobrar:',
        idCardapproved: 'Tarjeta de identificación aprobada',
        proofApproved: 'Prueba de relación aprobada',
        verificationPassed: 'Recibir la verificación de la tarjeta bancaria aprobada',
        promise: 'Los promotores prometen',
        platformStatement: 'Declaración de la plataforma',
        expandAll: 'Ampliar todo',
        closeAll: 'Colapsar todo',
        askFriend: 'Pide ayuda a tu amigo',
        applyForDonation: 'Solicite una donación',
        backgroundStory: 'Historia de fondo',
        evidence: 'Pruebas',
        witnessList: 'Lista de testigos',
        progress: 'Progreso',
        postedGrogress: 'progreso publicado',
        Malltext: 'Si necesita ayuda, inicie sesión en nuestra plataforma',
        iWantHelptext: '¡Da tu amor y haz que el mundo sea mejor!',
    },
    hits: {
        application: `Una vez presentada la solicitud de donación, el personal de la plataforma
        se pondrá en contacto con usted por teléfono.`,
        requireAmount: 'Por favor, rellene el importe de la donación',
        comments1: `Los pequeños actos de bondad se funden en un océano de amor, y espero que te pongas bien.`,
        comments2: `Que te recuperes.`,
        recipientDetail1: 'Protección de capital de primer nivel, una plataforma en la que confían los usuarios de todo el mundo',
        joinPlatform: 'Si necesita ayuda, únase a nuestra plataforma',
        dedication: 'Dedicación de amor',
        referral: 'Solicitar una remisión puede acelerar las donaciones',
        requestHelp: 'Iniciar una solicitud de ayuda',
    },
    placeholder: {
        requireWhatAppID: 'Por favor, introduzca la identificación de la línea',
        whatAppIDLength: 'la longitud del identificador de la aplicación debe ser de 1 a 30',
        requirePatientName: 'Por favor, introduzca el nombre del paciente',
        patientNameLength: 'La longitud del nombre del paciente debe ser de 1 a 50',
        requireDesc: 'Por favor, introduzca la descripción',
        descLength: 'La longitud de la descripción debe ser de 10 a 1000',
        requireAmount: 'Por favor, introduzca la cantidad requerida',
    },
    message: {
        notAllowed: 'La donación a uno mismo no está permitida',
    },
};