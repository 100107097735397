export default{
    MySubordinates :'my subordinates',
    listTheSubordinates:'list the subordinates',
    goReports:'go reports',
    virtualCurrency :'virtual-currency',
    rechageText1:'Transfer specified amount of money to the following account and upload screenshots.',
    rechageText2:'(*Please contact customer service before recharging and you should take the risk of this channel.)',
    coinType:'coin type',
    coinAmount:'coin amount',
    channel:'channel',
    rate:'rate',
    remark:'remark',
    maxAmount:'max amount',
    minAmount:'min amount',
    bankPay:'bank card payment',
    bankName:'bank name',
    BeneficiaryBank:'Beneficiary Bank',
    holderName:`Cardholder's real name`,
    rememberPwdText:`Please remember the password, if you forget the password, please contact customer service.`,
    versionInfo:'Version Information',
    instructions:'Instructions',
    minLength:'The minimum length is {min}.',
    maxLength:'The maximum length is {max}.',
    allSub:'All subordinates',
    directSub:'Direct subordinates',
    PIX:'Please enter the PIX account',
    realName:'Real name'
}