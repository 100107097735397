export default {
    label: {
        language:'Dilim',
        total: 'Toplam',
        count:'Saymak',
        orderProfits: 'Sipariş Karları',
        totalCommission: 'Toplam Komisyon',
        walletBalance: 'Cüzdan bakiyem',
        Funds_accepted_in_trust:'Güvenle kabul edilen fonlar',
        rechargeInstructions: 'Şarj talimatları',
        setTransPassword:'İşlem şifresi belirle',
        password: 'Parola',
        confirmPassword: 'Şifreyi Onayla',
        changePassword: 'Lütfen yeni şifrenizi girin',
        oldPassword: 'eski Şifre',
        newPassword: 'Yeni Şifre',
        confirmPasswordLower: 'Şifreyi Onayla',
        //use_info
        avatar: 'Avatar',
        nickname: 'Takma ad',
        myCellNo: 'Benim telefon numaram',
        myBankId: 'Banka kartım',
        myWithdrawal: 'Para çekme yöntemim',
        changeUserPassword: 'Şifre yönetimi',
        withdrawPassword: 'Şifreyi geri çek',
        shippingAddress: 'Gönderi Adresi',
        //address
        realInfo: 'Kimlik bilgilerinin alınması',
        actualName: 'Gerçek isim',
        actualCellNo: 'Telefon numarası',
        addressInfo: 'Teslimat adresi bilgileri',
        addressArea: 'Alan',
        address: 'Adres',
        //bankcard
        yourId: 'Kimliğiniz',
        accountName: 'Hesap adı',
        contactMobile: 'İletişim Telefonu',
        yourAccountInfo: 'Hesap bilgileriniz',
        bank: 'Banka',
        accountNo: 'Hesap numarası',
        //depository
        availableAssets: 'Mevcut varlıklar',
        yesterdayEarn: "Dünün kazancı",
        cumulativeIncome: 'Kümülatif gelir',
        days: 'Günler',
        expectedProfits: 'Beklenen Karlar',
        vipLevelRequired: 'VIP seviyesi gerekli',
        fee: 'Ücret',
        feeAmount:'Ücret miktarı',
        actualToAccount:'Hesap verilmesi gereken gerçek',
        min: 'Min',
        max: 'Maks',
        time: 'Zaman',
        serialNumber: 'Seri numarası',
        orderNumber: 'sipariş numarası',
        amount: 'Miktar',
        profits: 'Kârlar',
        expireDate: 'Son kullanma tarihi',
        expectedReturn: 'Beklenen Dönüş',
        //grab
        commission: 'Komisyon',
        vip: 'VIP',
        grabbedToday: 'Bugün Yakalandı',
        availableToday: 'Bugün uygun',
        commissionYesterday: 'Dün komisyonlar',
        commissionsTotal: 'Komisyon toplamı',
        commissionsToday: 'Bugün komisyonlar',
        vipLevelUpgrade: 'Vip Seviye Yükseltmesi',
        automaticOrder: 'Otomatik sipariş',
        explanation: 'Açıklama',
        orderRecord: 'Sipariş Kaydı',
        return: 'Dönüş',
        orderAmount:'sipariş miktarı',
        //recharge
        rechargeSuccess: 'Şarj başarısı',
        //withdraw
        withdrawAmount: 'Çekim Tutarı',
        balance: 'Denge',
        tax: 'Vergi',
        afterTax: 'Vergi sonrası',
        contactEmail: 'İletişim E-posta',
        extraBonus: 'Ek bonus',
        selectPaymentChannel: 'Kanal seç',
        teamReports:'raporlar',

        methodType_1:'pix',
        methodType_2:'USDT-TRC20',
        methodType_3:'USDT-ERC20',
        methodType_4:'Banka kartı',
        pixType: 'pix tip',

        teamReportsTitle:'Ekip raporlarına genel bakış',
        teamReportsDetail:'Ekip raporları detayı',
        //team records
        chargeTotalAmount:'Toplam şarj',
        firstChargeNumber:'İlk şarj eden kullanıcıların toplamı',
        orderAmountTotal:'Siparişlerin toplam parası',
        payTotalAmount:'Toplam para çekme',
        profitTotal:'Toplam kar',
        registerNumber:'Toplam kayıtlar',
        subordinateNumber:'Toplam astlar',
        subordinateNumber1:'Toplam üye',
        teamBalance:'Takım hesaplarının toplam parası',
        totalPeople: 'Ödeme yapan kullanıcıların toplamı',

        teamOrderRecords:'Sipariş kayıtları',

        teamAccountRecords:'Hesap kayıtları',

        rechargeDetail:'Şarj',
        withdrawDetail:'para çekme',
        orderAndRebateDetail:'Sipariş',

        agencyType:'Hiyerarşi',
        LastLogin:'Son giriş',
        myself:'Kendim'

    },
    menu: {
        trustManagement:'AI Güven Yönetimi',
        information: 'Bilgi',
        orderRecord: 'Sipariş kaydı',
        accountDetails: 'Hesap detayları',
        rechargeRecord: 'Şarj kaydı',
        withdrawRecord: 'Çekilme kaydı',
        inviteFriends: 'Arkadaşları davet etmek',
        announcement: 'Duyuru',
        about: 'Hakkımızda',
        download: 'Uygulama İndirme',
        recharge: 'Şarj',
        withdraw: 'Çekil',
        service: 'Hizmet',
        agencyCenter:'Ajans merkezi',
        agencyRecords:'Ajans kayıtları',
    },
    hits: {
        transPassword: `Lütfen şifreyi hatırlayın, şifreyi unutursanız lütfen müşteri hizmetleri ile iletişime geçin.`,
        bindAccount: `Güvenlik İpucu: Lütfen banka hesabı bilgilerini göndermeden önce kontrol edin. Her biri
        kullanıcının banka hesabı göndermek için yalnızca bir ücretsiz şansı vardır. Garanti ediyoruz
        tüm bilgileri gizli tutmak. * * (Değiştirmek istediğinizde
        bilgileri tekrar, {fee} ödemeniz gerekir)`,
        // bindAccountConfirm: `Banka kartı bilgilerinin değiştirilmesi {fee} tutarında bir ücret gerektirir. Değiştirmek istediğinizden emin misiniz?`,
        bindAccountConfirm: `Lütfen iki kez kontrol edin ve bilgilerin doğru olduğundan emin olun. Göndereceğinizden emin misiniz?`,
        minimumAmount: 'Minimum Tutar {amount}',
        maximumAmount: 'Maksimum Tutar {amount}',
        //grab
        grabTip1: 'Sipariş telaşında',
        grabTip2: 'Sipariş merkezine bağlanılıyor',
        grabTip3: 'Veritabanı yanıtı bekleniyor',
        grabTip4: 'Sunucunun siparişi tahsis etmesi bekleniyor',
        minimumRecharge: 'Minimum {amount} yeniden yükleme',
        maximumRecharge: 'Maksimum {amount} şarjı',
        recharge: `İpucu: Tüm şarj kayıtlarını "Şarj Kaydı"nda bulabilirsiniz.`,
        rechargeOrderSubmitted: 'Lütfen sistem yöneticisinin siparişi denetlemesini bekleyin.',
        rechargeBank: 'Belirtilen miktardaki parayı aşağıdaki hesaba aktarın ve ekran görüntülerini yükleyin',
    },
    placeholder: {
        password: 'Lütfen şifre giriniz',
        oldPassword: 'Lütfen eski şifrenizi girin',
        oldPasswordLength: 'Şifre 4 ile 30 karakter arasında olmalıdır',
        newPassword:'Lütfen yeni bir şifre girin',
        newPasswordLength: 'Şifre 6 ile 30 karakter arasında olmalıdır',
        confirmPassword: 'Lütfen şifreyi tekrar girin',
        passwordMath: `İki giriş eşleşmiyor!`,
        realName: 'Lütfen gerçek adınızı girin',
        area: 'Lütfen alanı girin',
        contactPhone: 'Lütfen iletişim telefonunu girin',
        validPhoneNumber: 'Lütfen geçerli bir cep telefonu numarası girin',
        address: 'Lütfen adresi girin',
        accountName: 'Lütfen hesap adınızı girin',
        accountNo: 'Lütfen hesap numarasını girin',
        selectIFSC: 'Lütfen banka seçin',
        transAmount: 'Lütfen Transfer Tutarını Giriniz',
        rechargeAmount: 'Lütfen tutarınızı girin',
        selectPayChannel: 'Lütfen ödeme kanalı seçin',
        requireIFSC: `Lütfen IFSC'yi girin`,
        fundPassword: 'lütfen para çekme şifrenizi girin',
        fundCI: `Lütfen CI'nizi girin`,
        fundCI2: 'lütfen kimlik numaranızı giriniz',
        withdrawAmount: 'Lütfen para çekme tutarını girin',
        excessBalance: 'Aşırı bakiye',
        bank: 'Lütfen banka adınızı girin',
        withdrawalType:'Lütfen bir para çekme yöntemi seçin',
        contactEmail: 'Lütfen iletişim e-postanızı girin',
    },
    agencyCenter:{
        subordinatesManagement:'ast yönetimi',
        rechargeROT:'takımın şarj kayıtları',
        withdrawROT:'takımın kayıtlarını geri çekme',
        orderROT:'takımın sipariş kayıtları',
        accountROT:'takımın hesap kayıtları',
        TeamRO:'Ekip raporlarına genel bakış'
    }
};